import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow, Polygon } from '@react-google-maps/api';
import { EnviarMensaje, GoogleGeoCode } from '../EurekaGeneral/EurekaGeneral';

interface IEurekaMapa {
  height?: string;
  query?: string;
  coordenadas: any
  idestado: number;
  datos: {
    tipoentrega: string;
    nroventa: string;
    sucursal: string;
    direccion: string;
  };
  polygons?: {
    paths: google.maps.MVCArray<google.maps.LatLng> | google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> | google.maps.LatLng[] | google.maps.LatLng[][] | google.maps.LatLngLiteral[] | google.maps.LatLngLiteral[][];
  }[];
  setCoordenadas?: Function;
}
function EurekaMapa({ height, query, coordenadas, setCoordenadas, polygons }: IEurekaMapa) {
  const [selectedMarker, setSelectedMarker] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  });

  useEffect(() => {
    const geocodeLocation = async () => {
      if (!query) {
        return;
      }
      try {
        GoogleGeoCode(query).then((data) => {
          if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            if(setCoordenadas)
            setCoordenadas({ lat, lng });
          }
        });
      } catch (error) {
        EnviarMensaje('danger', 'Error al obtener la ubicación');
      }
    };

    geocodeLocation();
  }, [query]);

  const center = useMemo(() => ({ lat: -34.61315, lng: -58.37723 }), []);
  const handleMarkerClick = (id: boolean | ((prevState: boolean) => boolean)) => {
    setSelectedMarker(id);
  };

  function devolverIcon(idestado: number) {
    switch (idestado) {
      case 1:
        return 'https://www.flexit-testing.com/assets/images/box2.png';
      case 2:
        return 'https://www.flexit-testing.com/assets/images/box1.png';
      default:
        return '';
    }
  }

  return (
    <div className='App'>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap mapContainerClassName='map-dashboard' center={coordenadas?.length ? center : coordenadas} zoom={10} mapContainerStyle={{ height: height || '100vh' }}>
          {coordenadas?.length
            ? coordenadas.map((coordenada: { [x: string]: any; idestado: any; coordenadas: any}, index: boolean) => (
              <Marker icon={{ url: devolverIcon(coordenada.idestado) }} onClick={() => handleMarkerClick(index)} position={coordenada.coordenadas} key={index}>
                {selectedMarker === index && (
                  <InfoWindow onCloseClick={() => setSelectedMarker(false)}>
                    <div>
                      <h6 className='fs-6'>
                        <strong className='text-dark'>{coordenada.datos.tipoentrega}</strong>
                      </h6>
                      <p>{coordenada.datos.direccion}</p>
                      <p>
                        Nro Venta: <strong className='text-dark'>{coordenada.datos.nroventa}</strong>
                      </p>
                      {coordenada.datos.sucursal && (
                        <p>
                          Sucursal: <strong className='text-dark'>{coordenada.datos.sucursal}</strong>
                        </p>
                      )}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))
            : coordenadas && <Marker position={coordenadas} />}
          {polygons &&
            polygons.map((value: { paths: google.maps.MVCArray<google.maps.LatLng> | google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> | google.maps.LatLng[] | google.maps.LatLng[][] | google.maps.LatLngLiteral[] | google.maps.LatLngLiteral[][] | undefined; }, key: React.Key | null | undefined) => (
              <Polygon
                key={key}
                paths={value.paths}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.3,
                  strokeWeight: 3.5,
                  fillOpacity: 0.15,
                  fillColor: '#cc9900',
                }}
              />
            ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default EurekaMapa;
