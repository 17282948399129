import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface IFormDashBoard {
  props: {
    tipoEntrega: any;
    closeModalDashboard: React.MouseEventHandler<HTMLButtonElement>;
    setrefreshData: Function;
  };
}

interface IUseForm {
  tipoconector: string;
  tipoentrega: string;
  tipoestado: string;
  tipocliente: string;
  tiposucursales: string;
  tiporepartidores: string;
  tiponroventa: string;
  tipocodinterno: string;
  fecha_alta: string;
  fecha_entrega: string;
  tipoproducto: string;
  fecha_hasta: string;
  turbo: string;
  impreso: string;
}

const FormDashboard: React.FC<IFormDashBoard> = ({ props }) => {
  const [mostrarSelects, setMostrarSelects] = useState(false);
  const [defaultTipoEntrega, setDefaultTipoEntregas] = useState([]);
  const [optionsTipoEntrega, setOptionsTipoEntrega] = useState([]);
  function devolverValue(descripcion: string, id: number) {
    if (descripcion === 'Retiro CC') return 'retirocc';
    else if (descripcion === 'Flex') return 'self_service';
    else if (descripcion === 'Colecta') return 'cross_docking';
    else if (descripcion === 'Correo' && id === 4) return 'xd_drop_off';
    else return 'drop_off';
  }
  useEffect(() => {
    let filtros = localStorage.getItem('filtrodashboard')?.length ? JSON.parse(localStorage.getItem('filtrodashboard') || '{}') : null;
    if (filtros) {
      setDefaultTipoEntregas(props?.tipoEntrega.filter((value: any) => filtros.tipoentrega.includes(devolverValue(value.descripcion, value.id))).map((value: any) => ({ value: devolverValue(value.descripcion, value.id), label: value.descripcion, name: 'tipoentrega' })));
      setOptionsTipoEntrega(props?.tipoEntrega.map((value: any) => ({ value: devolverValue(value.descripcion, value.id), label: value.descripcion, name: 'tipoentrega' })));
      setMostrarSelects(true);
    } else {
      setOptionsTipoEntrega(props?.tipoEntrega.map((value: any) => ({ value: devolverValue(value.descripcion, value.id), label: value.descripcion, name: 'tipoentrega' })));
      setMostrarSelects(true);
    }
    return () => setMostrarSelects(false);
  }, []);

  const { register, handleSubmit, setValue, getValues } = useForm<IUseForm>({
    defaultValues: {
      tipoconector: '',
      tipoentrega: '',
      tipoestado: '',
      tipocliente: '',
      tiposucursales: '',
      tiporepartidores: '',
      tiponroventa: '',
      tipocodinterno: '',
      fecha_alta: '',
      fecha_entrega: '',
      tipoproducto: '',
      fecha_hasta: '',
      turbo: '',
      impreso: '',
    },
  });

  const onChange = (selectedOption: any) => {
    const selectedValues = selectedOption ? selectedOption.map((value: any) => value.value) : [];
    setValue('tipoentrega', selectedValues);
    localStorage.setItem('filtrodashboard', JSON.stringify(getValues()));
    props.setrefreshData((prevState: any) => !prevState);
  };

  return (
    <form>
      <div className='col-12'>
        <div className='form-group'>
          <Form.Label htmlFor='tipoentrega'>Tipo entrega</Form.Label>
          {mostrarSelects && <Select isMulti defaultValue={defaultTipoEntrega} id='tipoentrega' {...register('tipoentrega')} onChange={onChange} options={optionsTipoEntrega} />}
        </div>
      </div>
      <button className='btn btn-sm btn-danger mt-3 w-100' onClick={props.closeModalDashboard}>
        Cerrar
      </button>
    </form>
  );
};

export default FormDashboard;
