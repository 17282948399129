import React, { useEffect, useState } from 'react';
import EurekaGeneral, { PutEureka, GetEureka, PostEureka, EnviarMensaje } from '../../../EurekaGeneral/EurekaGeneral';
import { Timeline } from 'rsuite';
import { QRCodeCanvas } from 'qrcode.react';
import 'rsuite/dist/rsuite.min.css';
import moment from 'moment-timezone';
import { Form } from 'react-bootstrap';
import EurekaStepCotizacion from '../../../../EurekaStep/EurekaStepCotizacion';

interface IFormCanceladosEntregas {
  ListaChecks: IListaChecks[];
  SetRefresh: Function;
  ModalClose: Function;
}

interface IListaChecks {
  id: number;
  nroventa: string;
  idsucursal: number;
  idtipoconector: number;
}

export const FormCanceladosEntregas: React.FC<IFormCanceladosEntregas> = ({ ListaChecks = [], SetRefresh, ModalClose }) => {
  function submit_cancelar_entregas() {
    PutEureka('/Entregas/CancelarEntregas', { entregas: ListaChecks }).then((response) => {
      if (response.info) {
        document.querySelectorAll<HTMLInputElement>('[class^="chk_"]:checked').forEach((input) => {
          input.checked = false;
        });
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># venta</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroventa}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary sm' onClick={submit_cancelar_entregas}>
          Cancelar
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay entregas seleccionadas
      </div>
    );
  }
};
export const FormTrackingEntregas: React.FC<{ identrega: number }> = ({ identrega }) => {
  const [content, setContent] = useState(
    <div className='alert alert-danger' role='alert'>
      Sin registros para mostrar
    </div>
  );
  useEffect(() => {
    GetEureka('/Entregas/TrackingEntregas/' + identrega)
      .then((response) => {
        if (response.info) {
          setContent(
            <div className='row'>
              <div className='mt-5 mb-4'>
                <Timeline align='alternate'>
                  <Timeline.Item>
                    <h6 className='text-default'>Entrega ingresada</h6>
                    <small>{moment(response.content.fecha_alta).format('DD/MM/yyyy hh:mm')}</small>
                  </Timeline.Item>
                  <Timeline.Item>
                    <h6 className='text-primary'>Entrega pendiente</h6>
                    <small>{response.content.fecha_pendiente !== null ? moment(response.content.fecha_pendiente).format('DD/MM/yyyy hh:mm') : 'pendiente'}</small>
                  </Timeline.Item>
                  <Timeline.Item>
                    <h6 className='text-warning'>Entrega en camino</h6>
                    <small>{response.content.fecha_encamino !== null ? moment(response.content.fecha_encamino).format('DD/MM/yyyy hh:mm') : 'pendiente'}</small>
                  </Timeline.Item>
                  <Timeline.Item>
                    <h6 className='text-success'>Entrega finalizada</h6>
                    <small>{response.content.fecha_finalizado !== null ? moment(response.content.fecha_finalizado).format('DD/MM/yyyy hh:mm') : 'pendiente'}</small>
                  </Timeline.Item>
                </Timeline>
              </div>
            </div>
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [identrega]);
  return <>{content}</>;
};
export const FormQrEntregas: React.FC<{ identrega: number }> = ({ identrega }) => {
  const [content, setContent] = useState(
    <div className='alert alert-danger' role='alert'>
      Sin registros para mostrar
    </div>
  );
  useEffect(() => {
    setContent(
      <div className='row'>
        <div className='mt-5 mb-4 text-center'>
          <QRCodeCanvas size={250} value={identrega.toString()} />
        </div>
      </div>
    );
  }, [identrega]);
  return <>{content}</>;
};

export const cancelarEntregaUnitaria = (e: React.ChangeEvent<any>, setChequeo: Function, ModalShow: Function) => {
  const id = Number(e.target.dataset.value);
  const nroventa = Number(e.target.dataset.nroventa);
  const bolsa = { id: id, nroventa: nroventa };
  setChequeo([bolsa]);
  ModalShow(1);
};
export function tituloModalTableroEntregas(tipo: number) {
  var res = '';
  switch (tipo) {
    case 1:
      res = 'Cancelar entregas';
      break;
    case 2:
      res = 'Tracking entregas';
      break;
    case 3:
      res = 'Qr entregas';
      break;
    case 4:
      res = 'Imprimir entregas';
      break;
    case 5:
      res = 'Confirmar entregas';
      break;
    case 7:
      res = 'Resolver entrega';
      break;
    case 8:
      res = 'Reprogramar entregas';
      break;
    default:
      res = '';
      break;
  }
  return res;
}
interface IFormCotizarEntrega {
  entregas: { id: number; nroventa: string }[];
  setEntregas: Function;
  mensajeStep3: string;
  mensajeStep4: string;
  devolverValores: Function;
  setIdProveedor: Function;
  idProveedor: Object;
  loading: boolean;
  setcheckCotizacion: Function;
  setValorCotizacion: Function;
  valorCotizacion: {
    codigoSeguimiento: string;
    descripcion: string;
    idproveedor: number;
    resultado: string;
  };
  datos: {
    show: boolean;
    tipo: number;
    accion: string;
  };
  formCotizacion: IFormCotizacion[];
  setFormCotizacion: Function;
  identrega: any[];
  formDisabled: boolean;
  datosCotizacionFinal: any[];
  proveedores?: {
    rapiboy: boolean;
    pedidosya: boolean;
    rappi: boolean;
    urbano: boolean;
  };
}
interface IFormCotizacion {
  idpais: number;
  cuit?: string;
  idproveedor: number[];
  identrega: number;
  nro_venta: string;
  origen: {
    pais: string;
    codigoPostal: number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  destino: {
    pais: string;
    codigoPostal: number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  productos: { articulo: string; cantidad: number; largo: number; ancho: number; alto: number; peso: number }[];
  idtipoconector?: number;
  proveedores?: {
    rapiboy: boolean;
    pedidosya: boolean;
    rappi: boolean;
    urbano: boolean;
  };
  entregas?: { id: number; nroventa: string }[];
}
export const FormCotizarEntrega: React.FC<IFormCotizarEntrega> = ({ entregas, setEntregas, mensajeStep3, mensajeStep4, setIdProveedor, idProveedor, loading, setValorCotizacion, valorCotizacion, datos, formCotizacion, setFormCotizacion, identrega = [], formDisabled = false, datosCotizacionFinal }) => {
  useEffect(() => {
    PostEureka('/Entregas/DatosCotizacion', { identrega: identrega.map((value) => value.id) })
      .then((response) => {
        let arraySetear = response.content.map((values: any) => {
          let latitudOrigen = JSON.parse(values?.coordenadas_origen || '["[0,0]"]')[0][0];
          let longitudOrigen = JSON.parse(values?.coordenadas_origen || '["[0,0]"]')[0][1];
          let coordenadasDestino = values?.coordenadas_destino.split(',');
          let direccionOrigen = values?.direccion_origen.split(',');
          let direccionDestino = values?.direccion_destino.split(',');
          return {
            nro_venta: values.nro_venta,
            idpais: formCotizacion[0].idpais,
            idproveedor: formCotizacion[0].idproveedor,
            identrega: values.identrega,
            origen: {
              pais: values?.country_origen || 'Argentina',
              codigoPostal: values?.codpostal_origen || '1012',
              provincia: (direccionOrigen && direccionOrigen[2]) || 'Buenos Aires',
              ciudad: (direccionOrigen && direccionOrigen[1]) || 'CABA',
              direccion: direccionOrigen && direccionOrigen[0],
              latitud: latitudOrigen,
              longitud: longitudOrigen,
              telefono: values?.telefono_origen,
              nombreEnvia: values?.envia_origen,
            },
            destino: {
              pais: values?.country_destino || 'Argentina',
              codigoPostal: values?.codpostal_destino || '1012',
              provincia: (direccionDestino && direccionDestino[2]) || 'Buenos Aires',
              ciudad: (direccionDestino && direccionDestino[1]) || 'CABA',
              direccion: direccionDestino && direccionDestino[0],
              latitud: coordenadasDestino && coordenadasDestino[0],
              longitud: coordenadasDestino && coordenadasDestino[1],
              telefono: values?.telefono_destino,
              nombreEnvia: values?.recibe_destino,
            },
            productos: values?.productos || [],
          };
        });
        setFormCotizacion(arraySetear);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return <EurekaStepCotizacion entregas={entregas} setEntregas={setEntregas} mensajeStep3={mensajeStep3} identrega={identrega} cpManual={{ origen: true, destino: true }} mensajeStep4={mensajeStep4} setIdProveedor={setIdProveedor} idProveedor={idProveedor} loading={loading} valorCotizacion={valorCotizacion} setValorCotizacion={setValorCotizacion} formDisabled={formDisabled} tipo={datos.accion} formCotizacion={formCotizacion} setFormCotizacion={setFormCotizacion} datosCotizacionFinal={datosCotizacionFinal} isManual={false} />;
};

interface IFormReprogramarEntregas {
  ListaChecks: IListaChecks[];
  ModalClose: Function;
  SetRefresh: Function;
}

export const FormReprogramarEntregas: React.FC<IFormReprogramarEntregas> = ({ ListaChecks, ModalClose, SetRefresh }) => {
  const [existeMeli, setexisteMeli] = useState(false);
  const [list, setList] = useState('');
  const [formReprogramar, setFormReprogramar] = useState({
    fecha_despacho: '',
    fecha_entrega: '',
  });
  useEffect(() => {
    setFormReprogramar({
      ...formReprogramar,
      fecha_despacho: moment().format('YYYY-MM-DD'),
      fecha_entrega: moment().format('YYYY-MM-DD'),
    });
    let ids = ListaChecks?.map((value) => value.id);
    let list = ids.join(',');
    setList(list);
    GetEureka('/Entregas/verificar_entregas/no_meli', `&list=${list}`).then((value) => setexisteMeli(value.content));
  }, []);
  function changeDate(e: React.ChangeEvent<HTMLInputElement>) {
    setFormReprogramar({
      ...formReprogramar,
      [e.target.name]: e.target.value,
    });
  }
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      GetEureka('/Entregas/reprogramar_entregas/masivo', `&list=${list}&fecha_despacho=${formReprogramar.fecha_despacho}&fecha_entrega=${formReprogramar.fecha_entrega}`).then((value) => {
        EnviarMensaje('success', `${value.content}`);
        SetRefresh();
        ModalClose();
      });
    } catch (error) {}
  }
  return (
    <div className='d-flex justify-content-center aling-items-center'>
      {existeMeli ? (
        <div className='w-100 row container'>
          <table className='table table-bordered table-striped table-responsive col-12'>
            <thead>
              <th className='text-center'>Tipo</th>
              <th className='text-center'>Nro Venta</th>
            </thead>
            <tbody>
              {ListaChecks?.length &&
                ListaChecks.map((value, key) => {
                  return (
                    <tr key={key}>
                      <td>{value.idtipoconector}</td>
                      <td>{value.nroventa}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <form className='row' onSubmit={onSubmit}>
            <div className='col-12 col-sm-6'>
              <Form.Label>Fecha despacho</Form.Label>
              <Form.Control name='fecha_despacho' onChange={changeDate} defaultValue={formReprogramar.fecha_despacho} max={moment().format('YYYY-MM-DD')} type='date' />
            </div>
            <div className='col-12 col-sm-6'>
              <Form.Label>Fecha entrega</Form.Label>
              <Form.Control name='fecha_entrega' onChange={changeDate} defaultValue={formReprogramar.fecha_entrega} max={moment().format('YYYY-MM-DD')} type='date' />
            </div>
            <button className='btn btn-success btn-sm w-100'>Reprogramar</button>
          </form>
        </div>
      ) : (
        <div className='alert alert-danger' role='alert'>
          Debe seleccionar entregas que no sean de Mercado Libre.
        </div>
      )}
    </div>
  );
};
export default function FormGenericos() {
  return <></>;
}
