import React, { useState } from 'react';
import { FlexitContext } from '../../context/Flexit.tsx';
import { Box, Form, Heading, Button, Anchor, Text } from '../../components/elements/index.ts';
import IconField from '../../components/fields/IconField.tsx';
import Logo from '../../components/Logo.tsx';
import data from '../../data/master/login.json';
import { useNavigate } from 'react-router-dom';
import { tokenDecifrado, mensajeError } from '../../helpers/index.ts';
import logo from '../../images/logo/logo/72/Logo_colores_72_original copia 5.png';
import notiflix from 'notiflix';
import { PostEureka } from '../../components-new/estructura/EurekaGeneral/EurekaGeneral.ts';
import useGetComplexObject from '../../hooks/useGetComplexObject.ts';
import { Image } from 'react-bootstrap';
const { REACT_APP_TEXTO, REACT_APP_HIDDEN_LOGIN_FIREBASE, REACT_APP_FRONT_VERSION } = process.env;

type IstateUsuario = {
  username: string;
  clave: string;
};

type IItem = {
  icon: string;
  type: string;
  option: {
    value: string;
    dias: string;
  }[];
  fieldSize: string;
  placeholder: string;
  objeto: string;
  passwordVisible?: boolean;
};

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

const Login: React.FC = () => {
  const { setearDatos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [mensajeErrorLocal, setMensajeErrorLocal] = useState('');
  const [stateUsuario, setstateUsuario] = useState<IstateUsuario>({
    username: '',
    clave: '',
  });
  const [loading, setLoading] = useState(true);
  /*
  const [SignInWithGoogle] = useSignInWithGoogle(auth);
  const [SignInWithFacebook] = useSignInWithFacebook(auth);
  const [SignInWithApple] = useSignInWithApple(auth);
  */
  const navigate = useNavigate();

  async function logeando(e: React.FormEvent<HTMLFormElement>, usuario: IstateUsuario) {
    e.preventDefault();
    try {
      setLoading(false);
      var params = { ...usuario, idusuario: null, sandbox: true };
      const res = await PostEureka(`/seguridad/Autenticacion/AutenticarUsuario`, params);
      localStorage.setItem('token', res.content.token);
      const datos = await tokenDecifrado(res.content.token);
      setearDatos(datos.content.data);
      setLoading(true);
      navigate('/inicio');
      window.location.reload();
    } catch (error: any) {
      if (JSON.parse(error?.request?.response || '{}').msg === 'Usuario sin permisos para acceder.') {
        setLoading(true);
        notiflix.Report.failure('Error al logear', 'Usuario sin permisos para acceder, comuniquese con su sucursal o con Flexit para finalizar el registro.', 'Continuar');
      } else {
        const respuesta = error?.request?.status !== 0 ? JSON.parse(error?.request?.response || '{}').msg : 'Error de servidor';
        const mensaje = mensajeError(respuesta);
        setLoading(true);
        setMensajeErrorLocal(mensaje);
      }
    }
  }
  const escribiendoUsuario = (e: React.ChangeEvent<HTMLInputElement>) => {
    setstateUsuario({
      ...stateUsuario,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box className='mc-auth'>
      <Image className="mc-auth-pattern" />
      <Box className='mc-auth-group'>
        <Logo src={logo} alt={data?.logo.alt} className='shadow-lg mc-auth-logo' />
        <Form className='shadow-lg mc-auth-form' onSubmit={(e: React.FormEvent<HTMLFormElement>) => logeando(e, stateUsuario)}>
          <span className='badge text-bg-flexit mb-3'>{REACT_APP_TEXTO}</span>
          <Heading as='h4' className='mc-auth-title'>
            {'Area clientes'}
          </Heading>
          <Heading as='h4' className='mc-auth-title'>
            {data?.title}
          </Heading>
          {data?.input.map((item: IItem, index: number) => {
            return (
              <IconField
                key={index}
                icon={item.icon}
                type={item.type}
                option={item.option}
                classes={item.fieldSize}
                onChangeInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  escribiendoUsuario(e);
                }}
                className='rounded-end'
                placeholder={item.placeholder}
                name={item.objeto}
                value={stateUsuario[item.objeto]}
              />
            );
          })}
          <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={data?.button.type === 'button' || data?.button.type === 'submit' || data?.button.type === 'reset' ? data?.button.type : 'button'}>
            <div className='spinner-border spinner-flexit spinner' role='status' hidden={loading}>
              <span className='visually-hidden'>Loading...</span>
            </div>
            {data?.button.text}
          </Button>
          <p className='textoError' hidden={!mensajeErrorLocal?.length}>
            {mensajeErrorLocal}
          </p>
          <Box className='mc-auth-connect' hidden={REACT_APP_HIDDEN_LOGIN_FIREBASE}>
            <Box className='mc-auth-divide'>
              <Text as='span'>{data?.divide.text}</Text>
            </Box>
            {data?.connect.map((item, index) => (
              <Anchor
                key={index}
                className={`${item.path === 'google' ? item.classes : `${item.classes} desactivado`}`}
                onClick={() => {
                  if (item.path === 'google') {
                    //SignInWithGoogle().then((user) => logeando(user));
                  }
                  if (item.path === 'facebook') {
                    //SignInWithFacebook().then((user) => logeando(user));
                  }
                  if (item.path === 'apple') {
                    //SignInWithApple().then((user) => logeando(user));
                  }
                }}>
                <i className={item.icon}></i>
                <span>{item.text}</span>
              </Anchor>
            ))}
          </Box>
          <small>v. {REACT_APP_FRONT_VERSION}</small>
        </Form>
      </Box>
    </Box>
  );
};

export default Login;
