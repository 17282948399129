import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageLayout from '../../layouts/PageLayout';
import { Tabs, Tab } from 'react-bootstrap';
import TabDashboard from './TabDashboard';
import TabCotizacion from './TabCotizacion';
import PlaceholderDashboard from './../../components/placeholders/PlaceholderDashboard';
import TabEntregas from './TabEntregas/TabEntregas';
import TabOrdenes from './TabOrdenes/TabOrdenes';
import TabConectores from './TabConectores';

interface IEurekaDasboard {}
const EurekaDashboard: React.FC<IEurekaDasboard> = ({}): JSX.Element => {
  const loading = false;
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('dashboard');
  useEffect(() => {
    if (location.state?.tab) {
      setActiveTab(location.state.tab);
    }
  }, [location.state]);

  return (
    <PageLayout label='Dashboard principal'>
      {!loading ? (
        <>
          <div className='card'>
            <div className='card-body background-card'>
              <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k || 'dashboard')}
                id='uncontrolled-tab-example'
                className='mb-3'
                justify
              >
                <Tab eventKey='dashboard' title='Dashboard' className='background-tab'>
                  <TabDashboard />
                </Tab>
                <Tab eventKey='remitos' title='Remitos' className='background-tab'>
                  <TabOrdenes />
                </Tab>
                <Tab eventKey='entregas' title='Entregas' className='background-tab'>
                  <TabEntregas />
                </Tab>
                <Tab eventKey='cotizacion' title='Cotización' className='background-tab'>
                  <TabCotizacion />
                </Tab>
                <Tab eventKey='conectores' title='Conectores' className='background-tab'>
                  <TabConectores />
                </Tab>
                {/* <Tab eventKey="estructura" title="Estructura" style={{ backgroundColor: 'lightblue' }}>
                  <TabEstructura />
                </Tab> */}
              </Tabs>
            </div>
          </div>
        </>
      ) : (
        <PlaceholderDashboard />
      )}
    </PageLayout>
  );
};

export default EurekaDashboard;