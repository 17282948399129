import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Image, Form, Tab, Tabs } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import EurekaUploadFile from '../estructura/EurekaUploadFile/EurekaUploadFile.tsx';
import imageMedidas from '../../images/dimensiones.png';
import Grid from '../estructura/EurekaGrid/EurekaGrid.tsx';
import EurekaModals from '../estructura/EurekaModals/EurekaModals.tsx';
import FormSku from './sku/Form-Sku.tsx';
import { devolverPesoVolumetrico } from '../../helpers/index.ts';
interface IFormArticulos {
  estadoArticulos: IEstadoArticulos;
  formDisabled?: boolean;
  handleClose: any;
  handleModification?: any;
  handleDelete?: any;
  handleCreate?: any;
  disabledUpload?: boolean;
  SetRefresh?: Function;
}
interface IEstadoArticulos {
  show: boolean;
  id: number;
  accion: string;
}
interface IUseForm {
  idarticulo: number;
  articulo: string;
  sku: string;
  stock_actual: number;
  stock_reservado: number;
  stock_disponible: number;
  stock_minimo: number;
  tipo: string;
  marca: string;
  modelo: string;
  proveedor: string;
  precio_venta: string;
  disabled: boolean;
  json_imagenes: string;
  idtipo: number;
  idmarca: number;
  idmodelo: number;
  idproveedor: number;
  alto: string;
  ancho: string;
  largo: string;
  valorizacion: string;
  peso: string;
  peso_volumetrico: string;
  idfiltroproducto: number;
  sku_flexit: string;
}
interface IRegistro {
  articulo: string;
  idtipo: number;
  tipo: string;
  idmarca: number;
  marca: string;
  idmodelo: number;
  modelo: string;
  idproveedor: number;
  proveedor: string;
  precio_costo: string;
  precio_venta: string;
  stock_minimo: number;
  stock_actual: number;
  stock_reservado: number;
  sku: string;
  alto: string;
  ancho: string;
  largo: string;
  peso: string;
  valorizacion: string;
  peso_volumetrico: string;
  json_imagenes: string;
  sku_flexit: string;
  idcliente: number;
  stock_disponible: number;
  idarticulo: number;
}
const FormArticulos: React.FC<IFormArticulos> = ({ estadoArticulos, formDisabled, handleClose, handleModification, handleDelete, handleCreate, disabledUpload, SetRefresh }) => {
  const [upload, setUpload] = useState(false);
  const [pesoVolumetrico, setPesoVolumetrico] = useState('0');
  const [recargaGridSku, setRecargaGridSku] = useState(false);
  const [modalSku, setmodalSku] = useState({
    show: false,
    id: 0,
    accion: 'cr',
  });
  const ModalClose = () => setmodalSku({ show: false, id: 0, accion: 'cr' });
  const ModalShow = (id: number, accion: string) => setmodalSku({ show: true, id: id, accion: accion });
  const [combos, setCombos] = useState({
    marcas: [],
    modelos: [],
    proveedores: [],
    categorias: [],
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IUseForm>({
    defaultValues: {
      idarticulo: 0,
      articulo: '',
      sku: '',
      stock_actual: 0,
      stock_reservado: 0,
      stock_disponible: 0,
      stock_minimo: 0,
      tipo: '',
      marca: '',
      modelo: '',
      proveedor: '',
      precio_venta: '',
      disabled: true,
      json_imagenes: '[]',
      idtipo: 32,
      idmarca: 1,
      idmodelo: 1,
      idproveedor: 1,
      alto: '',
      ancho: '',
      largo: '',
      valorizacion: '',
      peso: '',
      peso_volumetrico: '',
      idfiltroproducto: 0,
      sku_flexit: '',
    },
  });
  function cambiarValores(e: React.ChangeEvent<HTMLInputElement>, values: any) {
    let value: number = Number(e.target.value);
    if (e.target.name === 'alto') {
      let peso: any = devolverPesoVolumetrico(value.toString(), values.largo, values.ancho);
      setValue('peso_volumetrico', peso);
      setPesoVolumetrico(peso);
    } else if (e.target.name === 'largo') {
      let peso: any = devolverPesoVolumetrico(values.alto, value.toString(), values.ancho);
      setValue('peso_volumetrico', peso);
      setPesoVolumetrico(peso);
    } else {
      let peso: any = devolverPesoVolumetrico(values.alto, values.largo, value.toString());
      setValue('peso_volumetrico', peso);
      setPesoVolumetrico(peso);
    }
  }
  const articulobyid = useCallback(
    (registro: number, accion: string) => {
      GetEureka('/articulos/' + registro).then((response) => {
        if (response.info) {
          var registro: IRegistro = response.content[0];
          setValue('idarticulo', registro.idarticulo);
          setValue('articulo', registro.articulo);
          setValue('sku', registro.sku);
          setValue('proveedor', registro.proveedor);
          setValue('stock_actual', registro.stock_actual);
          setValue('stock_minimo', registro.stock_minimo);
          setValue('stock_reservado', registro.stock_reservado);
          setValue('stock_disponible', registro.stock_disponible);
          setValue('tipo', registro.tipo);
          setValue('marca', registro.marca);
          setValue('modelo', registro.modelo);
          setValue('precio_venta', registro.precio_venta);
          setValue('json_imagenes', registro.json_imagenes || '[]');
          setValue('peso', registro.peso);
          setValue('alto', registro.alto);
          setValue('ancho', registro.ancho);
          setValue('largo', registro.largo);
          setValue('valorizacion', registro.valorizacion || '0');
          setValue('peso_volumetrico', registro.peso_volumetrico);
          setValue('sku_flexit', registro.sku_flexit);
          setValue('idtipo', registro.idtipo);
          setValue('idmarca', registro.idmarca);
          setValue('idmodelo', registro.idmodelo);
          setValue('idproveedor', registro.idproveedor);
          setPesoVolumetrico(registro.peso_volumetrico);
          setUpload(true);
        } else {
          console.log(response);
          throw new Error('Error de peticion');
        }
      });
    },
    [setValue]
  );
  useEffect(() => {
    if (estadoArticulos.show) {
      traerCombosArticulos();
      if (estadoArticulos.accion !== 'cr') articulobyid(estadoArticulos.id, estadoArticulos.accion);
      else setUpload(true);
    }
    return () => setUpload(false);
  }, [articulobyid, estadoArticulos.accion, estadoArticulos.id, estadoArticulos.show]);
  const traerCombosArticulos = () => {
    GetEureka('/articulos/combos').then((response) => {
      let registro = response.content;
      setCombos({ ...registro });
    });
  };
  function valorById(value: { id: number; descripcion: string }) {
    return estadoArticulos.accion === 'cr' || estadoArticulos.accion === 'm' ? value.id : value.descripcion;
  }
  function handleCreateLocal(body: IUseForm) {
    try {
      let newBody: any = {
        codigo: '',
        idtipo: Number(body.idtipo),
        idmarca: Number(body.idmarca),
        idmodelo: Number(body.idmodelo),
        idproveedor: Number(body.idproveedor),
        precio_costo: parseFloat(body.precio_venta).toFixed(2),
        precio_venta: parseFloat(body.precio_venta).toFixed(2),
        precio_actual: parseFloat(body.precio_venta).toFixed(2),
        stock_minimo: body.stock_minimo,
        stock_actual: Number(body.stock_actual),
        articulo: body.articulo,
        sku: body.sku,
        observaciones: '',
        escombo: false,
        json_imagenes: body.json_imagenes,
        alto: parseFloat(body.alto).toFixed(2),
        ancho: parseFloat(body.ancho).toFixed(2),
        largo: parseFloat(body.largo).toFixed(2),
        valorizacion: parseFloat(body.valorizacion).toFixed(2),
        peso: parseFloat(body.peso).toFixed(2),
        peso_volumetrico: parseFloat(body.peso_volumetrico),
      };
      handleCreate && handleCreate(newBody);
    } catch (error) {
      console.log(error);
    }
  }
  function handleModificationLocal(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, body: IUseForm) {
    e.preventDefault();
    let newBody: any = {
      idproducto: Number(body.idarticulo),
      codigo: '',
      idtipo: Number(body.idtipo),
      idmarca: Number(body.idmarca),
      idmodelo: Number(body.idmodelo),
      idproveedor: Number(body.idproveedor),
      precio_costo: parseFloat(body.precio_venta).toFixed(2),
      precio_venta: parseFloat(body.precio_venta).toFixed(2),
      precio_actual: parseFloat(body.precio_venta).toFixed(2),
      stock_minimo: body.stock_minimo,
      stock_actual: Number(body.stock_actual),
      articulo: body.articulo,
      sku: body.sku,
      observaciones: '',
      escombo: false,
      json_imagenes: body.json_imagenes,
      alto: parseFloat(body.alto).toFixed(2),
      ancho: parseFloat(body.ancho).toFixed(2),
      largo: parseFloat(body.largo).toFixed(2),
      valorizacion: parseFloat(body.valorizacion).toFixed(2),
      peso: parseFloat(body.peso).toFixed(2),
      peso_volumetrico: parseFloat(body.peso_volumetrico),
    };
    handleModification && handleModification(newBody);
  }
  function handleDeleteLocal(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, body: any) {
    e.preventDefault();
    handleDelete && handleDelete(body);
  }
  function handleFileUploaded(jsonInfo: string) {
    setValue('json_imagenes', JSON.stringify(jsonInfo));
  }
  function manejo_acciones(origen: string, registro: number, accion: string) {
    switch (accion) {
      case 'cr':
        ModalShow(registro, accion);
        break;
      case 'b':
        ModalShow(registro, accion);
        break;
      case 'c':
        ModalShow(registro, accion);
        break;
      default:
        return;
    }
  }

  return (
    <>
      <form className='row' onSubmit={handleSubmit(handleCreateLocal)}>
        <Tabs defaultActiveKey='datos' id='uncontrolled-tab-example' className='mb-3' justify>
          <Tab eventKey='datos' title='Datos'>
            <div className='row'>
              <div className='col-12 col-lg-7 d-flex align-items-center justify-content-center'>{upload && <EurekaUploadFile titulo='800 x 600' deleteImages={setValue} onFileUploaded={handleFileUploaded} origen='modificar' alto={600} ancho={800} tipo='file' tipoAceptar='imagen' clase='file' disable={disabledUpload} images={JSON.parse(getValues().json_imagenes)} />}</div>
              <div className='col-12 col-lg-5'>
                <div className='row'>
                  <div className={estadoArticulos.accion === 'cr' ? 'col-12 col-lg-6' : 'col-12'}>
                    <Form.Label htmlFor='articulo'>Articulo</Form.Label>
                    <Form.Control type='text' disabled={formDisabled} {...register('articulo', { required: true })} />
                    {errors.articulo && <p className='text-danger'>Este campo es obligatorio</p>}
                  </div>
                  {estadoArticulos.accion === 'cr' && (
                    <div className='col-12 col-lg-6'>
                      <Form.Label htmlFor='sku'>SKU</Form.Label>
                      <Form.Control type='text' disabled={formDisabled} {...register('sku', { required: true })} />
                      {errors.sku && <p className='text-danger'>Este campo es obligatorio</p>}
                    </div>
                  )}
                </div>
                <hr></hr>
                <div className='row'>
                  <div className={estadoArticulos.accion !== 'cr' ? 'col-12 col-lg-3' : 'col-12 col-lg-6'}>
                        <Form.Label htmlFor='stock_minimo'>Stock Min.</Form.Label>
                        <Form.Control min='0' type='number' disabled={formDisabled} {...register('stock_minimo', { required: true, validate: (value: any) => parseInt(value) > 0 })} />
                        {errors.stock_minimo && <p className='text-danger'>Este campo debe ser mayor a 0</p>}
                    </div>
                    <div className={estadoArticulos.accion !== 'cr' ? 'col-12 col-lg-3' : 'col-12 col-lg-6'}>
                    <Form.Label htmlFor='stock_actual'>Stock Act.</Form.Label>
                    <Form.Control min='0' type='number' disabled={estadoArticulos.accion == 'm' ? 'disabled' : formDisabled} name='stock_actual' {...register('stock_actual', { required: true, validate: (value) => parseInt(value) > 0 })} />
                    {errors.stock_actual && <p className='text-danger'>Este campo debe ser mayor a 0</p>}
                  </div>
                  {estadoArticulos.accion !== 'cr' && (
                      <div className='col-12 col-lg-3'>
                      <Form.Label htmlFor='stock_reservado'>Stock Res.</Form.Label>
                      <Form.Control min='0' type='number' disabled={estadoArticulos.accion == 'm' ? 'disabled' : formDisabled} name='stock_reservado' {...register('stock_reservado', { required: true, validate: (value) => parseInt(value) > 0 })} />
                      {errors.stock_reservado && <p className='text-danger'>Este campo debe ser mayor a 0</p>}
                    </div>
                  )}
                  {estadoArticulos.accion !== 'cr' && (
                    <div className='col-12 col-lg-3'>
                    <Form.Label htmlFor='stock_disponible'>Stock Disp.</Form.Label>
                    <Form.Control min='0' type='number' disabled={estadoArticulos.accion == 'm' ? 'disabled' : formDisabled} name='stock_disponible' {...register('stock_disponible', { required: true, validate: (value) => parseInt(value) > 0 })} />
                    {errors.stock_disponible && <p className='text-danger'>Este campo debe ser mayor a 0</p>}
                    </div>
                  )}
                </div>
                <hr></hr>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <Form.Label htmlFor={estadoArticulos.accion === 'cr' ? 'idtipo' : 'tipo'}>Tipo</Form.Label>
                    <Form.Select
                      disabled={formDisabled}
                      {...register(estadoArticulos.accion === 'cr' || estadoArticulos.accion === 'm' ? 'idtipo' : 'tipo', { required: true })}
                      onChange={(e) => setValue('idtipo', Number(e.target.value))}
                    >
                      <option value={''}>Seleccionar</option>
                      {combos?.categorias?.map((item: any, key) => (
                        <option key={item.id} value={valorById(item)}>
                          {item.descripcion}
                        </option>
                      ))}
                      |
                    </Form.Select>
                    {errors.tipo && <p className='text-danger'>Este campo es obligatorio</p>}
                  </div>
                  <div className='col-12 col-lg-6'>
                    <Form.Label htmlFor={estadoArticulos.accion === 'cr' ? 'idmarca' : 'marca'}>Marca</Form.Label>
                    <Form.Select
                      disabled={formDisabled}
                      {...register(estadoArticulos.accion === 'cr' || estadoArticulos.accion === 'm' ? 'idmarca' : 'marca', { required: true })}
                      onChange={(e) => setValue('idmarca', Number(e.target.value))}
                      >
                      <option value={''}>Seleccionar</option>
                      {combos?.marcas?.map((item: any, key) => (
                        <option key={item.id} value={valorById(item)}>
                          {item.descripcion}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.marca && <p className='text-danger'>Este campo es obligatorio</p>}
                  </div>
                  <div className='col-12 col-lg-6'>
                    <Form.Label htmlFor={estadoArticulos.accion === 'cr' ? 'idmodelo' : 'modelo'}>Modelo</Form.Label>
                    <Form.Select
                      disabled={formDisabled}
                      {...register(estadoArticulos.accion === 'cr' || estadoArticulos.accion === 'm' ? 'idmodelo' : 'modelo', { required: true })}
                      onChange={(e) => setValue('idmodelo', Number(e.target.value))}
                      >
                      <option value={''}>Seleccionar</option>
                      {combos?.modelos?.map((item: any, key) => (
                        <option key={item.id} value={valorById(item)}>
                          {item.descripcion}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.modelo && <p className='text-danger'>Este campo es obligatorio</p>}
                  </div>
                  <div className='col-12 col-lg-6'>
                    <Form.Label htmlFor={estadoArticulos.accion === 'cr' ? 'idproveedor' : 'proveedor'}>Proveedor</Form.Label>
                    <Form.Select 
                    disabled={formDisabled} 
                    {...register(estadoArticulos.accion === 'cr' || estadoArticulos.accion === 'm' ? 'idproveedor' : 'proveedor', { required: true })}
                    onChange={(e) => setValue('idproveedor', Number(e.target.value))}
                    >
                      <option value={''}>Seleccionar</option>
                      {combos?.proveedores?.map((item: any, key) => (
                        <option key={item.id} value={valorById(item)}>
                          {item.descripcion}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.proveedor && <p className='text-danger'>Este campo es obligatorio</p>}
                  </div>
                </div>
                <hr></hr>
                <div className='row'>
                  <div className='col-12 col-lg-6 text-center'>
                    <Form.Label htmlFor='precio_venta'>Precio venta</Form.Label>
                    <Form.Control
                      min='0'
                      disabled={formDisabled}
                      type='number'
                      {...register('precio_venta', {
                        required: true,
                        validate: (value) => parseFloat(value) > 0,
                      })}
                      step='0.01'
                    />
                    {errors.precio_venta && <p className='text-danger'>Este campo debe ser mayor a 0</p>}
                  </div>
                  <div className='col-12 col-lg-6 text-center'>
                    <Form.Label htmlFor='valorizacion'>Valorizacion</Form.Label>
                    <Form.Control
                      min='0'
                      disabled={formDisabled}
                      type='number'
                      {...register('valorizacion', {
                        required: true,
                        validate: (value) => parseFloat(value) > 0,
                      })}
                      step='0.01'
                    />
                    {errors.valorizacion && <p className='text-danger'>Este campo debe ser mayor a 0</p>}
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey='medidas' title='Medidas'>
            <div>
              <div className='col-12 row'>
                <div className='col-12 col-lg-7 d-flex alig-items-center justify-content-center flex-wrap flex-grow-'>
                  <div className='col-12 col-lg-7 text-center'>
                    <div className='d-block d-md-flex align-items-end justify-content-end'>
                      <Form.Label htmlFor='alto'>Alto (cm)</Form.Label>
                      <Form.Control
                        className='ms-2 w-100'
                        min='0'
                        disabled={formDisabled}
                        type='number'
                        {...register('alto', {
                          required: true,
                          validate: (value) => parseFloat(value) > 0,
                        })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => cambiarValores(e, getValues())}
                        step='0.01'
                      />
                    </div>
                    <div>{errors.alto && <p className='text-danger'>Este campo debe ser mayor a 0</p>}</div>
                  </div>
                  <div className='col-12 col-lg-7 text-center'>
                    <div className='d-block d-md-flex align-items-center justify-content-end'>
                      <Form.Label className='p-0 m-0' htmlFor='ancho'>
                        Ancho (cm)
                      </Form.Label>
                      <Form.Control
                        className='ms-2 w-100'
                        min='0'
                        disabled={formDisabled}
                        type='number'
                        {...register('ancho', {
                          required: true,
                          validate: (value) => parseFloat(value) > 0,
                        })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => cambiarValores(e, getValues())}
                        step='0.01'
                      />
                    </div>
                    <div>{errors.ancho && <p className='text-danger'>Este campo debe ser mayor a 0</p>}</div>
                  </div>
                  <div className='col-12 col-lg-7 text-center'>
                    <div className='d-block d-md-flex align-items-center justify-content-center'>
                      <Form.Label className='p-0 m-0' htmlFor='largo'>
                        Largo (cm)
                      </Form.Label>
                      <Form.Control
                        className='ms-2 w-100'
                        min='0'
                        disabled={formDisabled}
                        type='number'
                        {...register('largo', {
                          required: true,
                          validate: (value) => parseFloat(value) > 0,
                        })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => cambiarValores(e, getValues())}
                        step='0.01'
                      />
                    </div>
                    <div>{errors.largo && <p className='text-danger'>Este campo debe ser mayor a 0</p>}</div>
                  </div>
                </div>
                <Image className='col-12 col-lg-5 border rounded' src={imageMedidas} alt='medidas' />
              </div>
              <hr style={{ opacity: 1 }} />
              <div className='row mt-2 d-flex flex-wrap justify-content-end'>
                <div className='col-12 col-lg-5 text-center'>
                  <div className='d-block d-md-flex align-items-center justify-content-center'>
                    <Form.Label className='p-0 m-0' htmlFor='peso'>
                      Peso (kg)
                    </Form.Label>
                    <Form.Control
                      min='0'
                      className='w-100'
                      disabled={formDisabled}
                      type='number'
                      {...register('peso', {
                        required: true,
                        validate: (value) => parseFloat(value) > 0,
                      })}
                      step='0.01'
                    />
                  </div>
                  <div>{errors.peso && <p className='text-danger'>Este campo debe ser mayor a 0</p>}</div>
                </div>
                <div className='col-12 col-lg-5 text-center d-block d-md-flex align-items-center justify-content-center'>
                  <Form.Label className='p-0 m-0' htmlFor='peso_volumetrico'>
                    Peso volumetrico (kg)
                  </Form.Label>
                  <Form.Control min='0' className='w-100' disabled={true} type='number' name='peso_volumetrico' value={pesoVolumetrico} step='0.01' />
                </div>
              </div>
            </div>
          </Tab>
          {estadoArticulos.accion !== 'cr' ? (
            <Tab eventKey='sku' title='Sku'>
              <div>
                <div className='alert alert-warning' role='alert'>
                  SKU Flexit: {getValues().sku_flexit}
                </div>
                <button disabled={formDisabled} className='btn btn-warning btn-sm' onClick={(e) => manejo_acciones('sku', 0, 'cr')}>
                  Nuevo registro
                </button>
                <EurekaModals id='modal_sku' manejador={modalSku} modalTitulo='Alta productos SKU' sizeModal='sm' handleClose={ModalClose}>
                  <FormSku modalSku={modalSku} formDisabled={formDisabled} getValues={getValues} modalClose={ModalClose} setRecargaGridSku={setRecargaGridSku} SetRefresh={SetRefresh} />
                </EurekaModals>
                <div className='mt-2'>{Grid(manejo_acciones, 'sku', recargaGridSku, setRecargaGridSku, { idarticulo: estadoArticulos.id })}</div>
              </div>
            </Tab>
          ) : null}
        </Tabs>
        <div className='d-flex justify-content-end mt-2'>
          <button className='btn btn-sm btn-danger ms-2' onClick={handleClose}>
            Cerrar
          </button>
          <button hidden={!handleDelete} className='btn btn-sm btn-danger ms-2' onClick={(e) => handleDeleteLocal(e, getValues())}>
            Eliminar
          </button>
          <button hidden={!handleModification} className='btn btn-sm btn-warning ms-2' onClick={(e) => handleModificationLocal(e, getValues())}>
            Modificar
          </button>
          <button hidden={!handleCreate} className='btn btn-sm btn-success ms-2' type='submit'>
            Crear
          </button>
        </div>
      </form>
    </>
  );
};
export default FormArticulos;
