import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTimeline, faLocationArrow, faTag, faCircleMinus, faQrcode, faFire } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import EurekaModals from '../../EurekaModals/EurekaModals';
import EurekaGeneral from '../../EurekaGeneral/EurekaGeneral';
import EurekaOverlayTrigger from '../../EurekaOverlayTrigger/EurekaOverlayTrigger';
import moment from 'moment-timezone';
import { FormResolverEntrega } from './Observados/FormsObservados';
import { FormCanceladosEntregas, cancelarEntregaUnitaria, FormTrackingEntregas, tituloModalTableroEntregas, FormQrEntregas } from './Genericos/FormGenericos';
import $ from 'jquery';
const { REACT_APP_URL_BLUEHOST } = process.env;

interface IEurekaTableroObservados {
  observados: { fecha_entrega?: string; fecha_despacho?: string; nro_venta: string; identrega: number; codinterno: number; fecha: string; fecha_cancelacion: string; esturbo: number; tipoconector: string; productos: string; direccion: string; recibe: string; telefono: string; comentario: string; observaciones: string }[];
  observadosTotal: number[];
  underline: React.MouseEventHandler<HTMLLabelElement>;
  noUnderline: React.MouseEventHandler<HTMLLabelElement>;
  SetRefresh: Function;
  direccion: boolean;
  vermas: { observados: boolean; pendientes: boolean; finalizados: boolean };
  setVermas: Function;
}

const EurekaTableroObservados: React.FC<IEurekaTableroObservados> = ({ observados = [], observadosTotal, underline, noUnderline, SetRefresh, direccion, vermas, setVermas }) => {
  const [identregaActivo, setIdEntregaActivo] = useState(0);
  const [chequeosObservados, setChequeosObservados] = useState([]);
  const [entregaActivo, setEntregaActivo] = useState({ id: 0, nroventa: '' });
  const [modalObservado, setmmodalObservado] = useState({ show: false, tipo: 0 });
  const ModalClose = () => setmmodalObservado({ show: false, tipo: 0 });
  const ModalShow = (tipo: number) => setmmodalObservado({ show: true, tipo: tipo });
  const FnSetEntregaActivo = (id: number, nroventa: string) => setEntregaActivo({ id: id, nroventa: nroventa });
  function checkallobservados() {
    if ($('.all_chk_observados').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_o_ent"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_o_ent"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  function btn_cancelar_entrega() {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>('[class^="chk_o_ent"]:checked').forEach((input) => {
      const id = Number(input.value);
      const nroventa = input.dataset.nroventa;
      const bolsa_ = { id: id, nroventa: nroventa };
      bolsa.push(bolsa_);
    });
    setChequeosObservados(bolsa);
    ModalShow(1);
  }
  function btn_tracking_entrega(identrega: number) {
    setIdEntregaActivo(identrega);
    ModalShow(2);
  }
  function btn_qr_entrega(identrega: number) {
    FnSetEntregaActivo(identrega, '0');
    ModalShow(3);
  }
  function btn_resolver_entregas(identrega: number) {
    FnSetEntregaActivo(identrega, '0');
    ModalShow(7);
  }
  return (
    <>
      <EurekaModals manejador={modalObservado} modalTitulo={tituloModalTableroEntregas(modalObservado.tipo)} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosObservados, modalObservado.tipo)} handleClose={ModalClose}>
        {modalObservado.tipo === 1 ? (
          <FormCanceladosEntregas ListaChecks={chequeosObservados} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : modalObservado.tipo === 2 ? (
          <FormTrackingEntregas identrega={identregaActivo} />
        ) : modalObservado.tipo === 3 ? (
          <FormQrEntregas identrega={entregaActivo.id} />
        ) : modalObservado.tipo === 7 ? (
          <FormResolverEntrega identrega={entregaActivo.id} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay entregas seleccionadas
          </div>
        )}
      </EurekaModals>
      <div className={direccion ? 'col-12 col-lg-4' : 'col-md-12'}>
        <div className='card col_entregas_observados'>
          <div className='card-header eureka-bg-danger'>
            <div className='row'>
              <div className='col-10 text-danger'>
                <input className='all_chk_observados' onClick={checkallobservados} type='checkbox' /> Observadas <Badge bg='danger'>{observadosTotal.length}</Badge>
              </div>
              <div className='col-2 text-end'>
                <div className='dropdow'>
                  <button className='btn btn-danger btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    +
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_cancelar_entrega}>
                        Cancelar entregas
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direccion ? '' : 'row'}`} id='accordionObservados'>
              {observados.map((observado, key) => (
                <div className={`accordion-item ${direccion ? 'col-md-12' : 'col-md-4'}`} key={key}>
                  <div className='accordion-header eureka-bg-danger  accordion-observado danger'>
                    <nav className='navbar bg-body-tertiary'>
                      <div className='container-fluid'>
                        <div className='form-check'>
                          <input className={'chk_o_ent' + key + ' form-check-input me-1'} data-nroventa={observado.nro_venta} type='checkbox' value={observado.identrega} id={'chk_obs_' + key} />
                          <button className='btn btn-danger btn-sm' onClick={(e) => cancelarEntregaUnitaria(e, setChequeosObservados, ModalShow)} data-nroventa={observado.nro_venta} data-value={observado.identrega}>
                            <FontAwesomeIcon icon={faTrash} onClick={(e) => e.stopPropagation()} />
                          </button>
                          &nbsp;
                          <label className='link-danger text-danger form-check-label' data-bs-toggle='collapse' data-bs-target={'#collapse_obv' + key} aria-expanded='false' aria-controls={'collapse_obv' + key} onMouseEnter={underline} onMouseLeave={noUnderline}>
                            #{observado.codinterno} - {observado.fecha}{' '}
                            {observado.fecha_cancelacion?.length ? (
                              <EurekaOverlayTrigger isButton={false} title={<FontAwesomeIcon icon={faCircleMinus} />}>
                                {`Cancelada: ${moment(observado.fecha_cancelacion).format('DD/MM')}`}
                              </EurekaOverlayTrigger>
                            ) : null}{' '}
                            {observado.esturbo === 1 ? (
                              <EurekaOverlayTrigger isButton={false} title={<FontAwesomeIcon icon={faFire} />}>
                                {'Es Turbo'}
                              </EurekaOverlayTrigger>
                            ) : (
                              ''
                            )}
                          </label>
                        </div>
                        <div className='dropdow' key={key} id={`menu-obs-${key}`}>
                          <button className='btn btn-danger btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                            {observado.tipoconector}
                          </button>
                          <ul className='dropdown-menu dropdown-menu-end'>
                            <li>
                              <a onClick={(e) => btn_qr_entrega(observado.identrega)} className='dropdown-item pointer'>
                                <FontAwesomeIcon icon={faQrcode} /> QR
                              </a>
                            </li>
                            <li>
                              <a onClick={(e) => btn_tracking_entrega(observado.identrega)} className='dropdown-item'>
                                <FontAwesomeIcon icon={faTimeline} /> Tracking
                              </a>
                            </li>
                            <li>
                              <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + observado.nro_venta} className='dropdown-item' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={faLocationArrow} /> Seguimiento
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div id={'collapse_obv' + key} className='accordion-collapse collapse' data-bs-parent='#accordionObservados'>
                    <div className='accordion-body text-black'>
                      {JSON.parse(observado.productos)?.map((producto: { cant: number; prod: string }, index: number) => (
                        <div key={index}>
                          <small>
                            ({producto.cant}) {producto.prod}
                          </small>
                        </div>
                      ))}
                      <hr className='border border-dark margin-hr'></hr>
                      <small>
                        Nro Venta:{' '}
                        <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + observado.nro_venta} className='btn btn-link' target='_blank' rel='noreferrer'>
                          <small>{observado.nro_venta}</small>
                        </a>
                      </small>
                      <br />
                      <small>Dirección: {observado.direccion}</small>
                      <br />
                      <small>
                        Recibe: {observado.recibe} | Telefono: {observado.telefono}
                      </small>
                      <br />
                      <div className='row p-0 m-0'>
                        <small className='col-md-6 m-0 p-0'>Fecha despacho: {observado.fecha_despacho ? <span className='badge bg-secondary'>{moment.utc(observado.fecha_despacho).format('DD/MM/YYYY')}</span> : null}</small>
                        <small className='col-md-6 m-0 p-0'>Fecha entrega: {observado.fecha_entrega ? <span className='badge bg-secondary'>{moment.utc(observado.fecha_entrega).format('DD/MM/YYYY')}</span> : null}</small>
                      </div>
                      <br />
                      <small>Comentario: {observado.comentario}</small>
                      <br />
                      <small className='text-danger'>{observado.observaciones?.split('</br>').join(' ')}</small>
                      <br />
                      <Button className='sm pt-0 pb-0 w-100' variant='danger' onClick={() => btn_resolver_entregas(observado.identrega)}>
                        Resolver entrega
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div className='d-flex justify-content-center align-items-center pt-2'>
                <button hidden={observados.length < 12} className='btn btn-sm btn-danger' onClick={() => setVermas({ ...vermas, observados: !vermas.observados })}>
                  {observados.length <= 12 ? 'Ver mas' : 'Ver menos'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EurekaTableroObservados;
