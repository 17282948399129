import React from 'react';
import { ThemeProvider } from './context/Themes.tsx';
import { LoaderProvider } from './context/Preloader.tsx';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Error } from './pages/supports/index.ts';
import { ForgotPassword, Login, MyAccount } from './pages/master/index.ts';
import './css/bootstrap.min.css';
import './css/styles.css';
import './css/icofont/icofont.css';
import './css/icon.css';
import './css/text.css';
import EurekaArticulos from './components-new/EurekaArticulos/EurekaArticulos.tsx';
import EurekaDashboard from './components-new/EurekaDashboard/EurekaDashboard.tsx';
import EurekaOrdenes from './components-new/EurekaOrdenes/EurekaOrdenes.tsx';
import EurekaEntregas from './components-new/EurekaEntregas/EurekaEntregas.tsx';
import EurekaRedireccion from './components-new/estructura/EurekaRedireccion/EurekaRedireccion.tsx';
import EurekaConector from './components-new/EurekaConector/EurekaConector.tsx';
import EurekaNotificacion from './pages/master/Notification';
import EurekaConectores from './components-new/EurekaConectores/EurekaConector.tsx';

let titulo = document.title;

window.addEventListener(
  'blur',
  () => {
    titulo = document.title;
    document.title = 'Flexit te espera';
  },
  { passive: true }
);
window.addEventListener(
  'focus',
  () => {
    document.title = titulo;
  },
  { passive: true }
);
const App: React.FC = (): JSX.Element => {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <HashRouter>
          <Routes>
            <Route path='/' element={<EurekaRedireccion path='/' element={<Login />} />} />
            <Route path='/login' element={<EurekaRedireccion path='/login' element={<Login />} />} />
            <Route path='/inicio' element={<EurekaRedireccion path='/inicio' element={<EurekaDashboard />} />} />
            <Route path='/Dashboard' element={<EurekaRedireccion path='Dashboard' element={<EurekaDashboard />} />} />
            <Route path='/MiPerfil' element={<EurekaRedireccion path='/MiPerfil' element={<MyAccount />} />} />
            <Route path='/CambiarContraseña' element={<EurekaRedireccion path='/CambiarContraseña' element={<ForgotPassword />} />} />
            <Route path='/Articulos' element={<EurekaRedireccion path='/Articulos' element={<EurekaArticulos />} />} />
            <Route path='/Remitos' element={<EurekaRedireccion path='/Remitos' element={<EurekaOrdenes />} />} />
            <Route path='/Entregas' element={<EurekaRedireccion path='/Entregas' element={<EurekaEntregas />} />} />
            <Route path='/LogCotizacion' element={<EurekaRedireccion path='/LogCotizacion' element={<EurekaConector />} />} />
            <Route path='/Conectores' element={<EurekaRedireccion path='/Conectores' element={<EurekaConectores />} />} />
            <Route path='/Notificaciones' element={<EurekaRedireccion path='Notificaciones' element={<EurekaNotificacion />} />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </HashRouter>
      </LoaderProvider>
    </ThemeProvider>
  );
};

export default App;
