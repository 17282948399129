import { useState, useEffect } from 'react';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import PageLayout from '../../layouts/PageLayout';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltroEntregas from './Filtro-Entregas';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
import EurekaFormEntregas from './Form-Entregas';
import { FormTrackingEntregas } from '../estructura/EurekaTablero/Entregas/Genericos/FormGenericos';

interface IEstado {
  descripcion: string;
  id: string;
  orden: string;
}

interface IModalEntrega {
  show: boolean;
  id: number;
  accion: string;
}

const EurekaEntregas = () => {
  const [estados, setEstados] = useState<IEstado[]>([]);
  const [tipoConector, setTipoConector] = useState<IEstado[]>([]);
  const [tipoEntregas, setTipoEntregas] = useState<IEstado[]>([]);
  const [clientes, setClientes] = useState<IEstado[]>([]);
  const [sucursales, setSucursales] = useState<IEstado[]>([]);
  const [repartidores, setRepartidores] = useState<IEstado[]>([]);
  const [formdisabled, setformDisabled] = useState<boolean>(false);
  const [titulo, setTitulo] = useState<string>('');
  const [modalEntregas, setmodalEntregas] = useState<IModalEntrega>({ show: false, id: 0, accion: 'c' });
  const ModalClose = () => setmodalEntregas({ show: false, id: 0, accion: 'c' });
  const ModalShow = (id: number, accion: string) => setmodalEntregas({ show: true, id: id, accion: accion });
  const [modalTracking, setmodalTracking] = useState({ show: false, id: 0, accion: 't' });
  const ModalCloseTracking = () => setmodalTracking({ show: false, id: 0, accion: 't' });
  const ModalShowTracking = (id: number, accion: string) => setmodalTracking({ show: true, id: id, accion: accion });
  const [offcanvasFiltrosEntregas, setoffcanvasFiltrosEntregas] = useState({ show: false, id: 0, accion: 'f' });
  const OffCanvasFiltrosClose = () => setoffcanvasFiltrosEntregas({ show: false, id: 0, accion: 'f' });
  const OffCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosEntregas({ show: true, id: id, accion: accion });
  const [recargaGridEntregas, setRecargaGridEntregas] = useState<string>('');
  const SetRefresh = (date: string) => setRecargaGridEntregas(date);
  const manejo_acciones = (origen: string, registro: number, accion: string) => {
    switch (accion) {
      case 't':
        setTitulo('Tracking Entregas');
        ModalShowTracking(registro, accion);
        break;
      case 'c':
        setTitulo('Consulta Entregas');
        setformDisabled(true);
        ModalShow(registro, accion);
        break;
      case 'f':
        setTitulo('Filtros Entregas');
        OffCanvasFiltrosShow(registro, accion);
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    GetEureka('/entregas/combos').then((response) => {
      const respuesta = response.content;
      setEstados(respuesta.estados);
      setTipoConector(respuesta.tipo_conector);
      setTipoEntregas(respuesta.tipo_entregas);
      setClientes(respuesta.clientes);
      setSucursales(respuesta.sucursales);
      setRepartidores(respuesta.repartidores);
    });
  }, [recargaGridEntregas]);
  return (
    <PageLayout label='Adm. Entregas'>
      {Grid(manejo_acciones, 'entregas', recargaGridEntregas, SetRefresh)}
      <EurekaOffcanvas id='offcanvas_filtrosentregas' manejador={offcanvasFiltrosEntregas} offcanvasTitulo={titulo} handleClose={OffCanvasFiltrosClose} posicion='start' size='w-20'>
        <FiltroEntregas props={{ SetRefresh, OffCanvasFiltrosClose, estados, tipoConector, tipoEntregas, clientes, sucursales, repartidores }} />
      </EurekaOffcanvas>
      <EurekaModals id='modal_entregas' manejador={modalEntregas} modalTitulo={titulo} sizeModal='xl' handleClose={ModalClose}>
        <EurekaFormEntregas id={modalEntregas.id} modalEntregas={modalEntregas} SetRefresh={SetRefresh} ModalClose={ModalClose} formdisabled={formdisabled} />
      </EurekaModals>
      <EurekaModals id='modal_tracking' manejador={modalTracking} modalTitulo={titulo} sizeModal='xl' handleClose={ModalCloseTracking}>
        <FormTrackingEntregas identrega={modalTracking.id} />
      </EurekaModals>
    </PageLayout>
  );
};

export default EurekaEntregas;
