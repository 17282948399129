import React, { useEffect, useState, useRef } from 'react';
import EurekaOverlay from '../estructura/EurekaOverlay/EurekaOverlay';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useForm } from 'react-hook-form';
import moment from 'moment';
const { REACT_APP_DIAS_DASHBOARD } = process.env;

var fecha = moment();
const fechaDesde = moment(fecha).subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD');
const fechaHasta = moment(fecha).format('YYYY-MM-DD');

interface IFiltroEntregas {
  props: {
    SetRefresh: Function;
    OffCanvasFiltrosClose: Function;
    estados: IEstado[];
    tipoConector: IEstado[];
    tipoEntregas: IEstado[];
    clientes: IEstado[];
    sucursales: IEstado[];
    repartidores: IEstado[];
  };
}

interface IEstado {
  descripcion: string;
  id: string;
  orden: string;
}

interface IUseForm {
  identrega: number;
  idestado: string[] | number;
  producto: string;
  fecha_inicio: string;
  fecha_fin: string;
  codinterno: string;
  repartidor: string[] | number;
  sucursal: string[] | number;
  tipo_entrega: string[] | number;
  idtipoconector: string[] | number;
  impreso: number;
  es_turbo: number;
  fecha_despacho: string | null;
  fecha_entrega: string | null;
  cancelado: number;
  idtipoentrega: string[] | number;
  nroventa: string;
  fecha_entrega_inicio: string | null;
  fecha_entrega_fin: string | null;
  fecha_despacho_inicio: string | null;
  fecha_despacho_fin: string | null;
}

interface ISel {
  label: string;
  value: number;
}

interface IDate {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

const FiltroEntregas: React.FC<IFiltroEntregas> = ({ props }) => {
  const [showFecha, setshowFecha] = useState(false);
  const [target, setTarget] = useState(null);
  const refFecha = useRef(null);
  const [showFechaDespacho, setshowFechaDespacho] = useState(false);
  const [targetDespacho, setTargetDespacho] = useState(null);
  const refFechaDespacho = useRef(null);
  const [showFechaEntrega, setshowFechaEntrega] = useState(false);
  const [targetEntrega, setTargetEntrega] = useState(null);
  const refFechaEntrega = useRef(null);
  const [fechas, setFechas] = useState({
    startDate: moment.tz('America/Argentina/Buenos_Aires').subtract(REACT_APP_DIAS_DASHBOARD, 'days').startOf('day').toDate(),
    endDate: moment.tz('America/Argentina/Buenos_Aires').startOf('day').toDate(),
  });
  const [fechasDespacho, setFechasDespacho] = useState<IDate>({
    startDate: undefined,
    endDate: undefined,
  });
  const [fechasEntrega, setFechasEntrega] = useState<IDate>({
    startDate: undefined,
    endDate: undefined,
  });
  const [conectorSeleccionado, setconectorSeleccionado] = useState<ISel[]>([]);
  const [estadoSeleccionado, setestadoSeleccionado] = useState<ISel[]>([]);
  const [sucursalSeleccionado, setsucursalSeleccionado] = useState<ISel[]>([]);
  const [repartidorSeleccionado, setrepartidorSeleccionado] = useState<ISel[]>([]);
  const [esTurboSeleccionado, setesTurboSeleccionado] = useState<ISel[]>([]);
  const [impresoSeleccionado, setimpresoSeleccionado] = useState<ISel[]>([]);
  const [canceladoSeleccionado, setcanceladoSeleccionado] = useState<ISel[]>([]);
  const [sel_estado, setsel_estados] = useState<ISel[]>([]);
  const [sel_tipoconector, setsel_tipoconector] = useState<ISel[]>([]);
  const [sel_esturbo, setsel_esturbo] = useState<ISel[]>([
    { value: 3, label: 'Seleccione' },
    { value: 1, label: 'Si' },
    { value: 0, label: 'No' },
  ]);
  const [sel_impreso, setsel_impreso] = useState<ISel[]>([
    { value: 3, label: 'Seleccione' },
    { value: 1, label: 'Si' },
    { value: 0, label: 'No' },
  ]);
  const [sel_cancelado, setsel_cancelado] = useState<ISel[]>([
    { value: 3, label: 'Seleccione' },
    { value: 1, label: 'Si' },
    { value: 0, label: 'No' },
  ]);
  const [sel_tipoentregas, setsel_tipoentregas] = useState<ISel[]>([]);
  const [sel_cliente, setsel_cliente] = useState<ISel[]>([]);
  const [sel_sucursal, setsel_sucursal] = useState<ISel[]>([]);
  const [sel_repartidor, setsel_repartidor] = useState<ISel[]>([]);
  const [defaultEstados, setdefaultEstados] = useState<ISel[]>([]);
  const [defaultConector, setdefaultConector] = useState<ISel[]>([]);
  const [defaultSucursales, setdefaultSucursales] = useState<ISel[]>([]);
  const [defaultRepartidores, setdefaultRepartidores] = useState<ISel[]>([]);
  const [defaultEsTurbo, setdefaultEsTurbo] = useState<ISel[]>([]);
  const [defaultImpreso, setdefaultImpreso] = useState<ISel[]>([]);
  const [defaultCancelado, setdefaultCancelado] = useState<ISel[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const { register, getValues, setValue } = useForm<IUseForm>({
    defaultValues: {
      identrega: 0,
      idestado: [],
      producto: '',
      fecha_inicio: fechaDesde,
      fecha_fin: fechaHasta,
      codinterno: '',
      repartidor: 0,
      sucursal: 0,
      tipo_entrega: 0,
      idtipoconector: 0,
      impreso: 3,
      es_turbo: 3,
      fecha_despacho: null,
      fecha_entrega: null,
      cancelado: 3,
      idtipoentrega: 0,
      nroventa: '',
      fecha_entrega_inicio: '',
      fecha_entrega_fin: '',
      fecha_despacho_inicio: '',
      fecha_despacho_fin: '',
    },
  });
  const handleEstadoChange = (selectedOption: any) => {
    setValue(
      'idestado',
      selectedOption.map((value: any) => value.value)
    );
    setestadoSeleccionado(selectedOption);
  };
  const handleSucursalChange = (selectedOption: any) => {
    setValue(
      'sucursal',
      selectedOption.map((value: any) => value.value)
    );
    setsucursalSeleccionado(selectedOption);
  };
  const handleRepartidorChange = (selectedOption: any) => {
    setValue(
      'repartidor',
      selectedOption.map((value: any) => value.value)
    );
    setrepartidorSeleccionado(selectedOption);
  };
  const handleEsTurboChange = (selectedOption: any) => {
    setValue('es_turbo', selectedOption.value);
    setesTurboSeleccionado(selectedOption);
  };
  const handleImpresoChange = (selectedOption: any) => {
    setValue('impreso', selectedOption.value);
    setimpresoSeleccionado(selectedOption);
  };
  const handleCanceladoChange = (selectedOption: any) => {
    setValue('cancelado', selectedOption.value);
    setcanceladoSeleccionado(selectedOption);
  };
  async function submitFiltros() {
    try {
      localStorage.setItem('filtrosentregas', JSON.stringify(getValues()));
      props.SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
      props.OffCanvasFiltrosClose();
    } catch (error) {
      console.log(error);
    }
  }
  const handleConectorChange = (selectedOption: any) => {
    setValue(
      'idtipoconector',
      selectedOption.map((value: any) => value.value)
    );
    setconectorSeleccionado(selectedOption);
  };
  function resetFiltros() {
    setValue('fecha_inicio', moment().subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD'));
    setValue('fecha_fin', moment().format('YYYY-MM-DD'));
    setValue('idtipoconector', 0);
    setValue('idtipoentrega', 0);
    setValue('idestado', 0);
    setValue('nroventa', '');
    setValue('producto', '');
    setValue('sucursal', 0);
    setValue('repartidor', 0);
    setValue('es_turbo', 3);
    setValue('impreso', 3);
    setValue('fecha_entrega_inicio', null);
    setValue('fecha_entrega_fin', null);
    setValue('fecha_despacho_inicio', null);
    setValue('fecha_despacho_fin', null);
    setValue('cancelado', 3);
    setimpresoSeleccionado([]);
    setesTurboSeleccionado([]);
    setconectorSeleccionado([]);
    setestadoSeleccionado([]);
    setsucursalSeleccionado([]);
    setrepartidorSeleccionado([]);
    setcanceladoSeleccionado([]);
    setFechasEntrega({
      startDate: undefined,
      endDate: undefined,
    });
    setFechasDespacho({
      startDate: undefined,
      endDate: undefined,
    });
    setFechas({
      startDate: moment.tz('America/Argentina/Buenos_Aires').subtract(REACT_APP_DIAS_DASHBOARD, 'days').startOf('day').toDate(),
      endDate: moment.tz('America/Argentina/Buenos_Aires').startOf('day').toDate(),
    });
  }
  function changeDateRange(e: RangeKeyDict) {
    const { range1 } = e;
    const startDate = moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').startOf('day').toDate();
    const endDate = moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').endOf('day').toDate();
    setFechas({
      startDate,
      endDate,
    });
    setValue('fecha_inicio', moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    setValue('fecha_fin', moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
  }
  function changeDateRangeDespacho(e: RangeKeyDict) {
    const { range1 } = e;
    const startDate: Date = moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').startOf('day').toDate();
    const endDate: Date = moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').endOf('day').toDate();
    setFechasDespacho({
      startDate,
      endDate,
    });
    setValue('fecha_despacho_inicio', moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    setValue('fecha_despacho_fin', moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
  }
  function changeDateRangeEntrega(e: RangeKeyDict) {
    const { range1 } = e;
    const startDate = moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').startOf('day').toDate();
    const endDate = moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').endOf('day').toDate();
    setFechasEntrega({
      startDate,
      endDate,
    });
    setValue('fecha_entrega_inicio', moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    setValue('fecha_entrega_fin', moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
  }
  useEffect(() => {
    let filtros = localStorage.getItem('filtrosentregas')?.length ? JSON.parse(localStorage.getItem('filtrosentregas') || '{}') : null;
    setsel_tipoentregas(props.tipoConector?.map((value, key) => ({ value: parseInt(value.orden), label: value.descripcion })));
    setsel_tipoentregas(props.tipoEntregas?.map((value, key) => ({ value: parseInt(value.orden), label: value.descripcion })));
    setsel_tipoconector(props.tipoConector?.map((value, key) => ({ value: parseInt(value.orden), label: value.descripcion })));
    setsel_sucursal(props.sucursales?.map((value, key) => ({ value: parseInt(value.id), label: value.descripcion })));
    setsel_repartidor(props.repartidores?.map((value, key) => ({ value: parseInt(value.id), label: value.descripcion })));
    setsel_estados(props.estados.map((value) => ({ value: parseInt(value.id), label: value.descripcion })));
    if (filtros) {
      if (!filtros.hasOwnProperty('identrega')) filtros.identrega = 0;
      if (!filtros.hasOwnProperty('idestado')) filtros.idestado = [];
      if (!filtros.hasOwnProperty('codinterno')) filtros.codinterno = '';
      if (!filtros.hasOwnProperty('nroventa')) filtros.nroventa = '';
      if (!filtros.hasOwnProperty('producto')) filtros.producto = '';
      if (!filtros.hasOwnProperty('fecha_inicio')) filtros.fecha_inicio = fechaDesde;
      if (!filtros.hasOwnProperty('fecha_fin')) filtros.fecha_fin = fechaHasta;
      if (!filtros.hasOwnProperty('codinterno')) filtros.fecha_hasta = '';
      if (!filtros.hasOwnProperty('repartidor')) filtros.repartidor = 0;
      if (!filtros.hasOwnProperty('sucursal')) filtros.sucursal = 0;
      if (!filtros.hasOwnProperty('cliente')) filtros.cliente = 0;
      if (!filtros.hasOwnProperty('tipo_entrega')) filtros.tipo_entrega = 0;
      if (!filtros.hasOwnProperty('impreso')) filtros.impreso = 3;
      if (!filtros.hasOwnProperty('es_turbo')) filtros.es_turbo = 3;
      if (!filtros.hasOwnProperty('fecha_despacho_inicio')) filtros.fecha_despacho_inicio = null;
      if (!filtros.hasOwnProperty('fecha_despacho_fin')) filtros.fecha_despacho_fin = null;
      if (!filtros.hasOwnProperty('fecha_entrega_inicio')) filtros.fecha_entrega_inicio = null;
      if (!filtros.hasOwnProperty('fecha_entrega_fin')) filtros.fecha_entrega_fin = null;
      if (!filtros.hasOwnProperty('cancelado')) filtros.cancelado = 3;
      setValue('identrega', filtros.identrega);
      setValue('idestado', filtros.idestado);
      setValue('codinterno', filtros.codinterno);
      setValue('nroventa', filtros.nroventa);
      setValue('producto', filtros.producto);
      setValue('fecha_inicio', filtros.fecha_inicio);
      setValue('fecha_fin', filtros.fecha_fin);
      setValue('codinterno', filtros.codinterno);
      setValue('es_turbo', filtros.es_turbo);
      setValue('impreso', filtros.impreso);
      setValue('fecha_despacho_inicio', filtros.fecha_despacho_inicio);
      setValue('fecha_despacho_fin', filtros.fecha_despacho_fin);
      setValue('fecha_entrega_inicio', filtros.fecha_entrega_inicio);
      setValue('fecha_entrega_fin', filtros.fecha_entrega_fin);
      setValue('cancelado', filtros.cancelado);
      setFechas({
        startDate: moment.tz(filtros.fecha_inicio, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
        endDate: moment.tz(filtros.fecha_fin, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
      });
      if (filtros.fecha_despacho_inicio && filtros.fecha_despacho_fin) {
        setFechasDespacho({
          startDate: moment.tz(filtros.fecha_despacho_inicio, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
          endDate: moment.tz(filtros.fecha_despacho_fin, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
        });
      }
      if (filtros.fecha_entrega_inicio && filtros.fecha_entrega_fin) {
        setFechasEntrega({
          startDate: moment.tz(filtros.fecha_entrega_inicio, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
          endDate: moment.tz(filtros.fecha_entrega_fin, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
        });
      }
      let conectorArray: any = [];
      let sucursalesArray: any = [];
      let repartidoresArray: any = [];
      let esturboArray: any = [];
      let impresoArray: any = [];
      let canceladoArray: any = [];
      let estadosArray: any = props.estados
        .filter((value) => {
          if (filtros && filtros.idestado && filtros?.idestado?.includes(value.orden)) return value;
        })
        .map((value) => ({ value: value.orden, label: value.descripcion }));
      if (typeof filtros.idtipoconector === 'object') {
        conectorArray = props.tipoConector
          .filter((value) => {
            if (filtros?.idtipoconector?.includes(value.orden)) return value;
          })
          .map((value) => ({ value: value.orden, label: value.descripcion }));
      }
      if (typeof filtros.sucursal === 'object') {
        sucursalesArray = props.sucursales
          .filter((value) => {
            if (filtros?.sucursal?.includes(value.id)) return value;
          })
          .map((value) => ({ value: value.id, label: value.descripcion }));
      }
      if (typeof filtros.repartidor === 'object') {
        repartidoresArray = props.repartidores
          .filter((value) => {
            if (filtros?.repartidor?.includes(value.id)) return value;
          })
          .map((value) => ({ value: value.id, label: value.descripcion }));
      }
      if (filtros.es_turbo === 0 || filtros.es_turbo === 1) {
        esturboArray = [
          { value: 3, label: 'Seleccione' },
          { value: 1, label: 'Si' },
          { value: 0, label: 'No' },
        ]
          .filter((value) => {
            if (value.value === filtros.es_turbo) return value;
          })
          .map((value) => ({ value: value.value, label: value.label }));
      }
      if (filtros.impreso === 0 || filtros.impreso === 1) {
        impresoArray = [
          { value: 3, label: 'Seleccione' },
          { value: 1, label: 'Si' },
          { value: 0, label: 'No' },
        ]
          .filter((value) => {
            if (value.value === filtros.impreso) return value;
          })
          .map((value) => ({ value: value.value, label: value.label }));
      }
      if (filtros.cancelado === 0 || filtros.cancelado === 1) {
        canceladoArray = [
          { value: 3, label: 'Seleccione' },
          { value: 1, label: 'Si' },
          { value: 0, label: 'No' },
        ]
          .filter((value) => {
            if (value.value === filtros.cancelado) return value;
          })
          .map((value) => ({ value: value.value, label: value.label }));
      }
      setdefaultEstados(estadosArray);
      setdefaultConector(conectorArray);
      setdefaultSucursales(sucursalesArray);
      setdefaultRepartidores(repartidoresArray);
      setdefaultEsTurbo(esturboArray);
      setdefaultImpreso(impresoArray);
      setdefaultCancelado(canceladoArray);
      if (estadosArray?.length) setestadoSeleccionado(estadosArray);
      if (conectorArray?.length) setconectorSeleccionado(conectorArray);
      if (sucursalesArray?.length) setsucursalSeleccionado(sucursalesArray);
      if (repartidoresArray?.length) setrepartidorSeleccionado(repartidoresArray);
      if (esturboArray?.length) setesTurboSeleccionado(esturboArray);
      if (impresoArray?.length) setimpresoSeleccionado(impresoArray);
      if (canceladoArray?.length) setcanceladoSeleccionado(canceladoArray);
    }
    setDataLoaded(true);
  }, []);
  function functionShowFecha(e: any) {
    setshowFecha(!showFecha);
    setTarget(e.target);
  }
  function functionShowFechaDespacho(e: any) {
    setshowFechaDespacho(!showFechaDespacho);
    setTargetDespacho(e.target);
  }
  function functionShowFechaEntrega(e: any) {
    setshowFechaEntrega(!showFechaEntrega);
    setTargetEntrega(e.target);
  }
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <Form.Label htmlFor='fecha'>Fecha</Form.Label>
            <EurekaOverlay target={target} ref={refFecha} titlePop='Fecha' arrayTitle={Object.values(fechas)} show={showFecha} handleClick={functionShowFecha}>
              <DateRangePicker ranges={[fechas]} onChange={changeDateRange} />
            </EurekaOverlay>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_despacho' className='pt-2'>
              Fecha despacho
            </Form.Label>
            <EurekaOverlay target={targetDespacho} ref={refFechaDespacho} titlePop='Fecha despacho' arrayTitle={Object.values(fechasDespacho)} show={showFechaDespacho} handleClick={functionShowFechaDespacho}>
              <DateRangePicker ranges={[fechasDespacho]} onChange={changeDateRangeDespacho} />
            </EurekaOverlay>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_entrega' className='pt-2'>
              Fecha entrega
            </Form.Label>
            <EurekaOverlay target={targetEntrega} ref={refFechaEntrega} titlePop='Fecha entrega' arrayTitle={Object.values(fechasEntrega)} show={showFechaEntrega} handleClick={functionShowFechaEntrega}>
              <DateRangePicker ranges={[fechasEntrega]} onChange={changeDateRangeEntrega} />
            </EurekaOverlay>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idturbo'>Turbo</Form.Label>
            <Select id='idturbo' defaultValue={sel_esturbo.filter((value) => value.value === getValues().es_turbo)} value={esTurboSeleccionado} {...register('es_turbo')} onChange={handleEsTurboChange} options={sel_esturbo} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idimpreso'>Impreso</Form.Label>
            <Select id='idimpreso' defaultValue={sel_impreso.filter((value) => value.value === getValues().impreso)} value={impresoSeleccionado} {...register('impreso')} onChange={handleImpresoChange} options={sel_impreso} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idcancelado'>Cancelado</Form.Label>
            <Select id='idcancelado' defaultValue={sel_cancelado.filter((value) => value.value === getValues().cancelado)} value={canceladoSeleccionado} {...register('cancelado')} onChange={handleCanceladoChange} options={sel_cancelado} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idtipoconector'>Tipo conector</Form.Label>
            <Select isMulti id='idtipoconector' defaultValue={defaultConector} value={conectorSeleccionado} {...register('idtipoconector')} onChange={handleConectorChange} options={sel_tipoconector} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idestado'>Estado</Form.Label>
            {dataLoaded && <Select isMulti defaultValue={defaultEstados} id='idestado' value={estadoSeleccionado} {...register('idestado')} onChange={handleEstadoChange} options={sel_estado} />}
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idsucursal'>Sucursal</Form.Label>
            <Select isMulti id='idsucursal' defaultValue={defaultSucursales} value={sucursalSeleccionado} {...register('sucursal')} onChange={handleSucursalChange} options={sel_sucursal} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idsucursal'>Repartidor</Form.Label>
            <Select isMulti id='idsucursal' defaultValue={defaultRepartidores} value={repartidorSeleccionado} {...register('repartidor')} onChange={handleRepartidorChange} options={sel_repartidor} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='nroventa'>Nro venta</Form.Label>
            <Form.Control type='text' id='nroventa' placeholder='Igual a ...' {...register('nroventa')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='codinterno'>Cod interno</Form.Label>
            <Form.Control type='text' id='codinterno' placeholder='Contiene ...' {...register('codinterno')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='producto'>Producto</Form.Label>
            <Form.Control type='text' id='producto' placeholder='Contiene ...' {...register('producto')} />
          </div>
          <div className='form-group mt-4'>
            <button onClick={resetFiltros} className='btn btn-warning w-50 btn-sm'>
              Resetear
            </button>
            <button onClick={submitFiltros} className='btn btn-primary w-50 btn-sm'>
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltroEntregas;
