import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { DeleteEureka, PostEureka, GetEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
import moment from 'moment-timezone';

interface IFormSku {
  formDisabled?: boolean;
  getValues: UseFormGetValues<IUseForm>;
  modalClose: Function;
  setRecargaGridSku: Function;
  SetRefresh?: Function;
  modalSku: IModalSku;
}

interface IModalSku {
  show: boolean;
  id: number;
  accion: string;
}

interface IUseForm {
  idarticulo: number;
  articulo: string;
  sku: string;
  stock_actual: number;
  stock_reservado: number;
  stock_disponible: number;
  stock_minimo: number;
  tipo: string;
  marca: string;
  modelo: string;
  proveedor: string;
  precio_venta: string;
  disabled: boolean;
  json_imagenes: string;
  idtipo: number;
  idmarca: number;
  idmodelo: number;
  idproveedor: number;
  alto: string;
  ancho: string;
  largo: string;
  valorizacion: string;
  peso: string;
  peso_volumetrico: string;
  idfiltroproducto: number;
  sku_flexit: string;
}

interface IUseFormSku {
  articulo: string;
  sku: string;
  idproducto: number;
}

const FormSku: React.FC<IFormSku> = ({ formDisabled, getValues, modalClose, setRecargaGridSku, SetRefresh, modalSku }) => {
  const [disabledLocal, setdisabledLocal] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IUseFormSku>({
    defaultValues: {
      articulo: getValues().articulo,
      sku: '',
      idproducto: getValues().idarticulo,
    },
  });
  async function onSubmit(e: IUseFormSku): Promise<void> {
    await PostEureka('/articulos/crearsku', e);
    setRecargaGridSku(moment().format('YYYY-MM-DD HH:mm:ss'));
    SetRefresh && SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
    modalClose();
  }
  async function deleteSku(e: React.MouseEvent<HTMLButtonElement>, modalSku: IModalSku) {
    e.preventDefault();
    DeleteEureka(`/articulos/eliminarArticuloSku/${modalSku.id}`).then(() => {
      setRecargaGridSku(moment().format('YYYY-MM-DD HH:mm:ss'));
      SetRefresh && SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
      modalClose();
    });
  }
  const articulobyid = (registro: number, accion: string) => {
    GetEureka('/articulos/byidsku/' + registro).then((response) => {
      if (response.info) {
        var registro = response.content[0];
        setValue('sku', registro.sku);
      } else {
        console.log(response);
        throw new Error('Error de peticion');
      }
    });
  };
  useEffect(() => {
    if (modalSku.accion !== 'cr') {
      articulobyid(modalSku.id, modalSku.accion);
      setdisabledLocal(true);
    }
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='mt-2'>
        <Form.Label htmlFor='articulo'>Articulo</Form.Label>
        <Form.Control type='text' disabled={true} {...register('articulo')} />
      </div>
      <div className='mt-2'>
        <Form.Label htmlFor='sku'>SKU</Form.Label>
        <Form.Control type='text' disabled={disabledLocal} {...register('sku', { required: true })} />
        <div>{errors.sku ? <p className='text-danger'>Este campo es obligatorio</p> : null}</div>
      </div>
      <div className='d-flex mt-2'>
        {modalSku.accion === 'cr' && (
          <button className='btn btn-sm btn-success w-100' type='submit'>
            Crear
          </button>
        )}
        {modalSku.accion === 'b' && (
          <button className='btn btn-sm btn-danger w-100' onClick={(e) => deleteSku(e, modalSku)}>
            Delete
          </button>
        )}
        {modalSku.accion === 'c' && (
          <button className='btn btn-sm btn-danger w-100' onClick={() => modalClose()}>
            Cerrar
          </button>
        )}
      </div>
    </form>
  );
};

export default FormSku;
