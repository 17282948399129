import { useState } from 'react';
import { Box, Form, Heading, Button, Image } from '../../components/elements';
import { FlexitContext } from '../../context/Flexit';
import IconField from '../../components/fields/IconField';
import Logo from '../../components/Logo';
import data from '../../data/master/forgot.json';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo/logo/72/Logo_72_original.png';
import { PutEureka } from '../../components-new/estructura/EurekaGeneral/EurekaGeneral';
import useGetComplexObject from '../../hooks/useGetComplexObject';

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

const ForgotPassword = () => {
  const { datos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [newPass, setNewPass] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  async function changePass(pass: any) {
    const respuesta = await PutEureka(`/Usuarios/CambioPassword`, {
      sandbox: true,
      pass,
    });
    return respuesta;
  }
  function changePassLocal(e: any) {
    setNewPass(e.target.value);
  }
  const verifyType = (type: string): 'button' | 'submit' | 'reset' | undefined => (type === 'button' || type === 'submit' || type === 'reset' ? type : undefined);
  return (
    <Box className='mc-auth'>
      <Image className='mc-auth-pattern' src={data?.pattern.src} alt={data?.pattern.alt} />
      <Box className='mc-auth-group'>
        <Logo src={logo} alt={data?.logo.alt} href={data?.logo.path} className='mc-auth-logo' />
        <Heading as='h4' className='mc-auth-title'>
          {data?.title}
        </Heading>
        <Form className='mc-auth-form'>
          {data?.input.map((item, index) => (
            <IconField key={index} icon={item.icon} value={newPass} type={item.type} classes={item.fieldSize} placeholder={item.placeholder} onChangeInput={changePassLocal} />
          ))}
          <div hidden={newPass?.length > 0} className='mensaje-rojo-flexit mb-3'>
            Falta completar campos
          </div>
          <div hidden={!error?.length} className='mensaje-rojo-flexit mb-3'>
            {error}
          </div>
          <Button
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={verifyType(data?.button.type)}
            disabled={!newPass.length}
            onClick={() =>
              changePass({ password: newPass, idusuario: datos?.usuario.idusuario }).then((res) => {
                if (res.content !== 'error') navigate('/inicio');
                else setError(res.msg);
              })
            }>
            {data?.button.text}
          </Button>
        </Form>
        <Box className='mc-auth-navigate'>
          <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type='button' onClick={() => navigate('/inicio')}>
            Volver al inicio
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
