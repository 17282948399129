import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CardHeader, CardLayout, FloatCard } from '../../components/cards/index.ts';
import { Anchor, Box, Text, List } from '../../components/elements/index.ts';
import RoundAvatar from '../../components/RoundAvatar.tsx';
import DuelText from '../../components/DuelText.tsx';
import DivideTitle from '../../components/DivideTitle.tsx';
import data from '../../data/master/userProfile.json';
import EurekaMapa from '../estructura/EurekaMapa/EurekaMapa.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faFacebookF, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMap } from '@fortawesome/free-solid-svg-icons';
import EurekaModal from '../estructura/EurekaModals/EurekaModals.tsx';
import { GetEureka, PostEureka, ChangeUrlImage } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import FormDashboard from './FormDashboard/FormDashboard.tsx';
const { REACT_APP_DIAS_DASHBOARD } = process.env;
interface Usuario {
  username: string;
  nombres: string;
  apellidos: string;
  imagen: string;
  telefono: string;
  email: string;
  direccion: string;
  coordenadas: number[];
}

interface IDatosDashboard {
  conteo_diario: {};
  ccorriente_diario: [];
  conteo_mensual: {
    saldoactual: number;
    entregas_observadas: number;
    entregas_pendientes: number;
  };
  productos: [];
  coordenadas_entregas: [];
  polygon: {
    paths: google.maps.MVCArray<google.maps.LatLng> | google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> | google.maps.LatLng[] | google.maps.LatLng[][] | google.maps.LatLngLiteral[] | google.maps.LatLngLiteral[][];
  }[];
}

const TabDashboard: React.FC = (): JSX.Element => {
  const [refreshData, setrefreshData] = useState(false);
  const [tipoEntrega, settipoEntregas] = useState([]);
  const [modalDashboard, setmodalDashboard] = useState({
    show: false,
    id: 0,
    accion: '',
  });
  const showModalDashboard = () => setmodalDashboard({ show: true, id: 0, accion: '' });
  const closeModalDashboard = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.preventDefault();
    setmodalDashboard({ show: false, id: 0, accion: '' });
  };
  const [usuarios, setUsuarios] = useState<Usuario>({
    username: '',
    nombres: '',
    apellidos: '',
    imagen: '',
    telefono: '',
    email: '',
    direccion: '',
    coordenadas: [],
  });
  const loadUsuarios = (username: string, nombres: string, apellidos: string, imagen: string, telefono: string, email: string, direccion: string, coordenadas: number[]) => {
    setUsuarios({
      username: username,
      nombres: nombres,
      apellidos: apellidos,
      imagen: imagen,
      telefono: telefono,
      email: email,
      direccion: direccion,
      coordenadas: coordenadas,
    });
  };
  const [datosDash, setDatosDash] = useState<IDatosDashboard>({
    conteo_diario: {},
    ccorriente_diario: [],
    conteo_mensual: {
      saldoactual: 0,
      entregas_observadas: 0,
      entregas_pendientes: 0,
    },
    productos: [],
    coordenadas_entregas: [],
    polygon: [],
  });
  useEffect(() => {
    let filtrosDashboard = localStorage.getItem('filtrodashboard');
    let body = {
      tipoconector: '',
      tipoentrega: '',
      tipoestado: '',
      tipocliente: '',
      tiposucursales: '',
      tiporepartidores: '',
      tiponroventa: '',
      tipocodinterno: '',
      fecha_alta: '',
      fecha_entrega: '',
      tipoproducto: '',
      fecha_hasta: '',
      turbo: '',
      impreso: '',
    };
    GetEureka('/entregas/combos').then((response) => settipoEntregas(response.content.tipo_entregas));
    PostEureka('/dashboard/' + REACT_APP_DIAS_DASHBOARD, filtrosDashboard ? JSON.parse(filtrosDashboard) : body).then((response) => {
      var cliente = response.content.cliente;
      let datos = response.content;
      setDatosDash({
        coordenadas_entregas: datos.coordenadas_entregas,
        conteo_diario: datos.conteo_diario,
        ccorriente_diario: datos.ccorriente_diario,
        conteo_mensual: datos.conteo_mensual,
        productos: datos.productos,
        polygon: datos.polygon,
      });
      loadUsuarios(cliente.username, cliente.nombres, cliente.apellidos, cliente.imagen, cliente.telefono, cliente.email, cliente.direccion, cliente.coordenadas);
    });
  }, [refreshData]);
  return (
    <div>
      <Row className='mt-1'>
        <Col xl={4}>
          <CardLayout className='shadow-lg'>
            <CardHeader title='Datos cliente' className='colorLetra' />
            <Box className='mc-user-group'>
              <Box className='mc-user-profile'>
                {usuarios.imagen === '' ? (
                  <div className='spinner-border m-5' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : (
                  <>
                    <RoundAvatar src={ChangeUrlImage(usuarios.imagen)} />
                    <DuelText className='colorLetra' title={usuarios.nombres + ' ' + usuarios.apellidos} descrip={'@' + usuarios.username} />
                  </>
                )}
              </Box>
              <Box className='mb-4'>
                <DivideTitle title='Contacto' className='mb-4 colorLetra' />
                <List className='mc-user-metalist'>
                  <div className='row'>
                    <div className='col-12 colorLetra'>
                      {usuarios.telefono === '' ? (
                        <p className='placeholder-glow'>
                          <span className='placeholder col-12'></span>
                        </p>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faPhone} /> : {usuarios.telefono}
                        </>
                      )}
                    </div>
                    <div className='col-12 colorLetra'>
                      {usuarios.email === '' ? (
                        <p className='placeholder-glow'>
                          <span className='placeholder col-12'></span>
                        </p>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faEnvelope} /> : {usuarios.email}
                        </>
                      )}
                    </div>
                    <div className='col-12 colorLetra'>
                      {usuarios.direccion === '' ? (
                        <p className='placeholder-glow'>
                          <span className='placeholder col-12'></span>
                        </p>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faMap} /> : {usuarios.direccion}
                        </>
                      )}
                    </div>
                  </div>
                </List>
              </Box>
              <Box className='mb-4 hide'>
                <DivideTitle title={data?.bio.title} className='mb-3' />
                <Text className='mc-user-bio mb-4'>{data?.bio.descrip}</Text>
              </Box>
              <Box>
                <DivideTitle title='Redes sociales' className='mb-4 colorLetra' />
                <Box className='mc-user-social'>
                  <Anchor className='facebook' href='#'>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Anchor>
                  <Anchor className='linkedin' href='#'>
                    <FontAwesomeIcon icon={faTwitter} />
                  </Anchor>
                  <Anchor className='linkedin' href='#'>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Anchor>
                  <Anchor className='youtube' href='#'>
                    <FontAwesomeIcon icon={faYoutube} />
                  </Anchor>
                  <Anchor className='facebook' href='#'>
                    <FontAwesomeIcon icon={faApple} />
                  </Anchor>
                </Box>
              </Box>
            </Box>
          </CardLayout>
        </Col>
        <Col xl={8}>
          <Row>
            <Col md={4} lg={4}>
              <FloatCard variant='sm green' digit={datosDash?.conteo_mensual?.saldoactual?.toFixed(2)} title='Saldo Actual' icon='monetization_on' />
            </Col>
            <Col md={4} lg={4}>
              <FloatCard variant='sm purple' digit={datosDash.conteo_mensual.entregas_observadas.toString()} title='Entregas Observadas' icon='list_alt' />
            </Col>
            <Col md={4} lg={4}>
              <FloatCard variant='sm yellow' digit={datosDash.conteo_mensual.entregas_pendientes.toString()} title='Entregas Pendientes' icon='monetization_on' />
            </Col>
          </Row>
          <Row className='mt-1'>
            <EurekaModal id='modal-dashboard' modalTitulo='Filtros Mapa' manejador={modalDashboard} handleClose={closeModalDashboard}>
              <FormDashboard props={{ tipoEntrega, closeModalDashboard, setrefreshData }} />
            </EurekaModal>
            <button onClick={showModalDashboard} className='btn btn-sm btn-primary w-100'>
              Filtro avanzado
            </button>
            <EurekaMapa coordenadas={datosDash.coordenadas_entregas} polygons={datosDash.polygon} />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TabDashboard;
