import { useEffect, useState } from 'react';
import EurekaGeneral, { PutEureka, GetEureka } from '../../../EurekaGeneral/EurekaGeneral.ts';
import { FlexitContext } from '../../../../../context/Flexit.tsx';
import EurekaOverlayTrigger from '../../../EurekaOverlayTrigger/EurekaOverlayTrigger.tsx';
import 'rsuite/dist/rsuite.min.css';
import moment from 'moment';
import { Form, Table, Button } from 'react-bootstrap';
import { EnviarMensaje } from '../../../EurekaGeneral/EurekaGeneral.ts';
import { useForm } from 'react-hook-form';
import useGetComplexObject from '../../../../../hooks/useGetComplexObject.ts';

interface IFormCanceladosOrdenes {
  ListaChecks: { idremito: number; idcliente: number; nroremito: string }[];
  SetRefresh: Function;
  ModalClose: Function;
}

interface IFormModificarOrdenes extends IFormCanceladosOrdenes {
  formDisabled: boolean;
  modal: {
    accion: string;
    id: number;
    tipo: number;
    show: boolean;
  };
}

interface IUseFormCancelados {
  estado: string;
  fecha_alta: string;
  idestado: number;
  idremito: number;
  nroremito: string;
  observaciones: string;
  productos: { idproducto: number; cantidad: number; sku: string; producto: string }[];
  sucursal: string;
  total: number;
}

interface IProducto {
  cantidad: number;
  sku: string;
  producto: string;
  idproducto: number;
}

export const FormCanceladosOrdenes: React.FC<IFormCanceladosOrdenes> = ({ ListaChecks = [], SetRefresh, ModalClose }): JSX.Element => {
  function submit_cancelar_ordenes() {
    PutEureka('/Remitos/Baja', { ordenes: ListaChecks }).then((response) => {
      if (response.info) {
        document.querySelectorAll<HTMLInputElement>('[class^="chk_"]:checked').forEach((input) => {
          input.checked = false;
        });
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># remito</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroremito}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary sm' onClick={submit_cancelar_ordenes}>
          Cancelar
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay remitos seleccionadas
      </div>
    );
  }
};
interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}
export const FormModificarOrdenes: React.FC<IFormModificarOrdenes> = ({ ListaChecks = [], SetRefresh, ModalClose, formDisabled = false, modal }): JSX.Element => {
  const { datos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [combosProductos, setCombosProductos] = useState([]);
  const [producto, setProducto] = useState<IProducto>({ cantidad: 0, sku: '', producto: '', idproducto: 0 });
  const [productosLocal, setProductosLocal] = useState<IProducto[]>([]);
  const { setValue, register, getValues, handleSubmit } = useForm<IUseFormCancelados>({
    defaultValues: {
      estado: '',
      fecha_alta: '',
      idestado: 0,
      idremito: 0,
      nroremito: '',
      observaciones: '',
      productos: [],
      sucursal: '',
      total: 0,
    },
  });
  function submitModification(e: React.FormEvent<HTMLFormElement>): any {
    if (getValues()?.productos?.length) {
      let masajeo = { ...getValues(), mod_manual: datos.usuario.username };
      PutEureka(`/Remitos/${getValues().idremito}`, masajeo).then((response) => {
        EnviarMensaje('success', `Remito ${response.content[0].idremito} actualizado con exito.`);
        SetRefresh();
        ModalClose();
      });
    } else EnviarMensaje('warning', 'No cuenta con ningun producto, por favor agregue uno.');
  }
  function deleteProduct(id: number) {
    let productoDelete = productosLocal.filter((value) => value.idproducto !== id);
    let arraySetearValue = productoDelete.map((value) => ({ cantidad: Number(value.cantidad), sku: value.sku, producto: value.producto, idproducto: value.idproducto }));
    setProductosLocal(productoDelete);
    setValue('productos', arraySetearValue);
  }
  function escribiendoProducto(e: React.ChangeEvent<HTMLInputElement>) {
    setProducto({ ...producto, [e.target.name]: e.target.value });
  }
  function changeProducto(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value !== '') {
      let values = e.target.value?.split('|');
      setProducto({ ...producto, producto: values[0], sku: values[1], idproducto: parseInt(values[2]) });
    } else setProducto({ ...producto, producto: '', sku: '', idproducto: 0 });
  }
  function submitProduc(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (Number(producto.cantidad) > 0 && producto.sku !== '' && producto.producto !== '') {
      let arrayPushear = [...productosLocal, producto];
      let arraySetearValue = arrayPushear.map((value) => ({ cantidad: Number(value.cantidad), sku: value.sku, producto: value.producto, idproducto: value.idproducto }));
      setProductosLocal(arrayPushear);
      setValue('productos', arraySetearValue);
    } else EnviarMensaje('warning', 'Complete todos los campos y vuelva a intentar agregar el producto.');
  }
  useEffect(() => {
    if (ListaChecks.length) {
      GetEureka(`/Remitos/byid/${ListaChecks[0]?.idremito}`).then((response) => {
        let values = response.content;
        setValue('estado', values.estado);
        setValue('fecha_alta', moment(values.fecha_alta).format('DD/MM/YYYY'));
        setValue('idestado', values.idestado);
        setValue('idremito', values.idremito);
        setValue('nroremito', values.nroremito);
        setValue('observaciones', values.observaciones);
        setValue('productos', values.productos);
        setValue('sucursal', values.sucursal);
        setValue('total', values.total);
        setProductosLocal(JSON.parse(values.productos));
      });
    }
    GetEureka('/Remitos/Productos').then((value) => setCombosProductos(value.content));
  }, [ListaChecks, setValue]);
  if (ListaChecks.length > 0) {
    return (
      <>
        <Form className='mt-2 mb-2' onSubmit={(e) => handleSubmit(submitModification(e))}>
          <div className='row'>
            <div className='col-6'>
              <Form.Label htmlFor='nroremito' className='col-3'>
                Numero de remito
              </Form.Label>
              <Form.Control type='text' disabled={formDisabled} readOnly {...register('nroremito')} />
            </div>
            <div className='col-6'>
              <Form.Label htmlFor='sucursal' className='col-3'>
                Sucursal
              </Form.Label>
              <Form.Control type='text' disabled={formDisabled} readOnly {...register('sucursal')} />
            </div>
            <div className='col-6'>
              <Form.Label htmlFor='fecha_alta' className='col-3'>
                Fecha de alta
              </Form.Label>
              <Form.Control type='text' disabled={formDisabled} readOnly {...register('fecha_alta')} />
            </div>
          </div>
          <div className='row mt-2 mb-2'>
            <div className='col-12'>
              <Form.Label htmlFor='observaciones' className='col-3'>
                Observaciones
              </Form.Label>
              <Form.Control type='text' as='textarea' disabled={formDisabled} {...register('observaciones')} />
            </div>
          </div>
          <div>
            <div className='col-12'>
              <div className='row justify-content-center align-items-end'>
                <Form.Group className='col-2 p-0'>
                  <Form.Label>Cant</Form.Label>
                  <Form.Control disabled={formDisabled} type='text' placeholder='Cantidad' name='cantidad' value={producto.cantidad} onChange={escribiendoProducto} />
                </Form.Group>
                <Form.Group className='col-2 p-0'>
                  <Form.Label>Sku</Form.Label>
                  <Form.Control disabled={formDisabled} type='text' placeholder='Sku' name='sku' value={producto.sku} readOnly />
                </Form.Group>
                <Form.Group className='col-6 p-0'>
                  <Form.Label>Productos</Form.Label>
                  <Form.Select disabled={formDisabled} {...register('productos')} onChange={changeProducto}>
                    <option value={''}>Seleccionar</option>
                    {combosProductos?.map((item: any, key) => (
                      <option key={key} value={item.producto + '|' + item.sku + '|' + item.idproducto}>
                        {item.producto}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Button disabled={formDisabled} variant='primary' className='col-1' onClick={(e) => submitProduc(e)}>
                  +
                </Button>
              </div>
            </div>
            <div className='col-12'>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Cant.</th>
                    <th>SKU</th>
                    <th>Producto</th>
                  </tr>
                </thead>
                <tbody>
                  {productosLocal?.map((value, key) => (
                    <>
                      <tr key={key}>
                        <td>
                          <button
                            className='btn btn-sm btn-danger'
                            disabled={formDisabled}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteProduct(value.idproducto);
                            }}>
                            x
                          </button>
                        </td>
                        <td className='text-center align-middle'>{value.cantidad}</td>
                        <td className='text-center align-middle'>
                          <EurekaOverlayTrigger title={value?.sku?.split(',')[0]} variant='light'>
                            <ul key={key}>
                              {value?.sku
                                ?.split(',')
                                ?.slice(0, value?.sku?.split(',').length)
                                .map((value, key) => (
                                  <li key={key}>{value}</li>
                                ))}
                            </ul>
                          </EurekaOverlayTrigger>
                        </td>
                        <td className='text-center align-middle'>{value.producto}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          {modal.accion === 'c' && (
            <button
              className='w-100 mc-btn primary sm'
              onClick={(e) => {
                e.preventDefault();
                ModalClose();
              }}>
              Cerrar
            </button>
          )}
          {modal.accion === 'm' && (
            <button className='w-100 mc-btn primary sm' type='submit'>
              Modificar
            </button>
          )}
        </Form>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay remitos seleccionadas
      </div>
    );
  }
};

export function tituloModalTableroOrdenes(tipo: string): string {
  var res = '';
  switch (tipo) {
    case 'b':
      res = 'Cancelar remitos';
      break;
    case 'm':
      res = 'Modificar remito';
      break;
    case 'c':
      res = 'Consultar remito';
      break;
    default:
      res = 'Flexit';
      break;
  }
  return res;
}

export default function FormGenericos() {
  return <></>;
}
