import React, { useState, useEffect } from 'react';
import { PostEureka, GetEureka } from '../../../EurekaGeneral/EurekaGeneral';
import { Loading } from 'notiflix';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import { Alert } from 'react-bootstrap';

interface IFormImprimirEntregas {
  ListaChecks: IListaChecks[];
}

interface IListaChecks {
  id: number;
  nroventa: string;
  idsucursal: number;
  idtipoconector: number;
}

interface IJson {
  idconectores: number;
  valido: number;
  identrega: number;
  nro_venta: string;
  tipo: string;
}

const FormImprimirEntregas: React.FC<IFormImprimirEntregas> = ({ ListaChecks = [] }) => {
  const [downloadError, setDownloadError] = useState(false);
  const [downloadErrores, setDownloadErrores] = useState([]);
  const [formato, setFormato] = useState('pdf');
  const [ids, setids] = useState<any>();
  const [json_, setjson_] = useState<IJson[]>([]);
  const [tableRows, settableRows] = useState(
    <tr>
      <td className='alert alert-danger' role='alert'>
        Sin registros para mostrar
      </td>
    </tr>
  );
  const obtenerListaIdUnicos = (json: IJson[]) => {
    let id = 0;
    const list_id = [];
    for (let a = 0; a < json.length; a++) {
      if (!(id === json[a].idconectores)) {
        list_id.push(json[a].idconectores);
        id = json[a].idconectores;
      }
    }
    return list_id;
  };
  async function descargarEntrega(idconector: number, item: { identrega: number; nro_venta: string }) {
    Loading.circle();
    let enviopack = idconector === 7;
    let zpl2: any;
    let format = formato === 'zebra' ? true : false;
    GetEureka('/impresiones/imprimir/imprimir_entregas_by_id', `&identrega=${item.identrega}&formato=${formato}&enviopack=${enviopack}&idtipoconector=${idconector}`)
      .then(async (response) => {
        Loading.remove();
        const browserPrint = new ZebraBrowserPrintWrapper();
        if (format) {
          var zpl = response.content;
          zpl2 = response.content;
          const availables = await browserPrint.getAvailablePrinters();
          const defaultPrinter = availables[0];
          browserPrint.setPrinter(defaultPrinter);
          browserPrint.print(zpl);
          const blob = new Blob([zpl2], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = item.nro_venta + '.txt';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          window.open(response.content);
        }
      })
      .catch((error) => {
        Loading.remove();
        if (format) {
          let data = error?.response?.data?.content || [{ message: 'No se encontraron impresoras' }];
          let zpl3 =
            zpl2 === undefined
              ? data?.reduce((acc: any, next: any) => {
                  acc += next.message + ' ';
                  return acc;
                }, '')
              : zpl2;
          setDownloadError(true);
          setDownloadErrores(data);
          const blob = new Blob([zpl3], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = item.nro_venta + '.txt';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          let data = error.response.data.content;
          setDownloadError(true);
          setDownloadErrores(data);
        }
      });
  }
  const imprimir_entregas_by_conector = (idconector: number, list: string) => {
    let zebra = formato === 'zebra' ? true : false;
    var json = JSON.parse(list);
    var li = '';
    for (var a = 0; json.length > a; a++) {
      li += json[a] + '_';
    }
    li = li.substring(0, li.length - 1);
    let zpl2: any;
    GetEureka(`/impresiones/imprimir/imprimir_entregas_by_conector/${idconector}/${li}`, `&zebra=${zebra}`)
      .then(async (response) => {
        Loading.remove();
        const browserPrint = new ZebraBrowserPrintWrapper();
        if (zebra) {
          var zpl = response.content;
          zpl2 = response.content;
          const availables = await browserPrint.getAvailablePrinters();
          const defaultPrinter = availables[0];
          browserPrint.setPrinter(defaultPrinter);
          browserPrint.print(zpl);
          const blob = new Blob([zpl2], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = li + '.txt';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          window.open(response.content);
        }
      })
      .catch((error) => {
        Loading.remove();
        if (zebra) {
          let data = error?.response?.data?.content || [{ message: 'No se encontraron impresoras' }];
          let zpl3 =
            zpl2 === undefined
              ? data?.reduce((acc: any, next: any) => {
                  acc += next.message + ' ';
                  return acc;
                }, '')
              : zpl2;
          setDownloadError(true);
          setDownloadErrores(data);
          const blob = new Blob([zpl3], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = li + '.txt';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          let data = error.response.data.content;
          setDownloadError(true);
          setDownloadErrores(data);
        }
      });
    /*     let url = REACT_APP_URL_BLUEHOST + "imprimir_entregas_by_idconector/" + idconector + "/l/" + li + "?zebra=" + zebra;
    if (zebra) {
      let zpl2;
      const browserPrint = new ZebraBrowserPrintWrapper();
      axios
        .get(url)
        .then(async (response) => {
          var zpl = response.data;
          zpl2 = response.data;
          const availables = await browserPrint.getAvailablePrinters();
          const defaultPrinter = availables[0];
          browserPrint.setPrinter(defaultPrinter);
          let printerStatus = await browserPrint.checkPrinterStatus();
          if (printerStatus.isReadyToPrint) {
            browserPrint.print(zpl);
          }
        })
        .catch((error) => {
          let zpl3 = zpl2 === undefined ? "Entrega shipped" : zpl2;
          const blob = new Blob([zpl3], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "zebra-impresion" + ".txt";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          EnviarMensaje("danger", "No se encontraron impresoras.");
        });
    } else {
      window.open(url, "_blank");
    } */
  };
  const conteoByConectores = (json: IJson[], idconector: number) => {
    var list = [];
    for (var a = 0; json.length > a; a++) {
      if (idconector === json[a].idconectores) {
        list.push(json[a]);
      }
    }
    return list;
  };
  const validoByConectores = (json: IJson[], idconector: number) => {
    var valido = 0;
    for (var a = 0; json.length > a; a++) {
      if (idconector === json[a].idconectores) {
        valido = valido + json[a].valido;
      }
    }
    return valido;
  };
  const identregasByConectores = (json: IJson[], idconector: number) => {
    var ids = [];
    for (var a = 0; json.length > a; a++) {
      if (idconector === json[a].idconectores) {
        ids.push(json[a].identrega);
      }
    }
    return ids;
  };
  function changeFormat(e: React.MouseEvent<HTMLButtonElement>) {
    const button = e.target as HTMLButtonElement;
    setFormato(button.name);
  }
  useEffect(() => {
    PostEureka('/Imprimir/Lista/', ListaChecks)
      .then((response) => {
        if (response.info) {
          const json = response.content;
          const list_id = obtenerListaIdUnicos(response.content);
          const tableRows: any = list_id.map((id) => {
            const json_ = conteoByConectores(json, id);
            const ids = identregasByConectores(json, id);
            setjson_(json_);
            setids(ids);
          });
          settableRows(tableRows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ListaChecks]);

  return (
    <div className='row'>
      <div className='mt-5 mb-4'>
        {json_ && json_.length === 0 ? (
          <Alert variant='danger'> No hay registros disponibles</Alert>
        ) : downloadError ? (
          downloadErrores?.map((value: any, key) => (
            <div className='alert alert-danger' key={key} role='alert'>
              {value.message}
            </div>
          ))
        ) : (
          <>
            <div className='mb-4 d-flex align-items-center'>
              <div className='btn-group btn-group-sm' role='group' aria-label='Botones para tipos de impresión'>
                <button type='button' className={`btn btn-primary ${formato === 'pdf' ? 'active' : ''}`} name='pdf' onClick={changeFormat}>
                  PDF
                </button>
                <button type='button' className={`btn btn-primary ${formato === 'zebra' ? 'active' : ''}`} name='zebra' onClick={changeFormat}>
                  Zebra
                </button>
              </div>
              <div className='ms-4'>
                <h5>
                  Formato <strong className='text-black text-uppercase'>{formato}</strong>
                </h5>
              </div>
            </div>
            <table className='table table-bordered'>
              <thead>
                <tr className='text-center'>
                  <th> Nro entrega </th>
                  <th> PDF </th>
                  <th> PDF x conector</th>
                </tr>
              </thead>
              <tbody>
                {json_.map((item, index) => (
                  <tr key={index}>
                    <td className='text-center align-middle'>{item.nro_venta}</td>
                    <td>
                      {item.valido === 1 ? (
                        <a onClick={() => descargarEntrega(ListaChecks[index].idtipoconector, item)} target='_blank' className='btn btn-primary btn-sm w-100'>
                          Descargar
                        </a>
                      ) : (
                        <span className='badge text-bg-danger'>No disponible</span>
                      )}
                    </td>
                    {index === 0 && json_[index].tipo === 'MELI' && item.valido > 0 ? (
                      <td rowSpan={json_.length} className='align-middle'>
                        <button onClick={() => imprimir_entregas_by_conector(json_[index].idconectores, JSON.stringify(ids))} className='btn btn-primary w-100 btn-sm'>
                          Descargar
                        </button>
                      </td>
                    ) : (
                      index === 0 && (
                        <td rowSpan={json_.length} className='text-center text-danger align-middle'>
                          <span className='label label-danger'>No disponible</span>
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default FormImprimirEntregas;
