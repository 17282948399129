import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import moment from 'moment';
const { REACT_APP_DIAS_DASHBOARD } = process.env;

var fecha = moment();
const fechaDesde = moment(fecha).subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD');
const fechaHasta = moment(fecha).format('YYYY-MM-DD');

interface IFiltroOrdenes {
  props: {
    SetRefresh: Function;
    OffCanvasFiltrosClose: Function;
    estados: IEstado[];
  };
}

interface IEstado {
  descripcion: string;
  id: string;
  orden: string;
}

interface ISel {
  value: string;
  label: string;
}

interface IUseForm {
  fecha_inicio: string;
  fecha_fin: string;
  idestado: number;
  idremito: number;
  nroremito: string;
  producto: string;
}

const FiltroOrdenes: React.FC<IFiltroOrdenes> = ({ props }) => {
  const [estadoSeleccionado, setestadoSeleccionado] = useState([]);
  const [sel_estado, setsel_estados] = useState<ISel[]>([]);
  const [defaultEstados, setdefaultEstados] = useState<ISel[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { register, getValues, setValue } = useForm<IUseForm>({
    defaultValues: {
      fecha_inicio: fechaDesde,
      fecha_fin: fechaHasta,
      idestado: 0,
      idremito: 0,
      nroremito: '',
      producto: '',
    },
  });
  const { idestado } = getValues();
  const handleEstadoChange = (selectedOption: any) => {
    setValue(
      'idestado',
      selectedOption.map((value: any) => value.value)
    );
    setestadoSeleccionado(selectedOption);
  };
  async function submitFiltros() {
    try {
      localStorage.setItem('filtrosremitos', JSON.stringify(getValues()));
      props.SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
      props.OffCanvasFiltrosClose();
    } catch (error) {
      console.log(error);
    }
  }
  function resetFiltros() {
    setValue('fecha_inicio', moment().subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD'));
    setValue('fecha_fin', moment().format('YYYY-MM-DD'));
    setValue('idestado', 0);
    setValue('nroremito', '');
    setValue('producto', '');
    setValue('idremito', 0);
    setestadoSeleccionado([]);
  }
  useEffect(() => {
    let filtros = localStorage.getItem('filtrosremitos')?.length ? JSON.parse(localStorage.getItem('filtrosremitos') || '{}') : null;
    setsel_estados(props.estados.map((value) => ({ value: value.orden, label: value.descripcion })));
    if (filtros) {
      if (!filtros.hasOwnProperty('idremito')) filtros.idremito = 0;
      if (!filtros.hasOwnProperty('idestado')) filtros.idestado = [];
      if (!filtros.hasOwnProperty('nroremito')) filtros.nroorden = '';
      if (!filtros.hasOwnProperty('producto')) filtros.producto = '';
      if (!filtros.hasOwnProperty('fecha_inicio')) filtros.fecha_inicio = fechaDesde;
      if (!filtros.hasOwnProperty('fecha_fin')) filtros.fecha_fin = fechaHasta;
      setValue('idestado', filtros.idestado);
      setValue('idremito', filtros.idremito);
      setValue('nroremito', filtros.nroremito);
      setValue('producto', filtros.producto);
      setValue('fecha_inicio', filtros.fecha_inicio);
      setValue('fecha_fin', filtros.fecha_fin);
      let estadosArray: any = [];
      if (typeof filtros.idestado === 'object')
        estadosArray = props.estados
          .filter((value) => {
            if (filtros?.idestado?.includes(value.orden)) return value;
          })
          .map((value) => ({ value: value.orden, label: value.descripcion }));
      setdefaultEstados(estadosArray);
      if (estadosArray?.length) setestadoSeleccionado(estadosArray);
    }
    setDataLoaded(true);
  }, []);
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_inicio'>Fecha desde</Form.Label>
            <Form.Control type='date' id='fecha_inicio' {...register('fecha_inicio')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_fin'>Fecha hasta</Form.Label>
            <Form.Control type='date' id='fecha_fin' {...register('fecha_fin')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idestado'>Estado</Form.Label>
            {dataLoaded && <Select isMulti id='idestado' defaultValue={defaultEstados} value={estadoSeleccionado} {...register('idestado')} onChange={handleEstadoChange} options={sel_estado} />}
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='nroremito'>Nro remito</Form.Label>
            <Form.Control type='text' id='nroremito' placeholder='Igual a ...' {...register('nroremito')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='producto'>Producto</Form.Label>
            <Form.Control type='text' id='producto' placeholder='Contiene ...' {...register('producto')} />
          </div>
          <div className='form-group mt-4'>
            <button onClick={resetFiltros} className='btn btn-warning w-50 btn-sm'>
              Resetear
            </button>
            <button onClick={submitFiltros} className='btn btn-primary w-50 btn-sm'>
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltroOrdenes;
