import { useCallback, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment-timezone';

const { REACT_APP_API_URL, REACT_APP_URL_BLUEHOST } = process.env;
const $ = require('jquery');
$.DataTable = require('datatables.net');
require('datatables.net-responsive');
require('datatables.net-colreorder/js/dataTables.colReorder');
require('datatables.net-bs5/js/dataTables.bootstrap5');
require('datatables.net-buttons-dt/js/buttons.dataTables.mjs');
require('datatables.net-buttons/js/buttons.print.min');
require('datatables.net-buttons/js/buttons.html5.min');

interface IFiltro {
  id?: number;
  idarticulo?: number;
}

const Grid = (manejo_acciones: Function, origen: string, gridCarga: any, recargaGrid: Function, filtro?: IFiltro): JSX.Element => {
  const groupColumn = 2;
  const getButtonOpciones = (data: number, origen: string, full: any) => {
    let newData = origen === 'remitos' || origen === 'log_cotizacion' ? JSON.stringify(full) : data;
    var btn = '';
    var btnBaja = "<li><a key='" + newData + "' accion='b' class='dropdown-item'>Eliminar</a></li>";
    var btnConsulta = "<li><a key='" + data + "' accion='c' class='dropdown-item'>Consultar</a></li>";
    var btnModificar = "<li><a key='" + data + "' accion='m' class='dropdown-item'>Modificar</a></li>";
    var btnSeguimiento = "<li><a target='_blank' href='" + REACT_APP_URL_BLUEHOST + 'seguimiento/' + full.nro_venta + "' key='" + data + "' accion='s' class='dropdown-item'>Seguimiento</a></li>";
    var btnTracking = "<li><a key='" + data + "' accion='t' class='dropdown-item'>Tracking</a></li>";
    var btnInventario = "<li><a key='" + data + "' accion='i' class='dropdown-item'>Ver inventario</a></li>";
    switch (origen) {
      case 'articulos':
        btn = btnConsulta + btnModificar + btnBaja + btnInventario;
        break;
      case 'remitos':
        btn = btnConsulta + btnModificar + btnBaja;
        break;
      case 'entregas':
        btn = full.idestado === 2 ? btnConsulta + btnSeguimiento + btnTracking : btnConsulta + btnSeguimiento;
        break;
      case 'deposito':
        btn = btnConsulta + btnModificar + btnBaja;
        break;
      case 'sucursales':
        btn = btnConsulta + btnModificar + btnBaja;
        break;
      case 'sku':
        btn = btnConsulta + btnBaja;
        break;
      case 'log_cotizacion':
        btn = btnConsulta;
        break;
      case 'conectores':
        btn = btnConsulta + btnBaja; 
        break; 
      default:
        btn = '';
    }
    return "<div class='dropdown pb-1 pt-1 ps-3 pe-3'>" + "<button title='" + data + "' class='btn btn-default p-0 btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>" + "<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-gear' viewBox='0 0 16 16'>" + "<path d='M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z'/>" + "<path d='M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z'/>" + '</svg>' + '</button>' + "<ul class='dropdown-menu'>" + btn + '</ul>' + '</div>';
  };
  const getColumnsByOrigen = (origen: string) => {
    var columns: any = [];
    switch (origen) {
      case 'articulos': {
        columns = [
          { data: 'idarticulo', title: '', searcheable: false, width: '1%', class: 'text-center' },
          { data: 'articulo', title: 'Articulo' },
          { data: 'sku', title: 'Sku' },
          { data: 'proveedor', title: 'Proveedor' },
          { data: 'idcliente', title: 'Pertenencia' },
          { data: 'precio_venta', title: 'Pr. Venta' },
          { data: 'stock_reservado', title: 'Reservado', width: '5%' },
          { data: 'stock_disponible', title: 'Disponible', width: '5%' },
          { data: 'stock_actual', title: 'Actual', width: '5%' },
        ];
        break;
      }
      case 'remitos':
        columns = [
          { data: 'idremito', title: '', searcheable: false, width: '1%' },
          { data: 'sucursal', title: 'Sucursal' },
          { data: 'nroremito', title: 'Nro remito' },
          { data: 'estado', title: 'Estado', class: 'text-center' },
          { data: 'fecha_alta', title: 'Fecha de alta' },
          { data: 'mod_manual', title: 'Modificado', class: 'text-center' },
        ];
        break;
      case 'entregas':
        columns = [
          { data: 'identrega', title: '', searcheable: false, width: '1%' },
          { data: 'nro_venta', title: 'Número de venta' },
          { data: 'direccion', title: 'Direccion' },
          { data: 'estado', title: 'Estado' },
          { data: 'total', title: 'Total' },
          { data: 'fecha_alta', title: 'Fecha de alta' },
        ];
        break;
      case 'stock':
        columns = [
          { data: 'idtipo', title: '', searcheable: false, visible: false, width: '1%' },
          { data: 'tipo', title: 'Tipo' },
          { data: 'cantidad', title: 'Cant.' },
          { data: 'fecha_alta', title: 'Fecha' },
          { data: 'detalle', title: 'Detalle' },
        ];
        break;
      case 'deposito':
        columns = [
          { data: 'iddeposito', title: '', searcheable: false, width: '1%' },
          { data: 'principal', title: 'Principal', width: '7%', class: 'text-center' },
          { data: 'nombre', title: 'Nombre' },
          { data: 'estructura', title: 'Estructura' },
          { data: 'ubicacion', title: 'Ubicacion' },
          { data: 'fecha_alta', title: 'F.Alta', class: 'text-center' },
        ];
        break;
      case 'sucursales':
        columns = [
          { data: 'idsucursal', title: '', searcheable: false, width: '1%' },
          { data: 'nombre', title: 'NOMBRE' },
          { data: 'apellido', title: 'APELLIDO' },
          { data: 'email', title: 'EMAIL' },
          { data: 'telefono', title: 'TELEFONO' },
          { data: 'direccion', title: 'DIRECCION' },
          { data: 'fecha_alta', title: 'F.ALTA' },
        ];
        break;
      case 'sku':
        columns = [
          { data: 'Id', title: '', searcheable: false, width: '1%' },
          { data: 'producto', title: 'Producto' },
          { data: 'sku', title: 'SKU' },
        ];
        break;
      case 'log_cotizacion':
        columns = [
          { data: 'idlog', title: '', searcheable: false, width: '1%' },
          { data: 'nro_venta', title: 'Numero de venta', width: '15%' },
          { data: 'idproveedor', title: 'Nro. Envío', width: '15%' },
          { data: 'idproveedor', title: 'Proveedores Cotización', width: '15%' },
          { data: 'proveedoresenvio', title: 'Proveedores Envío', class: 'text-center' },
          { data: 'descripcion', title: 'Estado', class: 'text-center' },
          { data: 'origen', title: 'Origen', width: '15%' },
          { data: 'fecha_alta', title: 'Fecha de alta', class: 'text-center', width: '15%' },
        ];
        break;
      case 'conectores':
        columns = [
          { data: 'Id', title: '', searcheable: false, width: '1%' },
          { data: 'codvendedor', title: 'Cod. Conector', width: '15%' },
          { data: 'vendedor', title: 'Nombre Conector', width: '15%' },
          { data: 'tipo', title: 'Tipo', width: '15%' },
          { data: 'estado', title: 'Estado', width: '15%' },
        ];
        break;
      default:
        columns = [];
    }
    return columns;
  };

  function getNrosVenta(nro_venta: string) {
    if (nro_venta.includes('[')) {
      return JSON.parse(nro_venta).reduce((acc: any, next: any) => {
        acc += `<span class='mt-1 d-block border-bottom'>${next}</span>`;
        return acc;
      }, '');
    } else return `<span class='mt-1 border-bottom'>${nro_venta}</span>`;
  }
  const getRenderColumns = (origen: string) => {
    switch (origen) {
      case 'log_cotizacion': {
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          },
          {
            targets: [1],
            render: function (data: any, type: any, full: any) {
              return getNrosVenta(data);
            },
          },
          {
            targets: [2],
            class: 'text-center',
            render: function (data: any, type: any, full: any) {
              const datos = typeof data === 'string' ? JSON.parse(data) : data;
              let nro_envio = '';
              if (Array.isArray(datos)) {
                for (var i = 0; i < datos.length; i++) {
                  if ('nro_envio' in datos[i] && datos[i].nro_envio !== null) {
                    nro_envio += "<span class='badge text-bg-secondary w-100 fs-6' style='margin-bottom: 2px; display: inline-block;' title='" + datos[i].proveedor + "'>" + datos[i].nro_envio + '</span> ';
                  }
                }
                if (nro_envio !== '') {
                  return nro_envio.trim();
                }
                return ' - ';
              }
              return "<span class='label label-danger'>Sin Envio</span>";
            },
          },
          {
            target: [6],
            render: function (data: any, type: any, full: any) {
              return `<span class='badge text-bg-primary w-100 fs-6'>${data}</span>`;
            },
          },
          {
            targets: [3],
            render: function (data: any, type: any, full: any) {
              let datas = (data = typeof data === 'string' ? JSON.parse(data) : data);
              let result = '';
              let color = '';
              let tooltip = '';
              let proveedoresUnicos = new Set();
              datas?.forEach(function (item: any) {
                let proveedor = item.proveedor.split(' ')[1];
                if (!proveedoresUnicos.has(proveedor)) {
                  proveedoresUnicos.add(proveedor);
                  if (item.estado.value === 2 || item.estado.value === 4) {
                    color = 'text-bg-danger';
                    tooltip = item.estado.descripcion;
                  } else if (item.estado.value === 1 || item.estado.value === 3) {
                    color = 'text-bg-primary';
                    tooltip = item.estado.descripcion;
                  }
                  result += "<span class='badge " + color + " w-100 fs-6' style='margin-bottom: 2px; display: inline-block;' title='" + tooltip + "'>" + proveedor + '</span>';
                }
              });
              return result;
            },
          },
          {
            type: 'date',
            targets: [7],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY HH:mm:ss');
            },
          },
          {
            targets: [4],
            render: function (data: any, type: any, full: any) {
              if (data == null) {
                return '<span>Sin proveedores</span>';
              } else {
                var proveedores = typeof data === 'string' ? JSON.parse(data) : data;

                return proveedores
                  .map(function (prov: any) {
                    switch (prov) {
                      case 1:
                        return "<span class='badge text-bg-primary w-100 fs-6'>Pedidos Ya</span>";
                      case 2:
                        return "<span class='badge text-bg-primary w-100 fs-6'>Pide Uva</span>";
                      case 3:
                        return "<span class='badge text-bg-primary w-100 fs-6'>Ups</span>";
                      case 4:
                        return "<span class='badge text-bg-primary w-100 fs-6'>Oca</span>";
                      case 5:
                        return "<span class='badge text-bg-primary w-100 fs-6'>Envio Pack</span>";
                      case 6:
                        return "<span class='badge text-bg-primary w-100 fs-6'>Urbano</span>";
                      default:
                        return '<span>Sin Proveedores</span>';
                    }
                  })
                  .join(' ');
              }
            },
          },
          {
            targets: [5],
            render: function (data: any, type: any, full: any) {
              const dataFull = typeof full.idproveedor === 'string' ? JSON.parse(full.idproveedor) : full.idproveedor;
              let tooltips: any = [];

              dataFull.forEach(function (item: any) {
                if (item.estado.value === 2 || item.estado.value === 4) {
                  tooltips.push(item.nro_venta + ' - ' + item.proveedor.split(' ')[1]);
                }
              });

              let tooltip = tooltips.join(', ');

              if (data === 'Error cotizacion') {
                return "<span class='badge text-bg-danger w-100 fs-6'>" + data + '</span>';
              }
              if (data === 'Error solicitud envio') {
                return "<span class='badge text-bg-danger w-100 fs-6'>" + data + '</span>';
              }
              if (data === 'Cotizado') {
                return "<span class='badge text-bg-success w-100 fs-6'>" + data + '</span>';
              }
              if (data === 'Envio solicitado') {
                return "<span class='badge text-bg-success w-100 fs-6'>" + data + '</span>';
              }
              if (data === 'Error Cotizacion Multiple') {
                return "<span class='badge text-bg-warning w-100 fs-6' title='" + tooltip + "'>" + data + '</span>';
              }
              if (data === 'Error Envio Multiple') {
                return "<span class='badge text-bg-warning w-100 fs-6' title='" + tooltip + "'>" + data + '</span>';
              }
            },
          },
        ];
      }
      case 'articulos':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          },
          {
            targets: [4],
            render: function (data: any, type: any, full: any) {
              return full['idcliente'] === 1 ? "<span class='badge text-bg-success w-100 fs-6'>Flexit</span>" : "<span class='badge text-bg-primary w-100 fs-6'>Cliente</span>";
            },
          },
        ];
      case 'stock':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return '';
            },
          },
          {
            targets: [1],
            render: function (data: any, type: any, full: any) {
              if (full['idtipo'] === 1) {
                return "<span style='color:green' title='" + full['tipo'] + "' class='material-symbols-outlined'>arrow_upward</span>";
              } else {
                return "<span style='color:red' title='" + full['tipo'] + "' class='material-symbols-outlined'>arrow_downward</span>";
              }
            },
          },
          {
            type: 'date',
            targets: [3],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'sucursales':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          },
          {
            type: 'date',
            targets: [6],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'deposito':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          },
          {
            targets: [1],
            render: function (data: any, type: any, full: any) {
              return full.principal === 1 ? "<span class='badge text-bg-success'>Principal</span>" : "<span class='badge text-bg-danger'>No principal</span>";
            },
          },
          {
            type: 'date',
            targets: [5],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'remitos':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          },
          {
            type: 'date',
            targets: [4],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
          {
            targets: [5],
            render: function (data: any, type: any, full: any) {
              return full.mod_manual.length ? `<span class='badge text-bg-secondary'>${full.mod_manual}</span>` : '';
            },
          },
        ];
      case 'entregas':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          },
          {
            type: 'date',
            targets: [5],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'conectores':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          }
        ];
      default:
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, origen, full);
            },
          },
        ];
    }
  };
  const getUrlOrigen = (origen: string) => {
    var token = localStorage.getItem('token');
    var url = '';
    switch (origen) {
      case 'articulos':
        url = REACT_APP_API_URL + '/Articulos?token=' + token;
        break;
      case 'remitos':
        url = REACT_APP_API_URL + '/Remitos?token=' + token;
        break;
      case 'entregas':
        url = REACT_APP_API_URL + '/Entregas?token=' + token;
        break;
      case 'deposito':
        url = REACT_APP_API_URL + '/depositos?token=' + token;
        break;
      case 'sucursales':
        url = REACT_APP_API_URL + '/Sucursales?token=' + token;
        break;
      case 'stock':
        url = REACT_APP_API_URL + '/Stock/' + filtro?.id + '?token=' + token;
        break;
      case 'sku':
        url = REACT_APP_API_URL + '/articulos/getProductosSku?token=' + token;
        break;
      case 'log_cotizacion':
        url = REACT_APP_API_URL + '/conector-log/get_logs?token=' + token;
        break;
      case 'conectores':
        url = REACT_APP_API_URL + '/conector/api/get_conectores?token=' + token;
        break;  
      default:
        url = '';
        break;
    }
    return url;
  };
  const getMetodoOrigen = (origen: string) => {
    var metodo = '';
    switch (origen) {
      case 'articulos':
        metodo = 'POST';
        break;
      case 'remitos':
        metodo = 'POST';
        break;
      case 'entregas':
        metodo = 'POST';
        break;
      case 'deposito':
        metodo = 'GET';
        break;
      case 'sucursales':
        metodo = 'GET';
        break;
      case 'sku':
        metodo = 'POST';
        break;
      case 'log_cotizacion':
        metodo = 'POST';
        break;
      case 'conectores':
        metodo = 'GET';
        break  
      default:
        metodo = 'GET';
    }
    return metodo;
  };
  const getDataOrigen = (origen: string) => {
    let filtros = localStorage.getItem(`filtros${origen}`)?.length ? JSON.parse(localStorage.getItem(`filtros${origen}`) || '{}') : null;
    var data: any = {};
    switch (origen) {
      case 'articulos': {
        if (!filtros) {
          data['articulo'] = '';
          data['idarticulo'] = 0;
          data['idmarca'] = 0;
          data['idmodelo'] = 0;
          data['idproveedor'] = 0;
          data['idtipo'] = 0;
          data['idfiltroproducto'] = 0;
        } else {
          data['articulo'] = filtros.articulo;
          data['idarticulo'] = filtros.idarticulo;
          data['idmarca'] = filtros.idmarca;
          data['idmodelo'] = filtros.idmodelo;
          data['idproveedor'] = filtros.idproveedor;
          data['idtipo'] = filtros.idtipo;
          data['idfiltroproducto'] = filtros.idfiltroproducto || 0;
        }
        break;
      }
      case 'remitos': {
        if (!filtros) {
          data['idremito'] = 0;
          data['idcliente'] = 0;
          data['idestado'] = 0;
          data['producto'] = '';
          data['nroremito'] = '';
          data['fecha_inicio'] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
        } else {
          if (!filtros.hasOwnProperty('nroremito')) filtros.nroremito = '';
          if (!filtros.hasOwnProperty('producto')) filtros.producto = '';
          if (!filtros.hasOwnProperty('idremito')) filtros.idremito = 0;
          if (!filtros.hasOwnProperty('idcliente')) filtros.idcliente = 0;
          if (!filtros.hasOwnProperty('idestado')) filtros.idestado = 0;
          if (!filtros.hasOwnProperty('fecha_inicio')) filtros.fecha_inicio = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          if (!filtros.hasOwnProperty('fecha_fin')) filtros.fecha_fin = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
          data['idremito'] = filtros.idremito;
          data['idestado'] = filtros.idestado;
          data['idcliente'] = filtros.idcliente;
          data['producto'] = filtros.producto;
          data['nroremito'] = filtros.nroremito;
          data['fecha_inicio'] = filtros.fecha_inicio;
          data['fecha_fin'] = filtros.fecha_fin;
        }
        break;
      }
      case 'entregas': {
        if (!filtros) {
          data['identrega'] = 0;
          data['idestado'] = 0;
          data['codinterno'] = '';
          data['nroventa'] = '';
          data['producto'] = '';
          data['fecha_inicio'] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
          data['codinterno'] = '';
          data['repartidor'] = 0;
          data['sucursal'] = 0;
          data['cliente'] = 0;
          data['tipo_entrega'] = 0;
          data['impreso'] = 3;
          data['es_turbo'] = 3;
          data['idtipoconector'] = 0;
          data['fecha_despacho_inicio'] = null;
          data['fecha_despacho_fin'] = null;
          data['fecha_entrega_inicio'] = null;
          data['fecha_entrega_fin'] = null;
          data['cancelado'] = 3;
        } else {
          if (!filtros.hasOwnProperty('nroorden')) filtros.nroorden = '';
          if (!filtros.hasOwnProperty('producto')) filtros.producto = '';
          if (!filtros.hasOwnProperty('idestado')) filtros.idestado = 0;
          if (!filtros.hasOwnProperty('idchofer')) filtros.idchofer = 0;
          if (!filtros.hasOwnProperty('idestado')) filtros.idestado = 0;
          if (!filtros.hasOwnProperty('fecha_inicio')) filtros.fecha_desde = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          if (!filtros.hasOwnProperty('fecha_hasta')) filtros.fecha_hasta = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
          if (!filtros.hasOwnProperty('codinterno')) filtros.fecha_hasta = '';
          if (!filtros.hasOwnProperty('repartidor')) filtros.repartidor = 0;
          if (!filtros.hasOwnProperty('sucursal')) filtros.sucursal = 0;
          if (!filtros.hasOwnProperty('cliente')) filtros.cliente = 0;
          if (!filtros.hasOwnProperty('tipo_entrega')) filtros.tipo_entrega = 0;
          if (!filtros.hasOwnProperty('impreso')) filtros.impreso = 0;
          if (!filtros.hasOwnProperty('es_turbo')) filtros.es_turbo = 0;
          if (!filtros.hasOwnProperty('idtipoconector')) filtros.idtipoconector = 0;
          if (!filtros.hasOwnProperty('fecha_despacho_inicio')) filtros.fecha_despacho_inicio = null;
          if (!filtros.hasOwnProperty('fecha_despacho_fin')) filtros.fecha_despacho_fin = null;
          if (!filtros.hasOwnProperty('fecha_entrega_inicio')) filtros.fecha_entrega_inicio = null;
          if (!filtros.hasOwnProperty('fecha_entrega_fin')) filtros.fecha_entrega_fin = null;
          if (!filtros.hasOwnProperty('cancelado')) filtros.cancelado = 3;
          data['identrega'] = filtros.identrega || 0;
          data['idestado'] = filtros.idestado || 0;
          data['codinterno'] = filtros.codinterno || '';
          data['nroventa'] = filtros.nroventa || '';
          data['producto'] = filtros.producto || '';
          data['fecha_inicio'] = filtros.fecha_inicio || moment().subtract(30, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = filtros.fecha_fin || moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
          data['codinterno'] = filtros.codinterno;
          data['repartidor'] = filtros.repartidor;
          data['sucursal'] = filtros.sucursal;
          data['cliente'] = filtros.cliente;
          data['tipo_entrega'] = filtros.tipo_entrega;
          data['impreso'] = filtros.impreso;
          data['es_turbo'] = filtros.es_turbo;
          data['idtipoconector'] = filtros.idtipoconector;
          data['fecha_despacho_inicio'] = filtros.fecha_despacho_inicio;
          data['fecha_despacho_fin'] = filtros.fecha_despacho_fin;
          data['fecha_entrega_inicio'] = filtros.fecha_entrega_inicio;
          data['fecha_entrega_fin'] = filtros.fecha_entrega_fin;
          data['cancelado'] = filtros.cancelado;
        }
        break;
      }
      case 'log_cotizacion': {
        if (!filtros) {
          data['idlog'] = 0;
          data['pais'] = 0;
          data['idcliente'] = [];
          data['idproveedor'] = [];
          data['estado'] = [];
          data['origen'] = 0;
          data['nro_venta'] = '';
          data['fecha_inicio'] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
        } else {
          data['idlog'] = 0;
          data['pais'] = filtros.pais;
          data['idcliente'] = filtros.idcliente;
          data['idproveedor'] = filtros.idproveedor;
          data['estado'] = filtros.estado;
          data['origen'] = filtros.origen;
          data['nro_venta'] = filtros.nro_venta;
          data['fecha_inicio'] = filtros.fecha_inicio;
          data['fecha_fin'] = filtros.fecha_fin;
        }
        break;
      }
      case 'sku': {
        data['idarticulo'] = filtro?.idarticulo;
        break;
      }
      default:
        data = [];
    }
    return JSON.stringify(data);
  };
  const getTipoTable = (origen: string) => {
    switch (origen) {
      default:
        return 'table-bordered tabla-generica';
    }
  };
  const orderfixedtogroup_grid = (tipo: string, groupColumn: any) => {
    switch (tipo) {
      default:
        return '';
    }
  };
  const getColumnasExportar = (origen: string) => {
    switch (origen) {
      case 'articulos':
        return [1, 2, 3, 4, 5, 6, 7];
      case 'deposito':
        return [1, 2, 3, 4];
      case 'sucursales':
        return [1, 2, 3, 4, 5, 6];
      case 'stock':
        return [1, 2, 3, 4];
      case 'sku':
        return [0, 1, 2];
      case 'remitos':
        return [1, 2, 3, 4, 5];
      case 'entregas':
        return [1, 2, 3, 4, 5];
      case 'log_cotizacion':
        return [1, 2, 3, 4, 5];
      case 'conectores':
        return [1, 2, 3, 4];  
      default:
        return [];
    }
  };
  const getExportOrientation = (origen: string) => {
    switch (origen) {
      default:
        return 'portrait';
    }
  };
  const ManejoOrigenAcciones = useCallback((origen: string, e: any) => {
    switch (origen) {
      default:
        return manejo_acciones(origen, e?.target?.attributes?.key?.value, e?.target?.attributes?.accion?.value, e);
    }
  }, []);
  function getButtonsGrids(origen: string) {
    switch (origen) {
      case 'stock':
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
        ];
      case 'deposito':
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
        ];
      case 'sucursales':
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
        ];
      case 'inventario':
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
        ];
      case 'conectores':
        return [
            {
              extend: 'collection',
              text: 'Exportar',
              buttons: [
                { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
                { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
                { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
                { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              ],
            },
            {
              text: 'Refresh',
              action: function (e: any, dt: any, node: any, config: any) {
                recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
              },
            }
        ]
      default:
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
          {
            text: "<button accion='f' class='a-filtro'>Filtros</button>",
            action: function (e: any, dt: any, node: any, config: any) {
              ManejoOrigenAcciones('', e);
            },
          },
        ];
    }
  }
  function getDomOrigen(origen: string) {
    switch (origen) {
      case 'stock':
        return '<"row"<"col-12"<"row"<"col-12 div-btn-grid"B>>><"col-12 d-flex justify-content-start mb-2"<"row"<"col-12 div-filter-table"f>>>><"row"<"col-12 content-grid-referencia">><"row"<"col-12"t>><"row grid-footer mt-2"<"col-12 col-lg-4"l><"col-12 col-lg-8"<"row"<"col-12 col-lg-4 col-xl-4"i><"col-12 col-lg-8 col-xl-8"p>>>>';
      default:
        return '<"row"<"col-12 col-sm-7 col-lg-8"<"row"<"col-12 div-btn-grid"B>>><"col-12 d-block d-sm-flex justify-content-end col-sm-5 col-lg-4 mb-2"<"row"<"col-12 div-filter-table"f>>>><"row"<"col-12 content-grid-referencia">><"row"<"col-12"t>><"row grid-footer mt-2"<"d-md-flex justify-content-between align-items-center dt-layout-end col-md-auto ms-auto"l><"d-md-flex justify-content-between align-items-center dt-layout-end col-md-auto ms-auto text-center"i><"d-md-flex justify-content-between align-items-center dt-layout-end col-md-auto ms-auto"p>>>';
    }
  }
  const tableOp: React.RefObject<HTMLTableElement> = useRef(null);
  useEffect(() => {
    $(tableOp.current).DataTable({
      colReorder: true,
      destroy: true,
      responsive: true,
      processing: true,
      ajax: {
        contentType: 'application/json',
        type: getMetodoOrigen(origen),
        url: getUrlOrigen(origen),
        dataSrc: 'content',
        data: function () {
          return getDataOrigen(origen);
        },
      },
      dom: getDomOrigen(origen),
      buttons: getButtonsGrids(origen),
      drawCallback: function (settings: any) {
        if ($('.tabla-agrupada').length > 0) {
          var api = this.api();
          var rows = api.rows({ page: 'current' }).nodes();
          var last: any = null;
          api
            .column(groupColumn, { page: 'current' })
            .data()
            .each(function (group: any, i: any) {
              if (last !== group) {
                $(rows)
                  .eq(i)
                  .before('<tr class="group tr_agrupado"><td class="table-secondary" colspan="11">' + group + '</td></tr>');
                last = group;
              }
            });
        }
        $('.div-btn-grid .btn,.btn-group-sm .btn').addClass('btn-sm');
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        $('#tabla_' + origen + ' .dropdown-item').on('click', function (e: any) {
          ManejoOrigenAcciones(origen, e);
        });
        $('.a-filtro').on('click', function (e: any) {
          ManejoOrigenAcciones(origen, e);
        });
      },
      orderFixed: orderfixedtogroup_grid(origen, groupColumn),
      columns: getColumnsByOrigen(origen),
      displayLength: 10,
      autoWidth: false,
      columnDefs: getRenderColumns(origen),
      language: {
        paginate: {
          previous: '<',
          next: '>',
          last: '>>',
          first: '<<',
        },
        lengthMenu: 'Mostrar <select>' + '<option selected value="10">10</option>' + '<option value="25">25</option>' + '<option value="50">50</option>' + '<option value="75">75</option>' + '<option value="100">100</option>' + '<option value="-1">Todos</option>' + '</select> registros',
        info: 'Mostrando del _START_ a _END_ (Total: _TOTAL_ resultados)',
        infoFiltered: ' - filtrados de _MAX_ registros',
        infoEmpty: 'No hay resultados de b&uacute;squeda',
        zeroRecords: 'No hay registros a mostrar',
        processing: 'Espere, por favor...',
        search: 'Buscar: ',
      },
    });
  }, [origen, gridCarga /*, ManejoOrigenAcciones, getButtonsGrids, getDataOrigen, getRenderColumns, getUrlOrigen */]);
  return <Table responsive='xl' id={'tabla_' + origen} className={getTipoTable(origen)} bordered hover ref={tableOp}></Table>;
};

export default Grid;
