import { useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import Grid from "../estructura/EurekaGrid/EurekaGrid";
import EurekaModals from "../estructura/EurekaModals/EurekaModals";
import FormConectores from "./FormConectores";
import { useNavigate } from "react-router-dom";
import { DeleteEureka, EnviarMensaje } from "../estructura/EurekaGeneral/EurekaGeneral";

const EurekaConectores = () => {
    const navigate = useNavigate();
    const [recargaGridConector, setRecargaGridConector] = useState<string>('');
    const [titulo, setTitulo] = useState<string>('');
    const [modalConector, setmodalConector] = useState({ show: false, id: 0, accion: 'a' });
    const [modalBaja, setmodalBaja] = useState({ show: false, id: 0, accion: 'b' });

    const ModalBajaClose = () => setmodalBaja({ show: false, id: 0, accion: 'b' });
    const ModalBajaShow = (id: number, accion: string) => setmodalBaja({ show: true, id: id, accion: accion });
    const ModalClose = () => setmodalConector({ show: false, id: 0, accion: 'a' });
    const SetRefresh = (date: string) => setRecargaGridConector(date);
    const ModalShow = (id: number, accion: string) => setmodalConector({ show: true, id: id, accion: accion });
    function manejo_acciones(origen: string, registro: number, accion: string) {
        switch (accion) {
            case 'c': {
                setTitulo('Consulta Conector');
                ModalShow(registro, accion);
                break;
            }
            case 'b': {
                setTitulo('Eliminar Conector');
                ModalBajaShow(registro, accion);
                break;
            }
            default:
                break;
        }
    }

    const handleNavigateClick = () => {
        navigate('/dashboard', { state: { tab: 'conectores' } });
    }

    const handleDelete = (id:any) =>{
    DeleteEureka(`/Conector/api/delete_conector/${id}`, []).then((response) => {
        if (response.info) {
            EnviarMensaje('success', 'Registro eliminado con éxito');
            SetRefresh(new Date().toString());
            ModalBajaClose();
        }
    });
    } 
    return (
        <>
            <PageLayout label="Adm. Conectores">
                <button
                    className='btn btn-success btn-xs mb-4 me-1'
                    onClick={handleNavigateClick}
                >
                    {' '}
                    <strong>+</strong> Agregar{' '}
                </button>
                <div>
                    {Grid(manejo_acciones, "conectores", recargaGridConector, SetRefresh)}
                </div>
                <EurekaModals id="modal_conector" manejador={modalConector} modalTitulo={titulo} sizeModal="lg" handleClose={ModalClose}>
                    <FormConectores idConector={modalConector.id} />
                </EurekaModals>
                <EurekaModals id="modal_baja" manejador={modalBaja} modalTitulo={titulo} sizeModal="md" handleClose={ModalBajaClose}>
                    <div className="row mt-2 mb-2 d-flex justify-content-center align-items-center text-center">
                        <div className="row col-12">
                            <div className="alert alert-danger" role="alert">
                                ¿Está seguro que desea eliminar el conector? Esta acción no se puede deshacer.
                            </div>
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    handleDelete(modalBaja.id);
                                }}
                            >
                                Eliminar
                            </button>
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    ModalBajaClose();
                                }}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </EurekaModals>
            </PageLayout>
        </>
    )
}

export default EurekaConectores;