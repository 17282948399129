import { useEffect, useState } from 'react';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
import { Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import EurekaOverlayTrigger from '../estructura/EurekaOverlayTrigger/EurekaOverlayTrigger';
import moment from 'moment';

interface IEurekaFormEntregas {
  id: number;
  SetRefresh: Function;
  ModalClose: Function;
  formdisabled: boolean;
  modalEntregas: IModalEntrega;
}

interface IModalEntrega {
  show: boolean;
  id: number;
  accion: string;
}

interface IUseForm {
  identrega: number;
  codigo: string;
  total: null | number;
  nro_venta: string;
  direccion: string;
  fecha_alta: string;
  observaciones: string;
  codinterno: string;
  fecha_cancelacion: string;
  fecha_impresion: string;
  idestado: number;
  estado: string;
  productos: IProducto[];
  repartidor: number;
  nombre_completo: string;
  fecha_despacho: string;
  fecha_entrega: string;
}

interface IProducto {
  cantidad: number;
  sku: string;
  articulo: string;
}

const EurekaFormEntregas: React.FC<IEurekaFormEntregas> = ({ id, SetRefresh, ModalClose, formdisabled, modalEntregas }) => {
  const [productos, setProductos] = useState<IProducto[]>([]);
  const { setValue, register, getValues, handleSubmit } = useForm({
    defaultValues: {
      identrega: 0,
      codigo: '',
      total: null,
      nro_venta: '',
      direccion: '',
      fecha_alta: '',
      observaciones: '',
      codinterno: '',
      fecha_cancelacion: '',
      fecha_impresion: '',
      idestado: 0,
      estado: '',
      productos: [],
      repartidor: 0,
      nombre_completo: '',
      fecha_despacho: '',
      fecha_entrega: '',
    },
  });
  function closeModal(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    ModalClose();
  }
  useEffect(() => {
    if (modalEntregas.show)
      GetEureka(`/Entregas/${id}`).then((response) => {
        let values = response.content[0];
        setValue('identrega', values.identrega);
        setValue('codigo', values.codigo);
        setValue('total', values.total);
        setValue('nro_venta', values.nro_venta);
        setValue('direccion', values.direccion);
        setValue('fecha_alta', moment(values.fecha_alta).format('DD/MM/YYYY'));
        setValue('observaciones', values.observaciones);
        setValue('codinterno', values.codinterno);
        setValue('idestado', values.idestado);
        setValue('estado', values.estado);
        setValue('productos', values.productos);
        setValue('nombre_completo', values.nombres ? `${values.nombres} ${values.apellidos}` : '');
        setValue('fecha_despacho', moment.utc(values.fecha_despacho).format('DD/MM/YYYY'));
        setValue('fecha_entrega', moment.utc(values.fecha_entrega).format('DD/MM/YYYY'));
        if (values.fecha_cancelacion) setValue('fecha_cancelacion', moment.utc(values.fecha_cancelacion).format('DD/MM/YYYY'));
        if (values.fecha_impresion) setValue('fecha_impresion', moment.utc(values.fecha_impresion).format('DD/MM/YYYY'));
        setProductos(values.productos);
      });
  }, []);
  return (
    <>
      <Form className='row mt-2 mb-2'>
        <div className='row col-12'>
          <div className='col-lg-3 col-md-6 col-12'>
            <Form.Label>Fecha despacho</Form.Label>
            <span className='ms-2 badge bg-secondary'>{getValues().fecha_despacho}</span>
          </div>
          <div className='col-lg-3 col-md-6 col-12'>
            <Form.Label>Fecha entrega</Form.Label>
            <span className='ms-2 badge bg-secondary'>{getValues().fecha_entrega}</span>
          </div>
          {getValues().fecha_impresion?.length && (
            <div className='col-lg-3 col-md-6 col-12'>
              <Form.Label>Fecha impresión</Form.Label>
              <span className='ms-2 badge bg-secondary'>{getValues().fecha_impresion}</span>
            </div>
          )}
          {getValues().fecha_cancelacion?.length && (
            <div className='col-lg-3 col-md-6 col-12'>
              <Form.Label>Fecha cancelación</Form.Label>
              <span className='ms-2 badge bg-secondary'>{getValues().fecha_cancelacion}</span>
            </div>
          )}
        </div>
        <div className='row col-12 mt-0'>
          <div className='col-4'>
            <Form.Label htmlFor='nro_venta' className='col-3'>
              nro_venta
            </Form.Label>
            <Form.Control type='text' readOnly {...register('nro_venta')} />
          </div>
          <div className='col-4'>
            <Form.Label htmlFor='codinterno' className='col-3'>
              Cod interno
            </Form.Label>
            <Form.Control type='text' readOnly {...register('codinterno')} />
          </div>
          <div className='col-4'>
            <Form.Label htmlFor='fecha_alta' className='col-3'>
              Fecha de alta
            </Form.Label>
            <Form.Control type='text' readOnly {...register('fecha_alta')} />
          </div>
          <div className='col-6'>
            <Form.Label htmlFor='direccion' className='col-3'>
              Direccion
            </Form.Label>
            <Form.Control type='text' readOnly {...register('direccion')} />
          </div>
          <div className='col-6'>
            <Form.Label htmlFor='estado' className='col-3'>
              Estado
            </Form.Label>
            <Form.Control type='text' readOnly {...register('estado')} />
          </div>
        </div>
        <div className='col-12 mt-0'>
          <div className='row mt-0'>
            {getValues().nombre_completo ? (
              <div className='row mt-0'>
                <div className='col-12'>
                  <Form.Label htmlFor='nombre_completo' className='col-3'>
                    Repartidor
                  </Form.Label>
                  <Form.Control type='text' readOnly {...register('nombre_completo')} />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='col-12'>
              <Form.Label htmlFor='observaciones' className='col-3'>
                Observaciones
              </Form.Label>
              <Form.Control disabled={formdisabled} type='text' as='textarea' {...register('observaciones')} />
            </div>
          </div>
          <div>
            <div className='col-12 mt-2'>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className='text-center'>Cant.</th>
                    <th className='text-center'>SKU</th>
                    <th className='text-center'>Producto</th>
                  </tr>
                </thead>
                <tbody>
                  {productos.length ? (
                    productos?.map((value, key) => (
                      <tr key={key}>
                        <td className='text-center align-middle'>{value.cantidad}</td>
                        <td className='text-center align-middle'>
                          <EurekaOverlayTrigger title={value.sku.split(',')[0]} variant='light'>
                            <ul>
                              {value.sku
                                .split(',')
                                .slice(0, value.sku.split(',').length)
                                .map((value, key) => (
                                  <li key={key}>{value}</li>
                                ))}
                            </ul>
                          </EurekaOverlayTrigger>
                        </td>
                        <td className='text-center align-middle'>{value.articulo}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className='text-center'>
                      <td colSpan={3} className='text-uppercase fs-3'>
                        Sin productos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <button className='w-100 mc-btn primary sm' onClick={closeModal}>
          Cerrar
        </button>
      </Form>
    </>
  );
};

export default EurekaFormEntregas;
