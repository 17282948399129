import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faTimeline, faQrcode, faFire } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import EurekaModals from '../../EurekaModals/EurekaModals';
import { tituloModalTableroEntregas, FormTrackingEntregas, FormQrEntregas } from './Genericos/FormGenericos';
import { Badge } from 'react-bootstrap';
import moment from 'moment-timezone';
import EurekaOverlayTrigger from '../../EurekaOverlayTrigger/EurekaOverlayTrigger';
const { REACT_APP_URL_BLUEHOST } = process.env;

interface IEurekaTableroFinalizadas {
  finalizados: { sucursal: string; fecha_entrega?: string; fecha_despacho?: string; nro_venta: string; identrega: number; codinterno: number; fecha: string; fecha_cancelacion: string; esturbo: number; tipoconector: string; productos: string; direccion: string; recibe: string; telefono: string; comentario: string; observaciones: string }[];
  finalizadosTotal: number[];
  underline: React.MouseEventHandler<HTMLLabelElement>;
  noUnderline: React.MouseEventHandler<HTMLLabelElement>;
  SetRefresh: Function;
  direccion: boolean;
  vermas: { observados: boolean; pendientes: boolean; finalizados: boolean };
  setVermas: Function;
}

const EurekaTableroFinalizadas: React.FC<IEurekaTableroFinalizadas> = ({ finalizados = [], finalizadosTotal, underline, noUnderline, direccion, vermas, setVermas }) => {
  const [entregaActivo, setEntregaActivo] = useState({ id: 0, nroventa: '' });
  const [modalFinalizado, setmodalFinalizado] = useState({ show: false, tipo: 0, accion: '' });
  const ModalClose = () => setmodalFinalizado({ show: false, tipo: 0, accion: '' });
  const ModalShow = (tipo: number, accion: string) => setmodalFinalizado({ show: true, tipo: tipo, accion: accion });
  const FnSetEntregaActivo = (id: number, nroventa: string) => setEntregaActivo({ id: id, nroventa: nroventa });
  function btn_tracking_entrega(identrega: number) {
    FnSetEntregaActivo(identrega, '0');
    ModalShow(2, 'tk');
  }
  function btn_qr_entrega(identrega: number) {
    FnSetEntregaActivo(identrega, '0');
    ModalShow(3, 'qr');
  }
  return (
    <>
      <EurekaModals manejador={modalFinalizado} modalTitulo={tituloModalTableroEntregas(modalFinalizado.tipo)} handleClose={ModalClose}>
        {modalFinalizado.tipo === 2 ? (
          <FormTrackingEntregas identrega={entregaActivo.id} />
        ) : modalFinalizado.tipo === 3 ? (
          <FormQrEntregas identrega={entregaActivo.id} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay entregas seleccionadas
          </div>
        )}
      </EurekaModals>
      <div className={direccion ? 'col-12 col-lg-4' : 'col-md-12'}>
        <div className='card col_entregas_finalizadas'>
          <div className='card-header eureka-bg-success'>
            <div className='row'>
              <div className='col-6 text-success'>
                Finalizados <Badge bg='success'>{finalizadosTotal.length}</Badge>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direccion ? '' : 'row'}`} id='accordionFinalizados'>
              {finalizados.map((finalizado, key) => (
                <div className={`accordion-item ${direccion ? 'col-md-12' : 'col-md-4'}`} key={key}>
                  <div className='accordion-header eureka-bg-success accordion-finalizados'>
                    <nav className='navbar bg-body-tertiary'>
                      <div className='container-fluid'>
                        <label className='text-success form-check-label' data-bs-toggle='collapse' data-bs-target={'#collapse_fin_' + key} aria-expanded='false' aria-controls={'collapse_fin_' + key} onMouseEnter={underline} onMouseLeave={noUnderline}>
                          #{finalizado.codinterno} - {finalizado.fecha}
                          {finalizado.esturbo === 1 ? (
                            <EurekaOverlayTrigger isButton={false} title={<FontAwesomeIcon icon={faFire} />}>
                              {'Es Turbo'}
                            </EurekaOverlayTrigger>
                          ) : (
                            ''
                          )}
                        </label>
                        <div className='dropdow' key={key} id={`menu-obs-${key}`}>
                          <button className='btn btn-success btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                            {finalizado.tipoconector}
                          </button>
                          <ul className='dropdown-menu dropdown-menu-end'>
                            <li>
                              <a onClick={(e) => btn_qr_entrega(finalizado.identrega)} className='dropdown-item pointer'>
                                <FontAwesomeIcon icon={faQrcode} /> QR
                              </a>
                            </li>
                            <li>
                              <a onClick={(e) => btn_tracking_entrega(finalizado.identrega)} className='dropdown-item'>
                                <FontAwesomeIcon icon={faTimeline} /> Tracking
                              </a>
                            </li>
                            <li>
                              <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + finalizado.nro_venta} className='dropdown-item' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={faLocationArrow} /> Seguimiento
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div id={'collapse_fin_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionFinalizados'>
                    <div className='text-dark accordion-body'>
                      <small>{finalizado.productos}</small>
                      <hr className='border border-dark margin-hr'></hr>
                      <small>
                        Nro Venta:{' '}
                        <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + finalizado.nro_venta} className='btn btn-link' target='_blank' rel='noreferrer'>
                          <small>{finalizado.nro_venta}</small>
                        </a>
                      </small>
                      <br />
                      <small>Sucursal: {finalizado.sucursal}</small>
                      <br />
                      <small>Dirección: {finalizado.direccion}</small>
                      <br />
                      <small>
                        Recibe: {finalizado.recibe} | Telefono: {finalizado.telefono}
                      </small>
                      <br />
                      <div className='row p-0 m-0'>
                        <small className='col-md-6 m-0 p-0'>Fecha despacho: {finalizado.fecha_despacho ? <span className='badge bg-secondary'>{moment.utc(finalizado.fecha_despacho).format('DD/MM/YYYY')}</span> : null}</small>
                        <small className='col-md-6 m-0 p-0'>Fecha entrega: {finalizado.fecha_entrega ? <span className='badge bg-secondary'>{moment.utc(finalizado.fecha_entrega).format('DD/MM/YYYY')}</span> : null}</small>
                      </div>
                      <br />
                      <small>Comentario: {finalizado.comentario}</small>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
              <div className='d-flex justify-content-center align-items-center pt-2'>
                <button hidden={finalizados.length < 12} className='btn btn-sm btn-success' onClick={() => setVermas({ ...vermas, finalizados: !vermas.finalizados })}>
                  {finalizados.length <= 12 ? 'Ver mas' : 'Ver menos'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EurekaTableroFinalizadas;
