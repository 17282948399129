import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faX } from '@fortawesome/free-solid-svg-icons';
import { Form, Table, Button, Badge } from 'react-bootstrap';
import { FlexitContext } from '../../../../context/Flexit.tsx';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import { EnviarMensaje, GetEureka, PostEureka, PutEureka } from '../../../estructura/EurekaGeneral/EurekaGeneral.ts';
import EurekaOverlayTrigger from '../../../estructura/EurekaOverlayTrigger/EurekaOverlayTrigger.tsx';
import EurekaModals from '../../../estructura/EurekaModals/EurekaModals.tsx';
import FormModificarProductoRemito from './FormModificarProductoRemito.tsx';
import useGetComplexObject from '../../../../hooks/useGetComplexObject.ts';

interface IFormFormulariosOrdenes {
  estadoOrdenes: { show: boolean; id: number; accion: string };
  formDisabled: boolean;
  handleClose: Function;
  SetRefresh: Function;
  ListaChecks?: { idremito: number }[];
}

interface IUseForm {
  nroremito: string;
  observaciones: string;
  fecha: string;
  productos: IProductosLocal[];
  fecha_confirmacion: string | null;
  fecha_alta: string | null;
  idremito: number;
  estado: string;
  idestado: number;
  sucursal: string;
  total: number;
  mod_manual: string;
}

interface IProductosLocal {
  cantidad: number;
  sku: string;
  producto: string;
  id: number;
}

interface IProductoById {
  cantidad: number;
  sku: string;
  producto: string;
  key: number;
}
interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

const FormFormularioOrdenes: React.FC<IFormFormulariosOrdenes> = ({ estadoOrdenes, formDisabled, handleClose, SetRefresh, ListaChecks = [] }) => {
  const { datos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [productoById, setproductoById] = useState<IProductoById>({ cantidad: 0, sku: '', producto: '', key: 0 });
  const [modalModificar, setmodalModificar] = useState({
    show: false,
    tipo: 0,
    accion: 'm',
    id: 0,
  });
  const ModalClose = () => setmodalModificar({ show: false, tipo: 0, accion: 'm', id: 0 });
  const ModalShow = (tipo: number, accion: string) => setmodalModificar({ show: true, tipo: tipo, accion: accion, id: 0 });
  const [producto, setProducto] = useState({
    cantidad: 0,
    sku: '',
    producto: '',
    id: 0,
  });
  const [productosLocal, setProductosLocal] = useState<IProductosLocal[]>([]);

  const { register, handleSubmit, setValue, getValues } = useForm<IUseForm>({
    defaultValues: {
      nroremito: '',
      observaciones: '',
      fecha: moment().format('YYYY-MM-DD'),
      productos: [],
      fecha_confirmacion: null,
      fecha_alta: null,
    },
  });

  function escribiendoProducto(e: React.ChangeEvent<HTMLInputElement>) {
    setProducto({ ...producto, [e.target.name]: e.target.value });
  }

  function submitProduc(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (producto.cantidad > 0 && producto.sku !== '' && producto.producto !== '') {
      let arrayPushear: any = [...productosLocal, { ...producto }];
      let arraySetearValue: IProductosLocal[] = arrayPushear.map((value: any) => ({
        cantidad: value.cantidad,
        sku: value.sku,
        producto: value.producto,
        id: value.id,
      }));
      setProducto({ cantidad: 0, sku: '', producto: '', id: 0 });
      setProductosLocal(arrayPushear);
      setValue('productos', arraySetearValue);
    } else EnviarMensaje('warning', 'Complete todos los campos y vuelva a intentar agregar el producto.');
  }

  function deleteProduct(e: React.MouseEvent<HTMLButtonElement>, id: number) {
    e.preventDefault();
    let productoDelete = productosLocal.filter((value: any, key: number) => key !== id);
    let arraySetearValue = productoDelete.map((value) => ({
      cantidad: value.cantidad,
      sku: value.sku,
      producto: value.producto,
      id: value.id,
    }));
    setProductosLocal(productoDelete);
    setValue('productos', arraySetearValue);
  }

  function submitFormOrdenes(e: React.MouseEvent<HTMLButtonElement>): void {
    const fechaActual = moment();
    let fechaConHoralActual = moment(getValues().fecha).set({
      hour: fechaActual.hour(),
      minute: fechaActual.minute(),
      second: fechaActual.second(),
    });
    let fechaFormateada = fechaConHoralActual.format('YYYY-MM-DD HH:mm:ss');
    let sendBody = {
      ...getValues(),
      fecha: fechaFormateada,
      productos: JSON.stringify(
        getValues().productos.map((value) => ({
          cantidad: Number(value.cantidad),
          producto: value.producto,
          sku: value.sku,
          id: value.id,
        }))
      ),
    };
    if (getValues().productos.length)
      PostEureka('/remitos/altaRemito', sendBody).then((response) => {
        EnviarMensaje('success', response?.content);
        SetRefresh();
        handleClose();
      });
    else EnviarMensaje('warning', 'Debe tener al menos un producto.');
  }

  function submitModification(e: React.MouseEvent<HTMLButtonElement>): void {
    if (getValues()?.productos?.length) {
      let masajeo = {
        ...getValues(),
        mod_manual: datos.usuario.username,
        productos: JSON.stringify(
          getValues().productos.map((value) => ({
            cantidad: Number(value.cantidad),
            producto: value.producto,
            sku: value.sku,
            id: value.id,
          }))
        ),
      };
      PutEureka(`/Remitos/${getValues().idremito}`, masajeo).then((response) => {
        EnviarMensaje('success', `Remito ${response.content[0].idremito} actualizado con exito.`);
        SetRefresh();
        handleClose();
      });
    } else EnviarMensaje('warning', 'No cuenta con ningun producto, por favor agregue uno.');
  }

  function funcionEjecutar(e: React.MouseEvent<HTMLButtonElement>, accion: string): void {
    switch (accion) {
      case 'm':
        return submitModification(e);
      default:
        return submitFormOrdenes(e);
    }
  }

  function changeProduc(e: React.MouseEvent<HTMLButtonElement>, producto: any, key: number) {
    e.preventDefault();
    ModalShow(0, 'm');
    setproductoById({ ...producto, key });
  }

  useEffect(() => {
    if (ListaChecks.length) {
      if (estadoOrdenes.show) {
        GetEureka(`/Remitos/byid/${ListaChecks[0]?.idremito}`).then((response) => {
          let values = response.content;
          setValue('estado', values.estado);
          setValue('fecha_alta', moment(values.fecha_alta).format('DD/MM/YYYY'));
          setValue('idestado', values.idestado);
          setValue('idremito', values.idremito);
          setValue('nroremito', values.nroremito);
          setValue('observaciones', values.observaciones);
          setValue('productos', JSON.parse(values.productos || '[]'));
          setValue('sucursal', values.sucursal);
          setValue('total', values.total);
          setValue('mod_manual', values.mod_manual);
          if (values.fecha_confirmacion) setValue('fecha_confirmacion', moment(values.fecha_confirmacion).format('DD/MM/YYYY'));
          setProductosLocal(JSON.parse(values.productos || '[]'));
        });
      }
    }
  }, [ListaChecks, setValue]);
  return (
    <>
      <EurekaModals manejador={modalModificar} modalTitulo='Modificar producto' sizeModal='lg' handleClose={ModalClose}>
        <FormModificarProductoRemito setValueProduc={setValue} productosLocal={productosLocal} setProductosLocal={setProductosLocal} producto={productoById} formDisabled={formDisabled} handleClose={ModalClose} />
      </EurekaModals>
      <form className='row mt-2' onSubmit={handleSubmit((data: any) => funcionEjecutar(data, estadoOrdenes?.accion))}>
        <div className='col-12 col-lg-6'>
          {getValues().mod_manual && (
            <div className='col-12 d-block d-sm-flex align-items-center mb-3'>
              <Form.Label htmlFor='mod_manual' className='col-3'>
                Mod manual
              </Form.Label>
              <Badge bg='secondary' className='ms-2' style={{ textDecoration: 'none' }}>
                {getValues().mod_manual}
              </Badge>
            </div>
          )}
          <div className='col-12 d-block d-sm-flex align-items-center mb-3'>
            <Form.Label htmlFor='nroremito' className='col-3'>
              Nro Remito
            </Form.Label>
            <Form.Control type='text' readOnly {...register('nroremito')} />
          </div>
          {!ListaChecks.length && (
            <div className='col-12 d-block d-sm-flex align-items-center mb-3'>
              <Form.Label htmlFor='Fecha' className='col-3'>
                Fecha
              </Form.Label>
              <Form.Control type='date' disabled={formDisabled} {...register('fecha')} max={moment().format('YYYY-MM-DD')} />
            </div>
          )}
          {getValues().fecha_alta && (
            <div className='col-12 d-block d-sm-flex align-items-center mb-3'>
              <Form.Label htmlFor='Fecha' className='col-3'>
                Fecha alta
              </Form.Label>
              <span className='ms-2 badge bg-secondary'>{getValues().fecha_alta}</span>
            </div>
          )}
          {getValues().fecha_confirmacion && (
            <div className='col-12 d-block d-sm-flex align-items-center mb-3'>
              <Form.Label htmlFor='Fecha' className='col-3'>
                Fecha confirmado
              </Form.Label>
              <span className='ms-2 badge bg-secondary'>{getValues().fecha_confirmacion}</span>
            </div>
          )}
          <div className='col-12 mb-2'>
            <Form.Label htmlFor='observaciones' className='col-3'>
              Observaciones
            </Form.Label>
            <Form.Control as='textarea' type='text' disabled={formDisabled} {...register('observaciones')} />
          </div>
        </div>
        <div className='col-12 col-lg-6'>
          <div className='col-12'>
            <div className='row justify-content-center align-items-end'>
              <Form.Group className='col-2 p-0'>
                <Form.Label>Cant</Form.Label>
                <Form.Control type='text' placeholder='Cantidad' name='cantidad' value={producto.cantidad} onChange={escribiendoProducto} />
              </Form.Group>
              <Form.Group className='col-2 p-0'>
                <Form.Label>Sku</Form.Label>
                <Form.Control type='text' placeholder='Sku' name='sku' value={producto.sku} onChange={escribiendoProducto} />
              </Form.Group>
              <Form.Group className='col-6 p-0'>
                <Form.Label>Productos</Form.Label>
                <Form.Control type='text' placeholder='Escribe tu producto' name='producto' value={producto.producto} onChange={escribiendoProducto} />
              </Form.Group>
              <Button disabled={formDisabled} variant='primary' className='col-1' onClick={submitProduc}>
                +
              </Button>
            </div>
          </div>
          <hr className='hrnew' />
          <div className='col-12'>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Cant.</th>
                  <th>SKU</th>
                  <th>Producto</th>
                </tr>
              </thead>
              <tbody>
                {productosLocal?.map((value, key) => (
                  <tr key={key}>
                    <td className='text-center w-100px'>
                      <button className='btn btn-sm btn-danger' disabled={formDisabled} onClick={(e) => deleteProduct(e, key)}>
                        <FontAwesomeIcon icon={faX} />
                      </button>
                      <button className='btn btn-sm btn-warning ms-2' disabled={formDisabled} onClick={(e) => changeProduc(e, value, key)}>
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </td>
                    <td className='text-center align-middle'>{value.cantidad}</td>
                    <td className='text-center align-middle'>
                      <EurekaOverlayTrigger title={value?.sku?.split(',')[0]} variant='light'>
                        <ul>
                          {value?.sku
                            ?.split(',')
                            .slice(0, value?.sku?.split(',').length)
                            .map((value, key) => (
                              <li key={key}>{value}</li>
                            ))}
                        </ul>
                      </EurekaOverlayTrigger>
                    </td>
                    <td className='text-center align-middle'>{value.producto}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div className='col-12 d-flex justify-content-end'>
          {estadoOrdenes?.accion === 'c' && (
            <button type='button' className='btn btn-sm btn-danger w-100 me-1' onClick={() => handleClose()}>
              Cerrar
            </button>
          )}
          {(estadoOrdenes.accion === 'f' || estadoOrdenes?.accion === 'm') && (
            <button type='button' className='btn btn-sm btn-danger w-50 me-1' onClick={() => handleClose()}>
              Cerrar
            </button>
          )}
          {estadoOrdenes.accion === 'f' && (
            <button type='submit' className='btn btn-sm btn-primary w-50 me-1'>
              Aceptar
            </button>
          )}
          {estadoOrdenes?.accion === 'm' && (
            <button className='w-50 mc-btn primary sm' type='submit'>
              Modificar
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default FormFormularioOrdenes;
