import React, { useEffect, useState } from 'react';
import { EnviarMensaje, GetEureka, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
const { REACT_APP_AUTH_TN } = process.env;

interface IFormTN {
  modalClose: React.MouseEventHandler<HTMLButtonElement>;
}

const FormTN: React.FC<IFormTN> = ({ modalClose }) => {
  const [code, setCode] = useState<string>('');
  const [popup, setPopup] = useState<Window | null>(null);

  const openPopup = () => {
    const popupUrl = REACT_APP_AUTH_TN;
    const popupWindow: any = window.open(popupUrl, 'popup', 'width=600,height=600');
    setPopup(popupWindow);
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const codeMessage = event?.data?.code;
      if (codeMessage) {
        setCode(codeMessage);
        if (popup) {
          popup.close();
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [popup]);

  useEffect(() => {
    if (code && code !== '') {
      GetEureka(`/Conector/api/generar_token?code=${code}&`)
        .then((response) => {
          if (response.info) {
            const config = response?.content;
            PostEureka(`/Conector/api/alta_conector`, { config: config, conector:'tn', tipo: 3 }).then((response) => {
              if (!response.info) {
                EnviarMensaje('danger', response.content);
                modalClose(response);
              } else {
                EnviarMensaje('success', 'Registro ingresado con éxito');
                modalClose(response);
              }
            });
          }
        });
    }
  }, [code, modalClose]);

  return (
    <div>
      <div className='ps-2 pe-2 pt-4 text-center text-wrap'>
        <h4 className='fw-light'>Autenticación Tiendanube</h4>
      </div>
      <hr className='border-dark' />
      <div className='text-center mb-3'>
        <button className='btn btn-primary' onClick={openPopup}>
          Iniciar Sesión en Tiendanube
        </button>
      </div>
    </div>
  );
};

export default FormTN;