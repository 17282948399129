import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import EurekaModals from '../EurekaModals/EurekaModals';
import { EnviarMensaje } from '../EurekaGeneral/EurekaGeneral';

interface Tabla {
  setFormCotizacion: Function;
  formCoti: any;
  formCotizacion: any;
  array: IProducto[];
  arrayHead: string[];
  eliminarProducto: Function;
  formDisabled: boolean;
  accessKey: number;
}

interface IProducto {
  valor: string;
  cantidad: number;
  alto: number;
  ancho: number;
  largo: number;
  peso: string | number;
  nombre: string;
}

const Tabla: React.FC<Tabla> = ({ formCoti, formCotizacion, setFormCotizacion, array = [], arrayHead = [], eliminarProducto = () => {}, formDisabled, accessKey }) => {
  const [datosEditar, setdatosEditar] = useState<any>({
    nombre: '',
    cantidad: 0,
    alto: 0,
    ancho: 0,
    largo: 0,
    peso: 0,
    valor: 0,
  });
  const [modalEditar, setmmodalEditar] = useState({ show: false, tipo: 0, accion: '' });
  const ModalClose = () => setmmodalEditar({ show: false, tipo: 0, accion: '' });
  const ModalShow = (tipo: number, accion: string) => setmmodalEditar({ show: true, tipo: tipo, accion: accion });
  function openModal(name: string) {
    ModalShow(1, 'e');
    setdatosEditar(array.find((value: any) => value.name === name));
  }
  function cambiarValores(e: React.ChangeEvent<HTMLInputElement>) {
    setdatosEditar({
      ...datosEditar,
      [e.target.name]: e.target.value,
    });
  }
  function modificarProducto() {
    if (datosEditar.cantidad > 0 && datosEditar.alto > 0 && datosEditar.ancho > 0 && datosEditar.largo > 0 && datosEditar.peso > 0) {
      formCotizacion[accessKey] = {
        ...formCotizacion[accessKey],
        productos: formCoti.productos.map((value: any) => {
          if (value.articulo === datosEditar.nombre) {
            return {
              articulo: value.articulo,
              cantidad: Number(datosEditar.cantidad),
              alto: Number(datosEditar.alto),
              ancho: Number(datosEditar.ancho),
              largo: Number(datosEditar.largo),
              peso: Number(datosEditar.peso),
              sku: value.sku,
              valor: value.valor,
            };
          } else return value;
        }),
      };
      setFormCotizacion(formCotizacion);
      ModalClose();
    } else EnviarMensaje('danger', 'Todos los valores deben ser mayores a 0');
  }

  const devolverName = (value: any): string => value.articulo;

  return (
    <>
      <EurekaModals manejador={modalEditar} modalTitulo='Editar Producto' handleClose={() => ModalClose()}>
        <div className='row'>
          <div className='col-4'>
            <label>Cantidad</label>
            <input className='form-control' name='cantidad' type='number' defaultValue={datosEditar.cantidad} onChange={cambiarValores} step='0.01' />
          </div>
          <div className='col-4'>
            <label>Alto</label>
            <input className='form-control' name='alto' type='number' defaultValue={datosEditar.alto} onChange={cambiarValores} step='0.01' />
          </div>
          <div className='col-4'>
            <label>Ancho</label>
            <input className='form-control' name='ancho' type='number' defaultValue={datosEditar.ancho} onChange={cambiarValores} step='0.01' />
          </div>
          <div className='col-4'>
            <label>Largo</label>
            <input className='form-control' name='largo' type='number' defaultValue={datosEditar.largo} onChange={cambiarValores} step='0.01' />
          </div>
          <div className='col-4'>
            <label>Peso</label>
            <input className='form-control' name='peso' type='number' defaultValue={datosEditar.peso} onChange={cambiarValores} step='0.01' />
          </div>
          <div className='col-4'>
            <label>Valor</label>
            <input className='form-control' name='valor' type='number' defaultValue={datosEditar.valor} onChange={cambiarValores} step='0.01' />
          </div>
          <button className='btn btn-success w-100' onClick={modificarProducto}>
            Modificar
          </button>
        </div>
      </EurekaModals>
      <Table responsive>
        <thead>
          <tr>
            {arrayHead.map((value, key) => (
              <th key={key} className='text-center'>
                {value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {array.length ? (
            formCotizacion[accessKey].productos.map((value: any, key: number) => (
              <tr key={key}>
                <td className='tdEureka'>
                  <button className='btn btn-sm btn-warning' disabled={!formDisabled} onClick={() => openModal(value.name)}>
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </button>
                </td>
                <td className='tdEureka'>{value.nombre || value.articulo}</td>
                <td className='tdEureka text-center'>{value.cantidad || value.count}</td>
                <td className='tdEureka text-center'>{value.alto}</td>
                <td className='tdEureka text-center'>{value.ancho}</td>
                <td className='tdEureka text-center'>{value.largo}</td>
                <td className='tdEureka text-center'>{value.peso}</td>
                <td className='tdEureka text-center'>{value.valor}</td>
                <td className='pointer text-center'>
                  <button className='btn btn-sm btn-danger' disabled={formDisabled} onClick={() => eliminarProducto(devolverName(value), accessKey)}>
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={arrayHead.length} className='text-center'>
                No agregaste productos
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Tabla;
