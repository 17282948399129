import { useEffect, useState } from 'react';
import EurekaOrdenesPendientes from '../../estructura/EurekaTablero/Ordenes/EurekaOrdenesPendientes';
import EurekaOrdenesRechazadas from '../../estructura/EurekaTablero/Ordenes/EurekaOrdenesRechazadas';
import EurekaOrdenesFinalizadas from '../../estructura/EurekaTablero/Ordenes/EurekaOrdenesFinalizadas';
import EurekaOffcanvas from '../../estructura/EurekaOffCanvas/EurekaOffCanvas';
import EurekaDropdown from '../../estructura/EurekaDropdown/EurekaDropdown';
import EurekaModals from '../../estructura/EurekaModals/EurekaModals';
import FormFormularioOrdenes from './Forms/FormFormularioOrdenes';
import FormImportar from './Forms/FormImportarOrdenes';
import FiltroTableroOrdenes from './FiltroOrdenes';
import { GetEureka, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTableList, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Button, ButtonGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
const { REACT_APP_DIAS_DASHBOARD, REACT_APP_API_URL } = process.env;

var fecha = moment();
const fechaDesde = moment(fecha).subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD');
const fechaHasta = moment(fecha).format('YYYY-MM-DD');

interface IEstadoArrayValue {
  orden: number;
  descripcion: string;
}

interface IDefaultEstados {
  value: number;
  label: string;
}

interface FormValues {
  fecha_desde: string;
  fecha_hasta: string;
  idestado: number;
  nroremito: string;
  producto: string;
  idremito: number;
  idcliente: number;
  nroorden: string;
}

const TabOrdenes = () => {
  const [refresTablero, setRefresTablero] = useState<moment.Moment>(moment());
  const [rechazados, setRechazados] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const [defaultEstados, setdefaultEstados] = useState<IDefaultEstados[]>([]);
  const [finalizados, setFinalizados] = useState([]);
  const [directionTablero, setDirectionTablero] = useState(true);
  const [modalFormulario, setModalFormulario] = useState({ show: false, id: 0, accion: 'f' });
  const ModalCloseFormulario = () => setModalFormulario({ show: false, id: 0, accion: 'f' });
  const ModalShowFormulario = () => setModalFormulario({ show: true, id: 0, accion: 'f' });
  const [modalImportar, setModalImportar] = useState({ show: false, id: 0, accion: 'i' });
  const ModalCloseImportar = () => setModalImportar({ show: false, id: 0, accion: 'i' });
  const ModalShowImportar = () => setModalImportar({ show: true, id: 0, accion: 'i' });
  const [estados, setEstados] = useState<IEstadoArrayValue[]>([]);
  const tooltipDirection = (props: any) => <Tooltip id='button-tooltip' {...props}>{`Cambiar tablero a ${directionTablero ? 'Filas' : 'Columnas'}`}</Tooltip>;
  const SetRefresh = () => setRefresTablero(moment());
  const SetDirection = () => setDirectionTablero(!directionTablero);
  const { register, getValues, setValue } = useForm<FormValues>({
    defaultValues: {
      fecha_desde: fechaDesde,
      fecha_hasta: fechaHasta,
      idestado: 0,
      nroremito: '',
      producto: '',
      idremito: 0,
      idcliente: 0,
      nroorden: '',
    },
  });
  const [offcanvasFiltros, setoffcanvasFiltros] = useState({ show: false });
  const offCanvasFiltrosClose = () => setoffcanvasFiltros({ show: false });
  const offCanvasFiltrosShow = () => setoffcanvasFiltros({ show: true });
  useEffect(() => {
    let filtros = localStorage.getItem('filtrosremitos')?.length ? JSON.parse(localStorage.getItem('filtrosremitos') || '{}') : null;
    var formdata = getValues();
    if (filtros) {
      if (!filtros.hasOwnProperty('nroremito')) filtros.nroremito = '';
      if (!filtros.hasOwnProperty('producto')) filtros.producto = '';
      if (!filtros.hasOwnProperty('idestado')) filtros.idestado = 0;
      if (!filtros.hasOwnProperty('idcliente')) filtros.idcliente = 0;
      if (!filtros.hasOwnProperty('idcliente')) filtros.idremito = 0;
      if (!filtros.hasOwnProperty('fecha_desde')) filtros.fecha_desde = fechaDesde;
      if (!filtros.hasOwnProperty('fecha_hasta')) filtros.fecha_hasta = fechaHasta;
      setValue('idestado', filtros.idestado);
      setValue('idcliente', filtros.idcliente);
      setValue('idremito', filtros.idremito);
      setValue('nroorden', filtros.nroremito);
      setValue('producto', filtros.producto);
      setValue('fecha_desde', filtros.fecha_desde);
      setValue('fecha_hasta', filtros.fecha_hasta);
      PostEureka('/remitos/tablero', filtros).then((response) => {
        setRechazados(response.content.rechazados);
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
      let estadosArray: { value: number; label: string }[] = [];
      if (typeof filtros.idestado === 'object')
        estadosArray = estados
          .filter((value: IEstadoArrayValue) => {
            if (filtros?.idestado?.includes(value.orden)) return value;
          })
          .map((value: IEstadoArrayValue) => ({ value: value.orden, label: value.descripcion }));
      setdefaultEstados(estadosArray);
    } else {
      PostEureka('/remitos/tablero', formdata).then((response) => {
        setRechazados(response.content.rechazados);
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
    }
    GetEureka('/remitos/combos').then((response) => {
      setEstados(response.content.estados);
    });
  }, [refresTablero]);
  let arrayVentas = [
    { click: () => ModalShowImportar(), descripcion: 'Via XLS' },
    { click: () => ModalShowFormulario(), descripcion: 'Formulario' },
  ];
  return (
    <div>
      <EurekaOffcanvas id='tab-ordenes' manejador={offcanvasFiltros} offcanvasTitulo='Filtros remitos' posicion='start' handleClose={offCanvasFiltrosClose} size='eureka-offcanvas'>
        <FiltroTableroOrdenes props={{ register, getValues, SetRefresh, offCanvasFiltrosClose, estados, setValue, defaultEstados, setdefaultEstados }} />
      </EurekaOffcanvas>
      <EurekaModals id='modal_formulario' manejador={modalFormulario} modalTitulo='Ingresar remito' sizeModal='xl' handleClose={ModalCloseFormulario}>
        <FormFormularioOrdenes SetRefresh={SetRefresh} estadoOrdenes={modalFormulario} formDisabled={false} handleClose={ModalCloseFormulario}></FormFormularioOrdenes>
      </EurekaModals>
      <EurekaModals id='modal_importar' manejador={modalImportar} modalTitulo='Proceso importar' sizeModal='xl' handleClose={ModalCloseImportar}>
        <FormImportar /*estadoOrdenes={modalImportar} formDisabled={false} handleClose={ModalCloseImportar} */></FormImportar>
      </EurekaModals>
      <div className='row'>
        <div className='col-md-1 mb-2'>
          <ButtonGroup size='sm'>
            <Button onClick={offCanvasFiltrosShow} variant='outline-primary'>
              Filtros
            </Button>
            <EurekaDropdown className='rounded-0' id='dropdown-basic' title='Importar' variant='outline-primary' arrayItems={arrayVentas} />
            <Button onClick={SetRefresh} variant='outline-primary'>
              <FontAwesomeIcon icon={faArrowsRotate} />
            </Button>
            <OverlayTrigger placement='right' delay={{ show: 150, hide: 150 }} overlay={tooltipDirection}>
              <Button onClick={SetDirection} variant='outline-primary'>
                <FontAwesomeIcon icon={directionTablero ? faTableList : faTableColumns} />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </div>
      </div>
      <div className='row justify-content-center'>
        <EurekaOrdenesPendientes direccion={directionTablero} pendientes={pendientes} SetRefresh={SetRefresh} />
        <EurekaOrdenesRechazadas direccion={directionTablero} rechazados={rechazados} SetRefresh={SetRefresh} />
        {/* <EurekaOrdenesEnCamino encaminos={encamino} SetRefresh={SetRefresh} /> */}
        <EurekaOrdenesFinalizadas direccion={directionTablero} finalizadas={finalizados} SetRefresh={SetRefresh} />
      </div>
    </div>
  );
};

export default TabOrdenes;
