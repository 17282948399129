import React, { useState, useEffect } from 'react';
import { GetEureka, PostEureka } from '../../../EurekaGeneral/EurekaGeneral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
export function FormsObservados() {
  return <></>;
}

interface IProductos {
  cant: number;
  sku: string;
  prod: string;
}

interface IValidado {
  pro: string;
  id: null | number;
}

export function FormResolverEntrega(identrega: { identrega: number }) {
  const [mostrarError, setmostrarError] = useState(false);
  const [productos, setproductos] = useState<IProductos[]>([]);
  const [validados, setvalidados] = useState<IValidado[]>([]);
  useEffect(() => {
    GetEureka(`/Entregas/get_entrega/by_id/${identrega.identrega}`).then((datosEntrega) => {
      let resultado = datosEntrega.content[0];
      setproductos(JSON.parse(resultado.productos));
      PostEureka('/Entregas/valida_sku', { identrega: identrega.identrega, json_productos: JSON.parse(resultado.productos) }).then((validado) => setvalidados(validado.content));
    });
  }, []);
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    PostEureka('/Entregas/valida_sku', { identrega: identrega.identrega, json_productos: productos }).then((validado) => {
      if (validado.content.find((value: any) => value.id === null)) setmostrarError(true);
      setvalidados(validado.content);
      setTimeout(() => {
        setmostrarError(false);
      }, 3000);
    });
  }
  function agregarProducto(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setproductos([...productos, { cant: 0, sku: '', prod: '' }]);
    setvalidados([...validados, { pro: '', id: null }]);
  }
  function changeValue(e: React.ChangeEvent<HTMLInputElement>, key: number) {
    let newProductos = productos.map((value, k) => (k === key ? { ...value, [e.target.name]: e.target.value } : value));
    setproductos(newProductos);
  }
  function deleteProduc(key: number) {
    let newProducts = productos.filter((value, k) => k !== key);
    let newValidados = validados.filter((value, k) => key !== k);
    setproductos(newProducts);
    setvalidados(newValidados);
  }
  return (
    <div className='container mt-3'>
      <form onSubmit={onSubmit}>
        <div>
          <button className='btn btn-primary btn-sm' onClick={agregarProducto}>
            Agregar
          </button>
          <table className='table table-bordered table-striped table-responsive col-12'>
            <thead>
              <tr>
                <th></th>
                <th>Cantidad</th>
                <th>SKU</th>
                <th>Producto</th>
                <th>Valido</th>
              </tr>
            </thead>
            <tbody>
              {productos?.map((value, key) => (
                <tr key={key}>
                  <td className='pointer' onClick={() => deleteProduc(key)}>
                    <button className='btn btn-danger btn-sm'>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </td>
                  <td>
                    <input name='cant' value={value.cant} className='form-control' onChange={(e) => changeValue(e, key)} />
                  </td>
                  <td>
                    <input name='sku' value={value.sku} className='form-control' onChange={(e) => changeValue(e, key)} />
                  </td>
                  <td>
                    <input name='prod' value={value.prod} className='form-control' onChange={(e) => changeValue(e, key)} />
                  </td>
                  <td className='text-center'>{validados[key]?.id ? <FontAwesomeIcon className='text-success fs-5' icon={faCheck} /> : <FontAwesomeIcon className='text-danger fs-5' icon={faXmark} />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='alert alert-warning' role='alert'>
          Recuerde que el sku debe estar asignado a un producto y el producto tener stock.
        </div>
        {mostrarError && (
          <div className='alert alert-danger' role='alert'>
            El nuevo SKU no se pudo reconocer o no tiene aun stock.
          </div>
        )}
        <button className='btn btn-sm btn-danger w-100' type='submit'>
          Validar SKU
        </button>
      </form>
    </div>
  );
}
