import { useEffect } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { UseFormRegister, UseFormGetValues } from 'react-hook-form';
import moment from 'moment';
const { REACT_APP_DIAS_DASHBOARD } = process.env;

interface FormValues {
  fecha_desde: string;
  fecha_hasta: string;
  idestado: number;
  nroremito: string;
  producto: string;
  idremito: number;
  idcliente: number;
  nroorden: string;
}

interface IDefaultEstados {
  value: number;
  label: string;
}

interface IEstadoArrayValue {
  orden: number;
  descripcion: string;
}

interface IFiltroTableroOrdenes {
  props: {
    register: UseFormRegister<FormValues>;
    getValues: UseFormGetValues<FormValues>;
    SetRefresh: Function;
    offCanvasFiltrosClose: Function;
    estados: IEstadoArrayValue[];
    setValue: Function;
    defaultEstados: IDefaultEstados[];
    setdefaultEstados: Function;
  };
}

const FiltroTableroOrdenes: React.FC<IFiltroTableroOrdenes> = ({ props }) => {
  const [valorSeleccionado, setvalorSeleccionado] = useState<IDefaultEstados[]>([]);
  const { idestado } = props.getValues();
  const [sel_estado, setsel_estado] = useState<IDefaultEstados[]>([]);
  const handleEstadoChange = (selectedOption: any) => {
    props.setValue(
      'idestado',
      selectedOption.map((value: any) => value.value)
    );
    setvalorSeleccionado(selectedOption);
  };
  async function submitFiltros() {
    try {
      localStorage.setItem('filtrosremitos', JSON.stringify(props.getValues()));
      props.SetRefresh();
      props.offCanvasFiltrosClose();
    } catch (error) {
      console.log(error);
    }
  }
  function resetFiltros() {
    props.setValue('fecha_desde', moment().subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD'));
    props.setValue('fecha_hasta', moment().format('YYYY-MM-DD'));
    props.setValue('idestado', 0);
    props.setValue('nroremito', '');
    props.setValue('producto', '');
    props.setValue('idremito', 0);
    setvalorSeleccionado([]);
  }
  useEffect(() => {
    if (props.defaultEstados?.length) setvalorSeleccionado(props.defaultEstados);
    setsel_estado(props.estados?.map((value) => ({ value: value.orden, label: value.descripcion })));
  }, []);
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_desde'>Fecha desde</Form.Label>
            <Form.Control type='date' id='fecha_desde' {...props.register('fecha_desde')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_hasta'>Fecha hasta</Form.Label>
            <Form.Control type='date' id='fecha_hasta' {...props.register('fecha_hasta')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idestado'>Estado</Form.Label>
            <Select defaultValue={props.defaultEstados} isMulti id='idestado' value={valorSeleccionado} {...props.register('idestado')} onChange={handleEstadoChange} options={sel_estado} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='nroremito'>Nro remito</Form.Label>
            <Form.Control type='text' id='nroremito' placeholder='Igual a ...' {...props.register('nroremito')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='producto'>Producto</Form.Label>
            <Form.Control type='text' id='producto' placeholder='Contiene ...' {...props.register('producto')} />
          </div>
          <div className='form-group mt-4'>
            <button onClick={resetFiltros} className='btn btn-warning w-50 btn-sm'>
              Resetear
            </button>
            <button onClick={submitFiltros} className='btn btn-primary w-50 btn-sm'>
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltroTableroOrdenes;
