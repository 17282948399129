import { useEffect, useState, useRef } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment-timezone';
import EurekaOverlay from '../../estructura/EurekaOverlay/EurekaOverlay';
import { UseFormRegister, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
const { REACT_APP_DIAS_DASHBOARD } = process.env;

interface IUseForm {
  fecha_desde: string;
  fecha_hasta: string;
  idtipoconector: number;
  idtipoentrega: number;
  idestado: number;
  nro_venta: string;
  producto: string;
  codinterno: string;
  repartidor: number;
  sucursal: number;
  cliente: number;
  tipo_entrega: number;
  impreso: number;
  es_turbo: number;
  fecha_despacho_inicio: string | null;
  fecha_despacho_fin: string | null;
  fecha_entrega_inicio: string | null;
  fecha_entrega_fin: string | null;
  cancelado: number;
}

interface IArray {
  descripcion: string;
  orden?: number;
  id?: number;
}

interface IArrayDefault {
  label: string;
  value: number | undefined;
}

interface IFiltroTableroEntregas {
  props: {
    register: UseFormRegister<IUseForm>;
    getValues: UseFormGetValues<IUseForm>;
    setValue: UseFormSetValue<IUseForm>;
    SetRefresh: Function;
    offCanvasFiltrosClose: Function;
    estados: IArray[];
    tipoConector: IArray[];
    tipoEntregas: IArray[];
    clientes: IArray[];
    sucursales: IArray[];
    repartidores: IArray[];
    defaultEstados: IArrayDefault[];
    defaultConector: IArrayDefault[];
    defaultClientes: IArrayDefault[];
    defaultSucursales: IArrayDefault[];
    defaultRepartidores: IArrayDefault[];
    defaultEsTurbo: IArrayDefault[];
    defaultImpreso: IArrayDefault[];
    defaultCancelado: IArrayDefault[];
  };
}

interface IFechasDespacho {
  startDate: undefined | Date;
  endDate: undefined | Date;
}

const FiltroTableroEntregas: React.FC<IFiltroTableroEntregas> = ({ props }) => {
  const [showFecha, setshowFecha] = useState(false);
  const [target, setTarget] = useState(null);
  const refFecha = useRef(null);
  const [showFechaDespacho, setshowFechaDespacho] = useState(false);
  const [targetDespacho, setTargetDespacho] = useState(null);
  const refFechaDespacho = useRef(null);
  const [showFechaEntrega, setshowFechaEntrega] = useState(false);
  const [targetEntrega, setTargetEntrega] = useState(null);
  const refFechaEntrega = useRef(null);

  const [fechas, setFechas] = useState({
    startDate: moment.tz('America/Argentina/Buenos_Aires').subtract(REACT_APP_DIAS_DASHBOARD, 'days').startOf('day').toDate(),
    endDate: moment.tz('America/Argentina/Buenos_Aires').startOf('day').toDate(),
  });
  const [fechasDespacho, setFechasDespacho] = useState<IFechasDespacho>({
    startDate: undefined,
    endDate: undefined,
  });
  const [fechasEntrega, setFechasEntrega] = useState<IFechasDespacho>({
    startDate: undefined,
    endDate: undefined,
  });
  const [conectorSeleccionado, setconectorSeleccionado] = useState<IArrayDefault[]>([]);
  const [estadoSeleccionado, setestadoSeleccionado] = useState<IArrayDefault[]>([]);
  const [clienteSeleccionado, setclienteSeleccionado] = useState<IArrayDefault[]>([]);
  const [sucursalSeleccionado, setsucursalSeleccionado] = useState<IArrayDefault[]>([]);
  const [repartidorSeleccionado, setrepartidorSeleccionado] = useState<IArrayDefault[]>([]);
  const [esTurboSeleccionado, setesTurboSeleccionado] = useState<IArrayDefault[]>([]);
  const [impresoSeleccionado, setimpresoSeleccionado] = useState<IArrayDefault[]>([]);
  const [canceladoSeleccionado, setcanceladoSeleccionado] = useState<IArrayDefault[]>([]);
  const { idtipoconector, idestado } = props.getValues();
  const sel_tipoconector: IArrayDefault[] = [];
  const sel_tipoentrega: IArrayDefault[] = [];
  const sel_estado: IArrayDefault[] = [];
  const sel_cliente: IArrayDefault[] = [];
  const sel_sucursal: IArrayDefault[] = [];
  const sel_repartidor: IArrayDefault[] = [];
  const sel_esturbo = [
    { value: 3, label: 'Seleccione' },
    { value: 1, label: 'Si' },
    { value: 0, label: 'No' },
  ];
  const sel_impreso = [
    { value: 3, label: 'Seleccione' },
    { value: 1, label: 'Si' },
    { value: 0, label: 'No' },
  ];
  const sel_cancelado = [
    { value: 3, label: 'Seleccione' },
    { value: 1, label: 'Si' },
    { value: 0, label: 'No' },
  ];

  props.tipoConector?.map((value, key) => sel_tipoconector.push({ value: value.orden, label: value.descripcion }));
  props.tipoEntregas?.map((value, key) => sel_tipoentrega.push({ value: value.orden, label: value.descripcion }));
  props.estados?.map((value, key) => sel_estado.push({ value: value.orden, label: value.descripcion }));
  props.clientes?.map((value, key) => sel_cliente.push({ value: value.id, label: value.descripcion }));
  props.sucursales?.map((value, key) => sel_sucursal.push({ value: value.id, label: value.descripcion }));
  props.repartidores?.map((value, key) => sel_repartidor.push({ value: value.id, label: value.descripcion }));

  const handleConectorChange = (selectedOption: any) => {
    props.setValue(
      'idtipoconector',
      selectedOption.map((value: any) => value.value)
    );
    setconectorSeleccionado(selectedOption);
  };
  /*     const handleEntregaChange = (selectedOption) => { props.setValue('idtipoentrega', selectedOption.map(value => value.value)); }; */
  const handleEstadoChange = (selectedOption: any) => {
    props.setValue(
      'idestado',
      selectedOption.map((value: any) => value.value)
    );
    setestadoSeleccionado(selectedOption);
  };

  const handleClienteChange = (selectedOption: any) => {
    props.setValue(
      'cliente',
      selectedOption.map((value: any) => value.value)
    );
    setclienteSeleccionado(selectedOption);
  };

  const handleSucursalChange = (selectedOption: any) => {
    props.setValue(
      'sucursal',
      selectedOption.map((value: any) => value.value)
    );
    setsucursalSeleccionado(selectedOption);
  };

  const handleRepartidorChange = (selectedOption: any) => {
    props.setValue(
      'repartidor',
      selectedOption.map((value: any) => value.value)
    );
    setrepartidorSeleccionado(selectedOption);
  };

  const handleEsTurboChange = (selectedOption: any) => {
    props.setValue('es_turbo', selectedOption.value);
    setesTurboSeleccionado(selectedOption);
  };

  const handleImpresoChange = (selectedOption: any) => {
    props.setValue('impreso', selectedOption.value);
    setimpresoSeleccionado(selectedOption);
  };

  const handleCanceladoChange = (selectedOption: any) => {
    props.setValue('cancelado', selectedOption.value);
    setcanceladoSeleccionado(selectedOption);
  };

  function submitFiltros() {
    localStorage.setItem('filtrosentregas', JSON.stringify(props.getValues()));
    props.SetRefresh();
    props.offCanvasFiltrosClose();
  }

  function resetFiltros() {
    props.setValue('fecha_desde', moment().tz('America/Argentina/Buenos_Aires').subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD'));
    props.setValue('fecha_hasta', moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    props.setValue('idtipoconector', 0);
    props.setValue('idtipoentrega', 0);
    props.setValue('idestado', 0);
    props.setValue('nro_venta', '');
    props.setValue('producto', '');
    props.setValue('cliente', 0);
    props.setValue('sucursal', 0);
    props.setValue('repartidor', 0);
    props.setValue('es_turbo', 3);
    props.setValue('impreso', 3);
    props.setValue('fecha_entrega_inicio', null);
    props.setValue('fecha_entrega_fin', null);
    props.setValue('fecha_despacho_inicio', null);
    props.setValue('fecha_despacho_fin', null);
    props.setValue('cancelado', 3);
    setimpresoSeleccionado([]);
    setesTurboSeleccionado([]);
    setconectorSeleccionado([]);
    setestadoSeleccionado([]);
    setclienteSeleccionado([]);
    setsucursalSeleccionado([]);
    setrepartidorSeleccionado([]);
    setcanceladoSeleccionado([]);
    setFechasEntrega({
      startDate: undefined,
      endDate: undefined,
    });
    setFechasDespacho({
      startDate: undefined,
      endDate: undefined,
    });
    setFechas({
      startDate: moment.tz('America/Argentina/Buenos_Aires').subtract(REACT_APP_DIAS_DASHBOARD, 'days').startOf('day').toDate(),
      endDate: moment.tz('America/Argentina/Buenos_Aires').startOf('day').toDate(),
    });
  }

  function changeDateRange(e: RangeKeyDict) {
    const { range1 } = e;
    const startDate = moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').startOf('day').toDate();
    const endDate = moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').endOf('day').toDate();
    setFechas({
      startDate,
      endDate,
    });
    props.setValue('fecha_desde', moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    props.setValue('fecha_hasta', moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
  }
  function changeDateRangeDespacho(e: RangeKeyDict) {
    const { range1 } = e;
    const startDate: any = moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').startOf('day').toDate();
    const endDate: any = moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').endOf('day').toDate();
    setFechasDespacho({
      startDate,
      endDate,
    });
    props.setValue('fecha_despacho_inicio', moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    props.setValue('fecha_despacho_fin', moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
  }
  function changeDateRangeEntrega(e: RangeKeyDict) {
    const { range1 } = e;
    const startDate: any = moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').startOf('day').toDate();
    const endDate: any = moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').endOf('day').toDate();
    setFechasEntrega({
      startDate,
      endDate,
    });
    props.setValue('fecha_entrega_inicio', moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    props.setValue('fecha_entrega_fin', moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
  }

  useEffect(() => {
    setFechas({
      startDate: moment.tz(props.getValues().fecha_desde, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
      endDate: moment.tz(props.getValues().fecha_hasta, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
    });
    if (props.getValues().fecha_despacho_inicio && props.getValues().fecha_despacho_fin) {
      setFechasDespacho({
        startDate: moment.tz(props.getValues().fecha_despacho_inicio, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
        endDate: moment.tz(props.getValues().fecha_despacho_fin, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
      });
    }
    if (props.getValues().fecha_entrega_inicio && props.getValues().fecha_entrega_fin) {
      setFechasEntrega({
        startDate: moment.tz(props.getValues().fecha_entrega_inicio, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
        endDate: moment.tz(props.getValues().fecha_entrega_fin, 'America/Argentina/Buenos_Aires').startOf('day').toDate(),
      });
    }
    if (props.defaultConector?.length) setconectorSeleccionado(props.defaultConector);
    if (props.defaultEstados?.length) setestadoSeleccionado(props.defaultEstados);
    if (props.defaultClientes?.length) setclienteSeleccionado(props.defaultClientes);
    if (props.defaultSucursales?.length) setsucursalSeleccionado(props.defaultSucursales);
    if (props.defaultRepartidores?.length) setrepartidorSeleccionado(props.defaultRepartidores);
    if (props.defaultEsTurbo?.length) setesTurboSeleccionado(props.defaultEsTurbo);
    if (props.defaultImpreso?.length) setimpresoSeleccionado(props.defaultImpreso);
    if (props.defaultCancelado?.length) setcanceladoSeleccionado(props.defaultCancelado);
  }, []);
  function functionShowFecha(e: any) {
    setshowFecha(!showFecha);
    setTarget(e.target);
  }
  function functionShowFechaDespacho(e: any) {
    setshowFechaDespacho(!showFechaDespacho);
    setTargetDespacho(e.target);
  }
  function functionShowFechaEntrega(e: any) {
    setshowFechaEntrega(!showFechaEntrega);
    setTargetEntrega(e.target);
  }
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <Form.Label htmlFor='fecha'>Fecha</Form.Label>
            <EurekaOverlay target={target} ref={refFecha} titlePop='Fecha' arrayTitle={Object.values(fechas)} show={showFecha} handleClick={functionShowFecha}>
              <DateRangePicker ranges={[fechas]} onChange={changeDateRange} />
            </EurekaOverlay>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_despacho' className='pt-2'>
              Fecha despacho
            </Form.Label>
            <EurekaOverlay target={targetDespacho} ref={refFechaDespacho} titlePop='Fecha despacho' arrayTitle={Object.values(fechasDespacho)} show={showFechaDespacho} handleClick={functionShowFechaDespacho}>
              <DateRangePicker ranges={[fechasDespacho]} onChange={changeDateRangeDespacho} />
            </EurekaOverlay>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_entrega' className='pt-2'>
              Fecha entrega
            </Form.Label>
            <EurekaOverlay target={targetEntrega} ref={refFechaEntrega} titlePop='Fecha entrega' arrayTitle={Object.values(fechasEntrega)} show={showFechaEntrega} handleClick={functionShowFechaEntrega}>
              <DateRangePicker ranges={[fechasEntrega]} onChange={changeDateRangeEntrega} />
            </EurekaOverlay>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idturbo' className='pt-2'>
              Turbo
            </Form.Label>
            <Select id='idturbo' defaultValue={sel_esturbo.filter((value) => value.value === props.getValues().es_turbo)} value={esTurboSeleccionado} {...props.register('es_turbo')} onChange={handleEsTurboChange} options={sel_esturbo} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idimpreso'>Impreso</Form.Label>
            <Select id='idimpreso' defaultValue={sel_impreso.filter((value) => value.value === props.getValues().impreso)} value={impresoSeleccionado} {...props.register('impreso')} onChange={handleImpresoChange} options={sel_impreso} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idcancelado'>Cancelado</Form.Label>
            <Select id='idcancelado' defaultValue={sel_cancelado.filter((value) => value.value === props.getValues().cancelado)} value={canceladoSeleccionado} {...props.register('cancelado')} onChange={handleCanceladoChange} options={sel_cancelado} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idtipoconector'>Tipo conector</Form.Label>
            <Select isMulti id='idtipoconector' defaultValue={props.defaultConector} value={conectorSeleccionado} {...props.register('idtipoconector')} onChange={handleConectorChange} options={sel_tipoconector} />
          </div>
          {/*                 <div className="form-group">
                    <Form.Label htmlFor="idtipoentrega">Tipo entrega</Form.Label>
                    <Select isMulti id="idtipoentrega" value={sel_tipoentrega.find(option => option.value === idtipoentrega)} {...props.register("idtipoentrega")} onChange={handleEntregaChange} options={sel_tipoentrega} />
                </div> */}
          <div className='form-group'>
            <Form.Label htmlFor='idestado'>Estado</Form.Label>
            <Select isMulti id='idestado' defaultValue={props.defaultEstados} value={estadoSeleccionado} {...props.register('idestado')} onChange={handleEstadoChange} options={sel_estado} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idcliente'>Cliente</Form.Label>
            <Select isMulti id='idcliente' defaultValue={props.defaultClientes} value={clienteSeleccionado} {...props.register('cliente')} onChange={handleClienteChange} options={sel_cliente} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idsucursal'>Sucursal</Form.Label>
            <Select isMulti id='idsucursal' defaultValue={props.defaultSucursales} value={sucursalSeleccionado} {...props.register('sucursal')} onChange={handleSucursalChange} options={sel_sucursal} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idsucursal'>Repartidor</Form.Label>
            <Select isMulti id='idsucursal' defaultValue={props.defaultRepartidores} value={repartidorSeleccionado} {...props.register('repartidor')} onChange={handleRepartidorChange} options={sel_repartidor} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='nro_venta'>Nro venta</Form.Label>
            <Form.Control type='text' id='nro_venta' placeholder='Igual a ...' {...props.register('nro_venta')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='codinterno'>Cod interno</Form.Label>
            <Form.Control type='text' id='codinterno' placeholder='Contiene ...' {...props.register('codinterno')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='producto'>Producto</Form.Label>
            <Form.Control type='text' id='producto' placeholder='Contiene ...' {...props.register('producto')} />
          </div>
          <div className='form-group mt-4'>
            <button onClick={resetFiltros} className='btn btn-warning w-50 btn-sm'>
              Resetear
            </button>
            <button onClick={submitFiltros} className='btn btn-primary w-50 btn-sm'>
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltroTableroEntregas;
