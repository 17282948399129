import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { EnviarMensaje } from '../../../estructura/EurekaGeneral/EurekaGeneral';

interface IFormModificarProductoRemito {
  producto: { cantidad: number; producto: string; key: number; sku: string };
  formDisabled: boolean;
  handleClose: Function;
  productosLocal: { id: number }[];
  setProductosLocal: Function;
  setValueProduc: Function;
}

interface IUseForm {
  cantidad: number;
  sku: string;
  producto: string;
  key: number;
  id: number;
}

const FormModificarProductoRemito: React.FC<IFormModificarProductoRemito> = ({ producto, formDisabled, handleClose, productosLocal, setProductosLocal, setValueProduc }) => {
  const { setValue, register, handleSubmit } = useForm<IUseForm>({
    defaultValues: {
      cantidad: 0,
      sku: '',
      producto: '',
      key: 0,
      id: 0,
    },
  });
  function closeModal(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    handleClose();
  }
  function onSubmit(e: IUseForm) {
    e.cantidad = Number(e.cantidad);
    if (e.cantidad > 0 && e.sku.length && e.producto.length) {
      let newArrayProductos = productosLocal.map((value) => (value.id === e.id ? e : value));
      setProductosLocal(newArrayProductos);
      setValueProduc('productos', newArrayProductos);
      handleClose();
    } else {
      EnviarMensaje('warning', 'Todos los campos deben estar completos.');
    }
  }
  useEffect(() => {
    setValue('cantidad', Number(producto.cantidad));
    setValue('sku', producto.sku);
    setValue('producto', producto.producto);
    setValue('key', Number(producto.key));
  }, []);
  return (
    <form className='row' onSubmit={handleSubmit(onSubmit)}>
      <div className='col-12'>
        <Form.Label htmlFor='nroremito'>Producto</Form.Label>
        <Form.Control type='text' disabled={formDisabled} {...register('producto')} />
      </div>
      <div className='col-6'>
        <Form.Label htmlFor='nroremito'>Sku</Form.Label>
        <Form.Control type='text' disabled={formDisabled} {...register('sku')} />
      </div>
      <div className='col-6'>
        <Form.Label htmlFor='cantidad'>Cantidad</Form.Label>
        <Form.Control min={1} type='number' disabled={formDisabled} {...register('cantidad')} />
      </div>
      <button className='btn btn-sm btn-danger w-50' onClick={closeModal}>
        Cerrar
      </button>
      <button className='btn btn-sm btn-success w-50' type='submit'>
        Modificar
      </button>
    </form>
  );
};

export default FormModificarProductoRemito;
