import React, { useEffect, useState } from 'react';
import { Row, Col, Tab, Tabs, Form, InputGroup } from 'react-bootstrap';
import { Box, Button, Image } from '../../components/elements';
import { CardLayout, TabCard } from '../../components/cards';
import PageLayout from '../../layouts/PageLayout';
import data from '../../data/master/myAccount.json';
import { FlexitContext } from '../../context/Flexit';
import { useNavigate } from 'react-router-dom';
import PlaceholderCuenta from '../../components/placeholders/PlaceholderCuenta';
import { PostEureka, PutEureka } from '../../components-new/estructura/EurekaGeneral/EurekaGeneral';
import useGetComplexObject from '../../hooks/useGetComplexObject';

interface IUser {
  imagen: string;
  nombres: string;
  apellidos: string;
  email: string;
  telefono: string;
  direccion: string;
  username: string;
}

interface IEdit {
  nombres: boolean;
  apellidos: boolean;
  email: boolean;
  telefono: boolean;
  direccion: boolean;
  username: boolean;
}

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

export default function MyAccount() {
  const { datos, tokenDecifrado, setearDatos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState<IUser>({
    imagen: '',
    nombres: 'Nombre',
    apellidos: 'Apellido',
    email: 'Email',
    telefono: 'Telefono',
    direccion: 'Direccion',
    username: '',
  });
  const [edit, setEdit] = useState<IEdit>({
    nombres: false,
    apellidos: false,
    email: false,
    telefono: false,
    direccion: false,
    username: false,
  });
  const navigate = useNavigate();

  async function getUsuario(id: number) {
    setLoading(false);
    let response = await PostEureka('/seguridad/Usuarios/consultausuariobyid', {
      sandbox: true,
      id: id,
    });
    setUsuario({
      ...response.content[0],
      cambio: false,
    });
    setLoading(true);
  }

  const traerUsuario = async (mandar: any) => {
    let token = await tokenDecifrado(mandar);
    setearDatos(token.data);
    return token.data;
  };

  function cambiarValorUser(e: React.ChangeEvent<HTMLInputElement>) {
    setUsuario({ ...usuario, [e.target.name]: e.target.value });
  }

  function cambiarEdit(name: string, value: boolean) {
    setEdit({
      ...edit,
      [name]: value,
    });
  }

  async function changeUser(user: IUser) {
    setLoading(false);
    PutEureka('/seguridad/Usuarios/ModificacionUsuario', { sandbox: true, modificacion: { update: user } }).then(() => getUsuario(datos?.usuario?.idusuario));
    setLoading(true);
  }

  useEffect(() => {
    traerUsuario(localStorage.getItem('token')).then((value) => getUsuario(value?.usuario?.idusuario));
  }, []);

  return (
    <PageLayout>
      {loading ? (
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Tabs defaultActiveKey='profile' id='mc' className='mc-tabs btn-secondary'>
                <Tab eventKey='profile' title='Perfil' className='mc-tabpane profile'>
                  <TabCard title='Información pública'>
                    <Row>
                      <Col xl={4}>
                        <Box className='mc-user-avatar-upload'>
                          <Box className='mc-user-avatar'>
                            <Image src={'https://cdn-icons-png.flaticon.com/512/3033/3033143.png'} alt={data?.avatar.alt} />
                          </Box>
                          {/* <FileUpload icon="cloud_upload" text="upload" /> */}
                        </Box>
                      </Col>
                      <Col xl={8}>
                        <Row>
                          <Col xl={12}>
                            <Form.Label>Nombres</Form.Label>
                            <InputGroup>
                              <Form.Control onChange={cambiarValorUser} disabled={!edit?.nombres} name='nombres' placeholder='Nombres' aria-describedby='basic-addon1' aria-label='Nombres' value={usuario?.nombres} className='colorLetra' />
                              <InputGroup.Text id='basic-addon1' onClick={() => cambiarEdit('nombres', !edit.nombres)} className='input-group-flexit'>
                                <Button className='sm' icon={`${edit?.nombres ? 'close' : 'edit'}`}></Button>
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                          <Col xl={12}>
                            <Form.Label>Apellidos</Form.Label>
                            <InputGroup>
                              <Form.Control onChange={cambiarValorUser} disabled={!edit?.apellidos} name='apellidos' placeholder='Apellidos' aria-describedby='basic-addon1' aria-label='Apellidos' value={usuario?.apellidos} className='colorLetra' />
                              <InputGroup.Text id='basic-addon1' onClick={() => cambiarEdit('apellidos', !edit?.apellidos)} className='input-group-flexit'>
                                <Button className='sm' icon={`${edit?.apellidos ? 'close' : 'edit'}`}></Button>
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                          <Col xl={12}>
                            <Form.Label>Usuario</Form.Label>
                            <InputGroup>
                              <Form.Control disabled={true} name='username' placeholder='Usuario' aria-describedby='basic-addon1' aria-label='Nombres' value={usuario?.username} className='colorLetra' />
                              <InputGroup.Text id='basic-addon1' className='input-group-flexit'>
                                <Button className='sm' icon={'block'}></Button>
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabCard>
                  <TabCard title='Información privada'>
                    <Row>
                      <Col xl={4}>
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                          <Form.Control onChange={cambiarValorUser} disabled={!edit?.email} name='email' placeholder='Email' aria-describedby='basic-addon1' aria-label='Email' value={usuario?.email} className='colorLetra' />
                          <InputGroup.Text id='basic-addon1' onClick={() => cambiarEdit('email', !edit?.email)} className='input-group-flexit'>
                            <Button className='sm' icon={`${edit?.email ? 'close' : 'edit'}`}></Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col xl={4}>
                        <Form.Label>Telefono</Form.Label>
                        <InputGroup>
                          <Form.Control onChange={cambiarValorUser} disabled={!edit?.telefono} name='telefono' placeholder='Telefono' aria-describedby='basic-addon1' aria-label='Telefono' value={usuario?.telefono} className='colorLetra' />
                          <InputGroup.Text id='basic-addon1' onClick={() => cambiarEdit('telefono', !edit?.telefono)} className='input-group-flexit'>
                            <Button className='sm' icon={`${edit?.telefono ? 'close' : 'edit'}`}></Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col xl={4}>
                        <Form.Label>Direccion</Form.Label>
                        <InputGroup>
                          <Form.Control onChange={cambiarValorUser} disabled={!edit?.direccion} name='direccion' placeholder='Direccion' aria-describedby='basic-addon1' aria-label='Direccion' value={usuario?.direccion} className='colorLetra' />
                          <InputGroup.Text id='basic-addon1' onClick={() => cambiarEdit('direccion', !edit?.direccion)} className='input-group-flexit'>
                            <Button className='sm' icon={`${edit?.direccion ? 'close' : 'edit'}`}></Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                      <Col className='d-flex flex-wrap justify-content-end align-items-center'>
                        <Col xs={12} sm={4} lg={3} className='me-1 mt-1'>
                          <Button onClick={() => navigate('/CambiarContraseña')} className='mc-btn primary me-1 sm' icon='password' text='cambiar contraseña' />
                        </Col>
                        <Col xs={12} sm={4} lg={3} className='me-1 mt-1'>
                          <Button onClick={() => changeUser(usuario)} className='mc-btn primary sm' icon='verified' text='guardar cambios' />
                        </Col>
                      </Col>
                    </Row>
                  </TabCard>
                </Tab>
              </Tabs>
            </CardLayout>
          </Col>
        </Row>
      ) : (
        <PlaceholderCuenta />
      )}
    </PageLayout>
  );
}
