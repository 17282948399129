import React, { useState, useEffect } from 'react';
import EurekaGeneral, { PutEureka } from '../../../EurekaGeneral/EurekaGeneral';

const { REACT_APP_URL_BLUEHOST } = process.env;

interface IFormConfirmarEntregas {
  ListaChecks: IListaChecks[];
  SetRefresh: Function;
  ModalClose: Function;
}

interface IListaChecks {
  id: number;
  nroventa: string;
  idsucursal: number;
  idtipoconector: number;
}

const FormConfirmarEntregas: React.FC<IFormConfirmarEntregas> = ({ ListaChecks = [], SetRefresh, ModalClose }) => {
  const [content, setContent] = useState(
    <div className='alert alert-danger' role='alert'>
      Sin registros para mostrar
    </div>
  );
  function submit_confirmar_entregas() {
    PutEureka('/Entregas/ConfirmarEntregas', { entregas: ListaChecks }).then((response) => {
      if (response.info) {
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># venta</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroventa}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary sm' onClick={submit_confirmar_entregas}>
          Confirmar
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay entregas seleccionadas
      </div>
    );
  }
};

export default FormConfirmarEntregas;
