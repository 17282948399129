import React from 'react';
import moment from 'moment-timezone';
import { Popover, Overlay, Form } from 'react-bootstrap';

interface IEurekaOverlay {
  arrayTitle: string[] | Date[];
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  show: boolean;
  target: HTMLElement | null;
  children: React.ReactNode;
  titlePop: string;
  ref: any;
}

const EurekaOverlay: React.FC<IEurekaOverlay> = ({ arrayTitle, handleClick, show, target, children, titlePop, ref }) => {
  return (
    <div ref={ref}>
      <div className='row' onClick={handleClick}>
        {arrayTitle.map((value, key) => {
          return value === null ? (
            <div className='col-6 p-0' key={key}>
              <Form.Control className='rounded-0 flexit-style-range' value={key === 0 ? 'Inicio' : 'Fin'} readOnly />
            </div>
          ) : (
            <div className='col-6 p-0' key={key}>
              <Form.Control className='rounded-0 flexit-style-range' value={moment.tz(value, 'America/Argentina/Buenos_Aires').format('ddd, DD MMM YYYY')} readOnly />
            </div>
          );
        })}
      </div>
      <Overlay show={show} target={target} placement='bottom' containerPadding={20} container={ref}>
        <Popover id='popover-contained'>
          <Popover.Header as='h3'>{titlePop}</Popover.Header>
          <Popover.Body>{children}</Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default EurekaOverlay;
