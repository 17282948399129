import { useState } from 'react';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import PageLayout from '../../layouts/PageLayout';
import FiltroConector from './FiltrosConector';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import FormConector from './FormConector';

const EurekaConector = () => {
  const [recargaGridConector, setRecargaGridConector] = useState<string>('');
  const [titulo, setTitulo] = useState<string>('');
  const [offcanvasFiltrosConector, setoffcanvasFiltrosConector] = useState({ show: false, id: 0, accion: 'f' });
  const OffCanvasFiltrosClose = () => setoffcanvasFiltrosConector({ show: false, id: 0, accion: 'f' });
  const OffCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosConector({ show: true, id: id, accion: accion });
  const [modalConector, setmodalConector] = useState({ show: false, id: 0, accion: 'a' });
  const ModalClose = () => setmodalConector({ show: false, id: 0, accion: 'a' });
  const ModalShow = (id: number, accion: string) => setmodalConector({ show: true, id: id, accion: accion });
  const SetRefresh = (date: string) => setRecargaGridConector(date);
  function manejo_acciones(origen: string, registro: number, accion: string) {
    switch (accion) {
      case 'f': {
        setTitulo('Filtros Conector');
        OffCanvasFiltrosShow(registro, accion);
        break;
      }
      case 'c': {
        setTitulo('Consulta Conector');
        ModalShow(registro, accion);
        break;
      }
      default:
        break;
    }
  }
  return (
    <PageLayout label='Adm. Conectizaciones'>
      <div>{Grid(manejo_acciones, 'log_cotizacion', recargaGridConector, SetRefresh)}</div>
      <EurekaOffcanvas id='offcanvas_filtroconector' manejador={offcanvasFiltrosConector} offcanvasTitulo={titulo} size='xl' handleClose={OffCanvasFiltrosClose}>
        <FiltroConector props={{ SetRefresh, OffCanvasFiltrosClose }} />
      </EurekaOffcanvas>
      <EurekaModals id='modal_conector' manejador={modalConector} modalTitulo={titulo} sizeModal='xl' handleClose={ModalClose}>
        <FormConector props={{ modalConector }} />
      </EurekaModals>
    </PageLayout>
  );
};

export default EurekaConector;
