import React, { useState, useEffect } from 'react';
import PageLayout from '../../layouts/PageLayout';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import { ConfirmGeneral, PutEureka, GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
import { FlexitContext } from '../../context/Flexit';
import FormFormularioOrdenes from '../EurekaDashboard/TabOrdenes/Forms/FormFormularioOrdenes';
import moment from 'moment';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltroOrdenes from './Filtros-Ordenes';
import useGetComplexObject from '../../hooks/useGetComplexObject';

interface IModalOrdenes {
  show: boolean;
  id: number;
  accion: string;
}

interface IEstado {
  descripcion: string;
  id: string;
  orden: string;
}

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

const EurekaOrdenes = () => {
  const { datos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [tituloModal, setTituloModal] = useState('Consulta Orden');
  const [estados, setEstados] = useState<IEstado[]>([]);
  const [modalOrdenes, setmodalOrdenes] = useState<IModalOrdenes>({ show: false, id: 0, accion: 'm' });
  const ModalClose = () => setmodalOrdenes({ show: false, id: 0, accion: 'm' });
  const ModalShow = (id: number, accion: string) => setmodalOrdenes({ show: true, id: id, accion: accion });
  const [offcanvasFiltrosOrdenes, setoffcanvasFiltrosOrdenes] = useState({ show: false, id: 0, accion: 'f' });
  const OffCanvasFiltrosClose = () => setoffcanvasFiltrosOrdenes({ show: false, id: 0, accion: 'f' });
  const OffCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosOrdenes({ show: true, id: id, accion: accion });
  const [recargaGridOrdenes, setRecargaGridOrdenes] = useState<string>('');
  const SetRefresh = (date: string) => setRecargaGridOrdenes(date);
  const [formdisabled, setformDisabled] = useState(false);
  function deleteOrder(registro: { idremito: number }) {
    PutEureka('/Remitos/Baja', { ordenes: [{ idremito: registro.idremito, idcliente: datos.usuario.idusuario }] }).then((response) => {
      if (response.info) {
        SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
      }
    });
  }
  const manejo_acciones = (origen: string, registro: string, accion: string) => {
    switch (accion) {
      case 'b':
        ConfirmGeneral('Eliminar remito', `Eliminar remito nro ${typeof registro === 'string' && JSON.parse(registro).nroremito}`, deleteOrder, typeof registro === 'string' && JSON.parse(registro));
        break;
      case 'm':
        setTituloModal('Modificar Remito');
        setformDisabled(false);
        ModalShow(parseInt(registro), accion);
        break;
      case 'c':
        setTituloModal('Consultar Remito');
        setformDisabled(true);
        ModalShow(parseInt(registro), accion);
        break;
      case 'f':
        setTituloModal('Filtro Remitos');
        setformDisabled(false);
        OffCanvasFiltrosShow(parseInt(registro), accion);
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    GetEureka('/remitos/combos').then((response) => {
      setEstados(response.content.estados);
    });
  }, [recargaGridOrdenes]);
  return (
    <PageLayout label='Adm. Remitos'>
      {Grid(manejo_acciones, 'remitos', recargaGridOrdenes, SetRefresh)}
      <EurekaModals id='modal_ordenes' manejador={modalOrdenes} modalTitulo={tituloModal} sizeModal='xl' handleClose={ModalClose}>
        <FormFormularioOrdenes ListaChecks={[{ idremito: modalOrdenes.id }]} SetRefresh={SetRefresh} estadoOrdenes={modalOrdenes} formDisabled={formdisabled} handleClose={ModalClose} />
      </EurekaModals>
      <EurekaOffcanvas id='offcanvas_filtrosordenes' manejador={offcanvasFiltrosOrdenes} offcanvasTitulo='Filtros' handleClose={OffCanvasFiltrosClose} posicion='start' size='w-20'>
        <FiltroOrdenes props={{ SetRefresh, OffCanvasFiltrosClose, estados }} />
      </EurekaOffcanvas>
    </PageLayout>
  );
};

export default EurekaOrdenes;
