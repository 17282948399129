import { useEffect } from 'react';
import $ from 'jquery';
import 'smartwizard/dist/css/smart_wizard_all.css';
import 'smartwizard/dist/js/jquery.smartWizard.min.js';

interface IEurekaSmartNew {
  children: React.ReactNode;
  enableSteps?: boolean;
  anchor: {
    enableNavigation: boolean;
  };
  keyboard?: {
    keyNavigation: boolean;
  };
  tema?: string;
  titulo?: string;
  id?: string;
  navItems: string[];
  botonesToolbar: {
    showNextButton: boolean;
    showPreviousButton: boolean;
  };
  funcionesSiguiente: Function;
  lenguaje: {
    next: string;
    previous: string;
  };
  btnExtra?: Function;
  showStep?: Function;
}

const EurekaSmartNew: React.FC<IEurekaSmartNew> = ({ children, enableSteps = true, anchor, keyboard, tema, titulo, id = 'smart-wizard', navItems = [], botonesToolbar = {}, funcionesSiguiente = () => {}, lenguaje, btnExtra = () => {}, showStep = () => {} }) => {
  useEffect(() => {
    const wizard: any = $(`#${id}`);
    wizard.smartWizard({
      theme: tema,
      justified: true,
      lang: lenguaje,
      enableAllSteps: enableSteps,
      toolbar: botonesToolbar,
      anchor,
      keyboard,
    });
    funcionesSiguiente(wizard);
    btnExtra(wizard);
    showStep(wizard);
  }, [funcionesSiguiente]);
  return (
    <div>
      <h1 className='gilroy-bold'>{titulo}</h1>
      <div id={id} className='sw sw-theme-arrows sw-justified'>
        <ul className='nav nav-progress'>
          {navItems.map((value, key) => (
            <li className='nav-item' key={key}>
              <a className='nav-link default'>{value}</a>
            </li>
          ))}
        </ul>
        <div className='tab-content'>{children}</div>
      </div>
    </div>
  );
};

export default EurekaSmartNew;
