import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IEurekaOverlayTrigger {
  isButton?: boolean;
  sizeButton?: 'sm' | 'lg' | undefined;
  variant?: string;
  placement?: 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start';
  id?: string;
  show?: number;
  hide?: number;
  title: string | React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const EurekaOverlayTrigger: React.FC<IEurekaOverlayTrigger> = ({ isButton = true, sizeButton, variant, placement, id = 'button-tooltip', show = 100, hide = 100, title = '', children, style = { textDecoration: 'underline' } }) => {
  const renderTooltip = (props: any) => (
    <Tooltip id={id} {...props}>
      {children}
    </Tooltip>
  );
  return (
    <OverlayTrigger placement={placement} delay={{ show, hide }} overlay={renderTooltip}>
      {isButton ? (
        <Button size={sizeButton} variant={variant} style={{ ...style }}>
          {title}
        </Button>
      ) : (
        <span>{title}</span>
      )}
    </OverlayTrigger>
  );
};
export default EurekaOverlayTrigger;
