import { useEffect, useState } from "react";
import { GetEureka } from "../estructura/EurekaGeneral/EurekaGeneral";
import { Form } from "react-bootstrap";
import { JSONTree } from "react-json-tree";

interface FormConectoresProps {
    idConector: number;
}

interface IConector {
    codvendedor: string;
    vendedor: string;
    tipoConector: string;
    config: '';
    estado: boolean | number;
    tipoFlex: string;
    tipoCorreo: string;
    tipoColecta: string;
}

const FormConectores: React.FC<FormConectoresProps> = ({ idConector }) => {
    const [conector, setConector] = useState<IConector>({
        codvendedor: '',
        vendedor: '',
        tipoConector: '',
        config: '',
        estado: false,
        tipoFlex: '',
        tipoCorreo: '',
        tipoColecta: ''
    });

    useEffect(() => {
        if (idConector > 0) {
            GetEureka(`/conector/api/get_conector_by_id`, `&id=${idConector}`)
            .then((response) => {
                setConector(response.content[0]);
            });
        }
    }, [idConector]);
    const theme = {
        scheme: 'monokai',
        base00: '#272822',
        base01: '#383830',
        base02: '#49483e',
        base03: '#75715e',
        base04: '#a59f85',
        base05: '#f8f8f2',
        base06: '#f5f4f1',
        base07: '#f9f8f5',
        base08: '#f92672',
        base09: '#fd971f',
        base0A: '#f4bf75',
        base0B: '#a6e22e',
        base0C: '#a1efe4',
        base0D: '#66d9ef',
        base0E: '#ae81ff',
        base0F: '#cc6633',
      };
    return (
        <Form className='row mt-2 mb-2'>
            <div className='row col-12'>
                <div className='col-6'>
                    <Form.Group controlId="codvendedor">
                        <Form.Label>Código Conector</Form.Label>
                        <Form.Control
                            type="text"
                            value={conector.codvendedor}
                            readOnly
                        />
                    </Form.Group>
                </div>
                <div className='col-6'>
                    <Form.Group controlId="vendedor">
                        <Form.Label>Nombre Conector</Form.Label>
                        <Form.Control
                            type="text"
                            value={conector.vendedor}
                            readOnly
                        />
                    </Form.Group>
                </div>
                <div className='col-6'>
                    <Form.Group controlId="tipoConector">
                        <Form.Label>Tipo de Conector</Form.Label>
                        <Form.Control
                            type="text"
                            value={conector.tipoConector}
                            readOnly
                        />
                    </Form.Group>
                </div>
                <div className='col-6'>
                    <Form.Group controlId="estado">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                            type="text"
                            value={conector.estado ? "Activo" : "Inactivo"}
                            readOnly
                        />
                    </Form.Group>
                </div>
                <div className='col-4'>
                    <Form.Group controlId="tipoFlex">
                        <Form.Label>Tipo Asig. Flex</Form.Label>
                        <Form.Control
                            type="text"
                            value={conector.tipoFlex}
                            readOnly
                        />
                    </Form.Group>
                </div>
                <div className='col-4'>
                    <Form.Group controlId="tipoCorreo">
                        <Form.Label>Tipo Asig. Correo</Form.Label>
                        <Form.Control
                            type="text"
                            value={conector.tipoCorreo}
                            readOnly
                        />
                    </Form.Group>
                </div>
                <div className='col-4'>
                    <Form.Group controlId="tipoColecta">
                        <Form.Label>Tipo Asig. Colecta</Form.Label>
                        <Form.Control
                            type="text"
                            value={conector.tipoColecta}
                            readOnly
                        />
                    </Form.Group>
                </div>
                <div className='col-12'>
                    <Form.Group controlId="config">
                        <Form.Label>Configuración</Form.Label>
                        {conector.config && (
                            <JSONTree data={JSON.parse(conector.config)} theme={theme} />
                        )}
                    </Form.Group>
                </div>
            </div>
        </Form>
    );
}

export default FormConectores;