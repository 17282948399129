import { useState } from 'react';
import EurekaUploadFile from '../../../estructura/EurekaUploadFile/EurekaUploadFile';
import EurekaSmartNew from '../../../estructura/EurekaSmartNew/EurekaSmartNew.tsx';
import 'smartwizard/dist/js/jquery.smartWizard.min.js';
import 'smartwizard/dist/css/smart_wizard_all.css';
import { PostEurekaBlueHost, EnviarMensaje } from '../../../estructura/EurekaGeneral/EurekaGeneral';

interface IImage {
  path: string;
}
const FormImportar = () => {
  const [dataPasoDos, setDataPasoDos] = useState('');
  const [dataPasoTres, setDataPasoTres] = useState('');
  const [mensajeStepDos, setmensajeStepDos] = useState('Cargando ...');
  const [image, setImage] = useState<IImage[]>([]);
  const [longitudFiles, setLongitudFiles] = useState(0);
  let navItems = ['Importar', 'Evaluar', 'Procesar'];
  let toolbar = {
    showNextButton: true,
    showPreviousButton: false,
  };
  let lenguaje = {
    next: 'Siguiente',
    previous: 'Previo',
  };
  let anchor = {
    enableNavigation: false,
  };
  const checkLengthUpload = (files: any) => {
    setLongitudFiles(Object.values(files || {}).length);
  };

  function funcionesBotonSiguiente(currentStepIndex: any, nextStepIndex: any, stepDirection: any, e: any, longitudFiles: any) {
    if (currentStepIndex === 0 && nextStepIndex === 1) {
      if (image.length) {
        if (image && image[0] && image[0].path) {
          for (let i = 0; i < image.length; i++) {
            const formData = new FormData();
            formData.append('path', image[i].path);
            formData.append('tipo', 'evaluar');
            PostEurekaBlueHost('proceso_importar_remitos', formData)
              .then((response) => setDataPasoDos(response))
              .catch((error) => {
                setmensajeStepDos('Error al importar el XLS, intente mas tarde');
                console.log(error);
              });
          }
        }
        return true;
      } else {
        EnviarMensaje('danger', 'Debe seleccionar un archivo para poder avanzar.');
        return false;
      }
    }
  }
  function funcionBotonSiguiente(wizard: any) {
    wizard.off('leaveStep');
    wizard.on('leaveStep', function (e: any, anchorObject: any, currentStepIndex: any, nextStepIndex: any, stepDirection: any) {
      return funcionesBotonSiguiente(currentStepIndex, nextStepIndex, stepDirection, e, longitudFiles);
    });
  }
  function handleFileUploaded(jsonInfo: any) {
    setImage(jsonInfo);
  }
  function retornarButtons() {
    return (
      <a href='./template_flexit_ordenes.xlsx' download>
        <button className='btn btn-warning'>Descargar archivo de prueba</button>
      </a>
    );
  }

  function importar() {
    if (image && image[0] && image[0].path) {
      for (let i = 0; i < image.length; i++) {
        const formData = new FormData();
        formData.append('path', image[i].path);
        formData.append('tipo', 'procesar');
        PostEurekaBlueHost('proceso_importar_remitos/', formData)
          .then((response) => setDataPasoTres('Procesado correctamente'))
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
  return (
    <EurekaSmartNew titulo='Importar remitos' navItems={navItems} lenguaje={lenguaje} botonesToolbar={toolbar} anchor={anchor} funcionesSiguiente={funcionBotonSiguiente}>
      <>
        <div id='step-1' className='tab-pane' role='tabpanel' aria-labelledby='step-1' style={{ display: 'block' }}>
          <EurekaUploadFile Buttons={retornarButtons} onFileUploaded={handleFileUploaded} titulo='Arrastre un archivo.' placeholder='Arrastre un archivo.' origen='importar' tipoAceptar='file' tipo='file' onCheckLenght={checkLengthUpload} />
        </div>
        <div id='step-2' className='tab-pane' role='tabpanel' aria-labelledby='step-2' style={{ display: 'block' }}>
          {dataPasoDos.length ? <div dangerouslySetInnerHTML={{ __html: dataPasoDos }} /> : <div>{mensajeStepDos}</div>}
        </div>
        <div id='step-3' className='tab-pane text-center' role='tabpanel' aria-labelledby='step-3' style={{ display: 'block' }}>
          {dataPasoTres.length ? (
            <div className='alert alert-success' role='alert'>
              {dataPasoTres}
            </div>
          ) : (
            <>
              <h1>Confirmar para proseguir</h1>
              <button className='btn btn-sm btn-success mt-3' onClick={() => importar()}>
                Importar
              </button>
            </>
          )}
        </div>
      </>
    </EurekaSmartNew>
  );
};

export default FormImportar;
