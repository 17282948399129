import axios from 'axios';
import { Loading, Report, Confirm } from 'notiflix';
const { REACT_APP_API_URL, REACT_APP_VALIDA_SSL, REACT_APP_URL_BLUEHOST } = process.env;

export const GetEureka = (path: string, querys: string = '') => {
  Loading.circle();
  const url = REACT_APP_API_URL + path + '?token=' + localStorage.getItem('token') + querys;
  const config: any = {
    rejectUnauthorized: REACT_APP_VALIDA_SSL === 'true' ? true : false,
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      Loading.remove();
    });
};
export const GoogleGeoCode = (query: string) => {
  Loading.circle();
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const config: any = {
    rejectUnauthorized: REACT_APP_VALIDA_SSL === 'true' ? true : false,
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    })
    .finally(() => {
      Loading.remove();
    });
};
/* export const PostEureka = (path, body) => {
  Loading.circle();
  var url = REACT_APP_API_URL + path + '?token=' + localStorage.getItem('token');
  const config = {
    rejectUnauthorized: REACT_APP_VALIDA_SSL === 'true' ? true : false
  };
  return axios.post(url, body, config).then((response) => {
    return response.data;
  }).catch((error) => {
    throw error;
  }).finally(() => {
    Loading.remove();
  })
} */
export const PostEureka = (path: string, body: any) => {
  Loading.circle();
  var url = REACT_APP_API_URL + path + '?token=' + localStorage.getItem('token');
  const config: any = {
    rejectUnauthorized: REACT_APP_VALIDA_SSL === 'true' ? true : false,
  };
  return axios
    .post(url, body, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      Loading.remove();
    });
};
export const PostEurekaBlueHost = (path: string, body: any) => {
  Loading.circle();
  var url = REACT_APP_URL_BLUEHOST + path;
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: url,
    data: body,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      Loading.remove();
    });
};
export const PutEureka = (path: string, body: any) => {
  EurekaGeneral.EurekaLoad(true);
  var url = REACT_APP_API_URL + path + '?token=' + localStorage.getItem('token');
  const config: any = {
    rejectUnauthorized: REACT_APP_VALIDA_SSL === 'true' ? true : false,
  };
  return axios
    .put(url, body, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      Loading.remove();
    });
};
export const PatchEureka = (path: string, body: any) => {
  EurekaGeneral.EurekaLoad(true);
  var url = REACT_APP_API_URL + path + '?token=' + localStorage.getItem('token');
  const config: any = {
    rejectUnauthorized: REACT_APP_VALIDA_SSL === 'true' ? true : false,
  };
  return axios
    .patch(url, body, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      Loading.remove();
    });
};
export const DeleteEureka = (path: string, body: any) => {
  Loading.circle();
  var url = REACT_APP_API_URL + path + '?token=' + localStorage.getItem('token');
  return axios
    .delete(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    })
    .finally(() => Loading.remove());
};
export const ChangeUrlImage = (url: string) => {
  const { REACT_APP_URL_BLUEHOST } = process.env;
  return url.replace('../', REACT_APP_URL_BLUEHOST + '/');
};
const EurekaGeneral = {
  EurekaLoad(accion: boolean) {
    accion ? Loading.circle() : Loading.remove();
  },
  VerificarDivisibilidad(numero: number): number {
    if (numero >= 1 && numero <= 6) {
      return numero;
    } else if (numero >= 7) {
      return 6;
    } else return 0;
  },
  tamanio_modal_tablero(chequeosObservados: any[], tipo: number): string | undefined {
    const columnCount: number = EurekaGeneral.VerificarDivisibilidad(chequeosObservados.length);
    if (tipo === 4) return 'md';
    else if (tipo === 6) return 'xl';
    else if (tipo === 2) return 'xl';
    else if (tipo === 7) return 'lg';
    else {
      if (columnCount >= 5) {
        return 'xl';
      } else if (columnCount > 3) {
        return 'lg';
      } else if (columnCount <= 2) {
        return 'md';
      } else {
        return undefined;
      }
    }
  },
};
export const EnviarMensaje = (tipo: string, msg: string): void => {
  if (msg === undefined) {
    msg = '';
  }
  switch (tipo) {
    case 'success':
      Report.success('Registro Exitoso', msg, 'Cerrar');
      break;
    case 'danger':
      Report.failure('Ups, tenemos un problema', msg, 'Cerrar');
      break;
    case 'warning':
      Report.warning('Atencion!', msg, 'Cerrar');
      break;
  }
};
export const ConfirmGeneral = (title: string, description: string, functionConfirm: Function = () => {}, idorden: number, functionDecline: Function = () => {}): void => {
  Confirm.show(
    title,
    description,
    'Si',
    'No',
    () => functionConfirm(idorden),
    () => functionDecline
  );
};
export default EurekaGeneral;
