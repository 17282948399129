import { useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import EurekaOverlay from '../estructura/EurekaOverlay/EurekaOverlay';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
const { REACT_APP_DIAS_DASHBOARD } = process.env;
var fecha = moment();
const fechaDesde = moment(fecha).subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD');
const fechaHasta = moment(fecha).format('YYYY-MM-DD');

interface IFiltroConector {
  props: {
    SetRefresh: Function;
    OffCanvasFiltrosClose: Function;
  };
}

interface ISel {
  label: string;
  value: number;
}

interface IUseForm {
  idlog: number;
  fecha_inicio: string;
  fecha_fin: string;
  pais: number;
  idcliente: string[] | number;
  estado: string[] | number;
  origen: number;
  idproveedor: string[] | number;
  nro_venta: string;
}

interface ICombos {
  proveedores: IFormato[];
  estados: IFormato[];
}

interface IFormato {
  value: number;
  label: string;
}

const FiltroConector: React.FC<IFiltroConector> = ({ props }) => {
  const [defaultPais, setdefaultPais] = useState<ISel[]>([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState<ISel[]>([]);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState<ISel[]>([]);
  const [origenSeleccionado, setorigenSeleccionado] = useState<ISel[]>([]);
  const [proveedorSeleccionado, setproveedorSeleccionado] = useState<ISel[]>([]);
  // const [clienteSeleccionado, setclienteSeleccionado] = useState([]);
  // const [defaultClientes, setdefaultClientes] = useState([]);
  const [defaultEstado, setdefaultEstado] = useState<ISel[]>([]);
  const [defaultOrigen, setdefaultOrigen] = useState<ISel[]>([]);
  const [defaultProveedor, setdefaultProveedor] = useState<ISel[]>([]);
  const { register, getValues, setValue, handleSubmit } = useForm<IUseForm>({
    defaultValues: {
      idlog: 0,
      fecha_inicio: fechaDesde,
      fecha_fin: fechaHasta,
      pais: 0,
      idcliente: [],
      estado: [],
      origen: 0,
      idproveedor: [],
      nro_venta: '',
    },
  });
  const [showFecha, setshowFecha] = useState<boolean>(false);
  const refFecha = useRef(null);
  const [sel_pais, setsel_pais] = useState<ISel[]>([
    { value: 0, label: 'Seleccione' },
    { value: 1, label: 'Argentina' },
    { value: 21, label: 'Puerto rico' },
  ]);
  const [sel_estado, setsel_estado] = useState<ISel[]>([]);
  const [sel_origen, setsel_origen] = useState<ISel[]>([
    { value: 0, label: 'Seleccione' },
    { value: 1, label: 'Tablero' },
    { value: 2, label: 'Manual' },
  ]);
  const [sel_proveedores, setsel_proveedores] = useState<ISel[]>([]);
  // const [sel_clientes, setsel_clientes] = useState([]);
  const [target, setTarget] = useState(null);
  const [fechas, setFechas] = useState({
    startDate: moment.tz('America/Argentina/Buenos_Aires').subtract(REACT_APP_DIAS_DASHBOARD, 'days').startOf('day').toDate(),
    endDate: moment.tz('America/Argentina/Buenos_Aires').startOf('day').toDate(),
  });
  function functionShowFecha(e: any) {
    setshowFecha(!showFecha);
    setTarget(e.target);
  }
  function changeDateRange(e: RangeKeyDict) {
    const { range1 } = e;
    const startDate = moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').startOf('day').toDate();
    const endDate = moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').endOf('day').toDate();
    setFechas({
      startDate,
      endDate,
    });
    setValue('fecha_inicio', moment.tz(range1?.startDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
    setValue('fecha_fin', moment.tz(range1?.endDate, 'America/Argentina/Buenos_Aires').format('YYYY-MM-DD'));
  }
  const handlePaisChange = (selectedOption: any) => {
    setValue('pais', selectedOption.value);
    setPaisSeleccionado(selectedOption);
  };
  const handleEstadoChange = (selectedOption: any) => {
    setValue(
      'estado',
      selectedOption.map((value: any) => value.value)
    );
    setEstadoSeleccionado(selectedOption);
  };
  const handleOrigenChange = (selectedOption: any) => {
    setValue('origen', selectedOption.value);
    setorigenSeleccionado(selectedOption);
  };
  const handleProveedorChange = (selectedOption: any) => {
    setValue(
      'idproveedor',
      selectedOption.map((value: any) => value.value)
    );
    setproveedorSeleccionado(selectedOption);
  };
  // const handleClienteChange = (selectedOption) => {
  //   setValue(
  //     "idcliente",
  //     selectedOption.map((value) => value.value)
  //   );
  //   setclienteSeleccionado(selectedOption);
  // };
  function submitFiltros(e: IUseForm) {
    try {
      localStorage.setItem('filtrosconector', JSON.stringify(e));
      props.SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
      props.OffCanvasFiltrosClose();
    } catch (error) {
      console.log(error);
    }
  }
  function resetFiltros(e: React.MouseEvent<HTMLButtonElement>) {
    setValue('idlog', 0);
    setValue('pais', 0);
    setValue('estado', 0);
    setValue('origen', 0);
    setValue('fecha_inicio', fechaDesde);
    setValue('fecha_fin', fechaHasta);
    setValue('idcliente', []);
    setValue('idproveedor', []);
    setValue('nro_venta', '');
    e.preventDefault();
    setdefaultPais([]);
    // setdefaultClientes([]);
    setdefaultEstado([]);
    setdefaultOrigen([]);
    setdefaultProveedor([]);
    setPaisSeleccionado([]);
    setEstadoSeleccionado([]);
    setorigenSeleccionado([]);
    setproveedorSeleccionado([]);
    // setclienteSeleccionado([]);
    setFechas({
      startDate: moment.tz('America/Argentina/Buenos_Aires').subtract(REACT_APP_DIAS_DASHBOARD, 'days').startOf('day').toDate(),
      endDate: moment.tz('America/Argentina/Buenos_Aires').startOf('day').toDate(),
    });
  }
  useEffect(() => {
    GetEureka('/conector-log/combos').then((combos) => {
      let data: ICombos = combos.content;
      setsel_proveedores(data.proveedores);
      // setsel_clientes(data.clientes);
      setsel_estado(data.estados);
      let filtros = localStorage.getItem('filtrosconector')?.length ? JSON.parse(localStorage.getItem('filtrosconector') || '{}') : null;
      if (filtros) {
        if (!filtros.hasOwnProperty('pais')) filtros.pais = 0;
        if (!filtros.hasOwnProperty('estado')) filtros.estado = [];
        if (!filtros.hasOwnProperty('origen')) filtros.origen = 0;
        if (!filtros.hasOwnProperty('fecha_inicio')) filtros.fecha_inicio = fechaDesde;
        if (!filtros.hasOwnProperty('fecha_fin')) filtros.fecha_fin = fechaHasta;
        if (!filtros.hasOwnProperty('idcliente')) filtros.origen = [];
        if (!filtros.hasOwnProperty('idproveedor')) filtros.origen = [];
        if (!filtros.hasOwnProperty('nro_venta')) filtros.nro_venta = '';
        setFechas({
          startDate: moment(filtros.fecha_inicio).startOf('day').toDate(),
          endDate: moment(filtros.fecha_fin).startOf('day').toDate(),
        });
        setValue('pais', filtros.pais);
        setValue('estado', filtros.estado);
        setValue('origen', filtros.origen);
        setValue('idcliente', filtros.idcliente);
        setValue('idproveedor', filtros.idproveedor);
        setValue('nro_venta', filtros.nro_venta);
        let paisArray: any;
        let estadoArray: any;
        let origenArray: any;
        let proveedoresArray: any;
        // let clientesArray;
        if (filtros.pais === 1 || filtros.pais === 21) {
          paisArray = [
            { value: 0, label: 'Seleccione' },
            { value: 1, label: 'Argentina' },
            { value: 21, label: 'Puerto rico' },
          ]
            .filter((value) => {
              if (value.value === filtros.pais) return value;
            })
            .map((value) => ({ value: value.value, label: value.label }));
        }
        if (typeof filtros.estado === 'object') {
          estadoArray = data.estados
            .filter((value) => {
              if (filtros?.estado?.includes(value.value)) return value;
            })
            .map((value) => ({ value: value.value, label: value.label }));
        }
        if (filtros.origen === 1 || filtros.origen === 2) {
          origenArray = [
            { value: 0, label: 'Seleccione' },
            { value: 1, label: 'Tablero' },
            { value: 2, label: 'Manual' },
          ]
            .filter((value) => {
              if (value.value === filtros.origen) return value;
            })
            .map((value) => ({ value: value.value, label: value.label }));
        }
        if (typeof filtros.idproveedor === 'object') {
          proveedoresArray = data.proveedores
            .filter((value) => {
              if (filtros?.idproveedor?.includes(value.value)) return value;
            })
            .map((value) => ({ value: value.value, label: value.label }));
        }
        // if (typeof filtros.idcliente === "object") {
        //   clientesArray = data.clientes
        //     .filter((value) => {
        //       if (filtros?.idcliente?.includes(value.value)) return value;
        //     })
        //     .map((value) => ({ value: value.value, label: value.label }));
        // }
        setdefaultPais(paisArray);
        // setdefaultClientes(clientesArray);
        setdefaultEstado(estadoArray);
        setdefaultOrigen(origenArray);
        setdefaultProveedor(proveedoresArray);
        if (paisArray?.length) setPaisSeleccionado(paisArray);
        if (estadoArray?.length) setEstadoSeleccionado(estadoArray);
        if (origenArray?.length) setorigenSeleccionado(origenArray);
        if (proveedoresArray?.length) setproveedorSeleccionado(proveedoresArray);
        // if (clientesArray?.length) setclienteSeleccionado(clientesArray);
      }
    });
  }, [setValue]);
  return (
    <form className='row' onSubmit={handleSubmit(submitFiltros)}>
      <div className='col-md-12'>
        <div className='form-group'>
          <Form.Label htmlFor='fecha'>Fecha</Form.Label>
          <EurekaOverlay target={target} ref={refFecha} titlePop='Fecha' arrayTitle={Object.values(fechas)} show={showFecha} handleClick={functionShowFecha}>
            <DateRangePicker ranges={[fechas]} maxDate={moment().toDate()} onChange={changeDateRange} />
          </EurekaOverlay>
        </div>
        <div className='form-group'>
          <Form.Label htmlFor='idpais'>Pais</Form.Label>
          <Select id='idpais' defaultValue={defaultPais} value={paisSeleccionado} {...register('pais')} onChange={handlePaisChange} options={sel_pais} />
        </div>
        <div className='form-group'>
          <Form.Label htmlFor='idproveedor'>Proveedores</Form.Label>
          <Select isMulti id='idproveedor' defaultValue={defaultProveedor} value={proveedorSeleccionado} {...register('idproveedor')} onChange={handleProveedorChange} options={sel_proveedores} />
        </div>
        {/* <div className="form-group">
          <Form.Label htmlFor="idcliente">Cliente</Form.Label>
          <Select isMulti id="idcliente" defaultValue={defaultClientes} value={clienteSeleccionado} {...register("idcliente")} onChange={handleClienteChange} options={sel_clientes} />
        </div> */}
        <div className='form-group'>
          <Form.Label htmlFor='idestado'>Estado</Form.Label>
          <Select isMulti id='idestado' defaultValue={defaultEstado} value={estadoSeleccionado} {...register('estado')} onChange={handleEstadoChange} options={sel_estado} />
        </div>
        <div className='form-group'>
          <Form.Label htmlFor='idorigen'>Origen</Form.Label>
          <Select id='idorigen' defaultValue={defaultOrigen} value={origenSeleccionado} {...register('origen')} onChange={handleOrigenChange} options={sel_origen} />
        </div>
        <div className='form-group'>
          <Form.Label htmlFor='nro_venta'>Nro venta</Form.Label>
          <Form.Control type='text' id='nro_venta' placeholder='Contiene ...' {...register('nro_venta')} />
        </div>
      </div>
      <div className='form-group mt-4'>
        <button onClick={resetFiltros} className='btn btn-warning w-50 btn-sm'>
          Resetear
        </button>
        <button type='submit' className='btn btn-primary w-50 btn-sm'>
          Buscar
        </button>
      </div>
    </form>
  );
};
export default FiltroConector;
