import React, { useEffect, useState } from 'react';
import { EnviarMensaje, GetEureka, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
const { REACT_APP_CLIENT_ID_ML, REACT_APP_REDIRECT_URI_ML, REACT_APP_CLIENT_SECRET } = process.env;

interface IFormML {
  modalClose: React.MouseEventHandler<HTMLButtonElement>;
}

const FormML: React.FC<IFormML> = ({ modalClose }) => {
  const [popup, setPopup] = useState<Window | null>(null);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const codeMessage = event?.data?.code;
      if (codeMessage) {
        obtenerToken(codeMessage);
        if (popup) {
          popup.close();
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [popup]);

  const getCode = () => {
    const popup = window.open('https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=' + REACT_APP_CLIENT_ID_ML + '&redirect_uri=' + REACT_APP_REDIRECT_URI_ML, 'popup', 'width=600,height=600');
    setPopup(popup);
  }

  const obtenerToken = (code: string) => {
    const data = {
      grant_type: 'authorization_code',
      client_id: REACT_APP_CLIENT_ID_ML,
      client_secret: REACT_APP_CLIENT_SECRET,
      code: code,
      redirect_uri: REACT_APP_REDIRECT_URI_ML,
    }
    PostEureka('/Conector/api/meli/obtener_token', data)
      .then((response) => {
        const { access_token, refresh_token } = response.content;
        if (response.info) {
          GetEureka(`/Conector/api/meli/obtener_seller`, `&access_token=${access_token}`)
            .then((response) => {
              if (response.info) {
                const config = {
                  nickname: response?.content?.nickname,
                  idseller: response?.content?.id,
                  app_id: REACT_APP_CLIENT_ID_ML,
                  client_secret: REACT_APP_CLIENT_SECRET,
                  tg_code: code,
                  redirect_uri: REACT_APP_REDIRECT_URI_ML,
                  token_actual_ML: access_token,
                  txt_refresh_code: refresh_token,
                  tipos_filtro: ['self_service', 'cross_docking', 'xd_dropp_off', 'retirocc'],
                }
                PostEureka(`/Conector/api/alta_conector`, { config: config, conector: 'meli', tipo: 2 }).then((response) => {
                  if (!response.info) {
                    EnviarMensaje('danger', response.content);
                    modalClose(response);
                  } else {
                    EnviarMensaje('success', 'Registro ingresado con éxito');
                  }
                })
              }
            })
        }
      })
      .catch((error) => {
        EnviarMensaje('danger', 'Error al obtener el token, intente nuevamente');
      })
  }

  return (
    <div>
      <div className='ps-2 pe-2 pt-4 text-center text-wrap'>
        <h4 className='fw-light'>Autenticación MercadoLibre</h4>
      </div>
      <hr className='border-dark' />
      <div className='text-center mb-3'>
        <button className='btn btn-primary' onClick={getCode}>
          Iniciar Sesión en MercadoLibre
        </button>
      </div>
    </div>
  );
};

export default FormML;
