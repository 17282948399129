import React from 'react';
import { Dropdown } from 'react-bootstrap';

interface IEurekaDropdown {
  arrayItems: {
    click: React.MouseEventHandler<HTMLElement>;
    descripcion: string;
  }[];
  title: string;
  variant: string;
  id: string;
  className?: string;
}

const EurekaDropdown: React.FC<IEurekaDropdown> = ({ arrayItems = [], title, variant, id, className = '' }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className={className} variant={variant} id={id}>
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {arrayItems.map((value, key) => (
          <Dropdown.Item key={key} onClick={value?.click}>
            {value?.descripcion}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EurekaDropdown;
