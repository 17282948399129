import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faGear } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Card, Form, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import EurekaModals from '../../EurekaModals/EurekaModals';
import FormFormularioOrdenes from '../../../EurekaDashboard/TabOrdenes/Forms/FormFormularioOrdenes';
import EurekaGeneral from '../../EurekaGeneral/EurekaGeneral';
import { tituloModalTableroOrdenes } from './Genericos/FormGenericos';
import moment from 'moment-timezone';
import EurekaOverlayTrigger from '../../EurekaOverlayTrigger/EurekaOverlayTrigger';
import $ from 'jquery';

interface IEurekaOrdenesFinalizadas {
  finalizadas: {
    idcliente: number;
    nroremito: number;
    idremito: number;
    fecha_alta: string;
    mod_manual: boolean;
    tipoconector: string;
    productos: string;
    observaciones: string;
    fecha: string;
  }[];
  direccion: boolean;
  SetRefresh: Function;
}

const EurekaOrdenesFinalizadas: React.FC<IEurekaOrdenesFinalizadas> = ({ finalizadas = [], direccion = true, SetRefresh }) => {
  const [chequeosfinalizadas, setChequeosfinalizadas] = useState([]);
  const [modalFinalizada, setmodalFinalizada] = useState({ show: false, tipo: 0, accion: 'c', id: 0 });
  const [formDisabled, setformDisabled] = useState(false);
  const ModalClose = () => setmodalFinalizada({ show: false, tipo: 0, accion: 'c', id: 0 });
  const ModalShow = (tipo: number, accion: string) => setmodalFinalizada({ show: true, tipo: tipo, accion: accion, id: 0 });
  function checkallfinalizadas() {
    if ($('.all_chk_finalizadas').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_f_ord"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_f_ord"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  function devolverName(name: string) {
    if (name) return '.' + name;
    else return '[class^="chk_f_ord"]:checked';
  }
  function getallchecksfinalizadas(name: string) {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>(devolverName(name)).forEach((input) => {
      const idremito = Number(input.value);
      const nroremito = input.dataset.nroremito;
      const idcliente = Number(input.dataset.idcliente);
      const bolsa_ = { idremito: idremito, nroremito: nroremito, idcliente: idcliente };
      bolsa.push(bolsa_);
    });
    setChequeosfinalizadas(bolsa);
  }
  function btn_consultar_orden(name: string) {
    getallchecksfinalizadas(name);
    setformDisabled(true);
    ModalShow(2, 'c');
  }
  return (
    <>
      <EurekaModals manejador={modalFinalizada} modalTitulo={tituloModalTableroOrdenes(modalFinalizada.accion)} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosfinalizadas, modalFinalizada.tipo)} handleClose={ModalClose}>
        {modalFinalizada.tipo === 2 ? (
          <FormFormularioOrdenes SetRefresh={SetRefresh} ListaChecks={chequeosfinalizadas} estadoOrdenes={modalFinalizada} formDisabled={formDisabled} handleClose={ModalClose} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay entregas seleccionadas
          </div>
        )}
      </EurekaModals>
      <div className={direccion ? 'col-12 col-lg-4' : 'col-md-12'}>
        <div className='card col_entregas_finalizadas'>
          <div className='card-header eureka-bg-success'>
            <div className='row'>
              <div className='col-10 text-success'>
                <input className='all_chk_finalizadas' onClick={checkallfinalizadas} type='checkbox' /> Remitos Confirmados <Badge bg='success'>{finalizadas.length}</Badge>
              </div>
              <div className='col-2 text-end'>
                {/*                 <div className="dropdow">
                  <button className="btn btn-success btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    +
                  </button>
                  <ul className="dropdown-menu">
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direccion ? '' : 'row'}`} id='accordionfinalizadas'>
              {finalizadas.map((finalizada, key) => (
                <div className={`accordion-item ${!direccion ? 'col-12 col-lg-4' : 'col-md-12'}`} key={key}>
                  <div className='accordion-header eureka-bg-success accordion-finalizada'>
                    <nav className='navbar bg-body-tertiary'>
                      <div className='container-fluid'>
                        <div className='form-check'>
                          <input className={'chk_f_ord' + key + ' form-check-input me-1'} data-idcliente={finalizada.idcliente} data-nroremito={finalizada.nroremito} type='checkbox' value={finalizada.idremito} id={'chk_f_ord' + key} />
                          <label className='text-success form-check-label' data-bs-toggle='collapse' data-bs-target={'#collapse_fin_' + key} aria-expanded='false' aria-controls={'collapse_fin_' + key}>
                            #{finalizada.nroremito} - {moment(finalizada.fecha).format('DD/MM HH:mm')}
                          </label>
                          {finalizada.mod_manual && (
                            <EurekaOverlayTrigger
                              title={
                                <Badge bg='secondary' className='ms-2' style={{ textDecoration: 'none' }}>
                                  <FontAwesomeIcon icon={faPen} />
                                </Badge>
                              }
                              isButton={false}
                              variant=''>
                              <p>Modificado por {finalizada.mod_manual}</p>
                            </EurekaOverlayTrigger>
                          )}
                        </div>
                        <div className='dropdow'>
                          <button className='btn btn-success btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                            {finalizada.tipoconector || <FontAwesomeIcon icon={faGear} />}
                          </button>
                          <ul className='dropdown-menu dropdown-menu-end'>
                            <li>
                              <a onClick={(e) => btn_consultar_orden('chk_f_ord' + key)} className='dropdown-item'>
                                {' '}
                                Consultar
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div id={'collapse_fin_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionfinalizadas'>
                    <div className='text-dark accordion-body'>
                      <small>{JSON.parse(finalizada?.productos || '[]')?.map((value: { cantidad: string; producto: string }) => `(${value.cantidad}) ${value.producto}, `)}</small>
                      <hr className='border border-dark'></hr>
                      <small>
                        Nro orden:{' '}
                        <a>
                          <small>{finalizada.nroremito}</small>
                        </a>
                      </small>
                      <br />
                      <small>Observaciones: {finalizada.observaciones}</small>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
              {/*                             <div className="d-flex justify-content-center align-items-center pt-2">
                                <button hidden={finalizadas.length < 12} className="btn btn-sm btn-success" onClick={() => setVermas({ ...vermas, finalizadas: !vermas.finalizadas })}>{finalizadas.length <= 12 ? "Ver mas" : "Ver menos"}</button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EurekaOrdenesFinalizadas;
