import React from 'react';
import { useForm } from 'react-hook-form';
import { EnviarMensaje, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';

interface IFormShopify {
  modalClose: React.MouseEventHandler<HTMLButtonElement>;
}

const FormShopify: React.FC<IFormShopify> = ({ modalClose }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      url_tienda: '',
      token_app: '',
    },
  });
  function onSubmit(e: any) {
    const config = {
      url_tienda: e.url_tienda,
      token_app: e.token_app,
    };
    PostEureka('/Conector/api/alta_conector', {config: config, conector: 'shop', tipo:6})
    .then((response) => {
      if (!response.info) {
        EnviarMensaje('danger', response.content);
        modalClose(response);
      } else {
        EnviarMensaje('success', 'Registro ingresado con éxito');
        modalClose(response);
      }
    });
  }
  return (
    <form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
      <div className='input-group mb-3'>
        <span className='input-group-text' id='basic-addon1'>
          Url tienda
        </span>
        <input className='form-control' type='text' {...register('url_tienda')} />
      </div>
      <div className='input-group mb-3'>
        <span className='input-group-text' id='basic-addon1'>
          Token app
        </span>
        <input className='form-control' type='text' {...register('token_app')} />
      </div>
      <div className='d-flex justify-content-end'>
        <button className='btn btn-light border' onClick={modalClose}>
          Cancelar
        </button>
        <button type='submit' className='btn btn-primary ms-3'>
          Aceptar
        </button>
      </div>
    </form>
  );
};

export default FormShopify;
