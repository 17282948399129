import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import EurekaModals from '../../EurekaModals/EurekaModals.tsx';
import EurekaGeneral, { EnviarMensaje, PostEureka } from '../../EurekaGeneral/EurekaGeneral.ts';
import FormImprimirEntregas from './Pendientes/ImprimirEntregas.tsx';
import FormConfirmarEntregas from './Pendientes/ConfirmarEntregas.tsx';
import EurekaSmartNew from '../../EurekaSmartNew/EurekaSmartNew.tsx';
import { FormCanceladosEntregas, FormTrackingEntregas, FormQrEntregas, tituloModalTableroEntregas, FormCotizarEntrega, FormReprogramarEntregas } from './Genericos/FormGenericos.tsx';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faCircleMinus, faTimeline, faLocationArrow, faQrcode, faFire } from '@fortawesome/free-solid-svg-icons';
import EurekaOverlayTrigger from '../../EurekaOverlayTrigger/EurekaOverlayTrigger.tsx';
import moment from 'moment-timezone';
const { REACT_APP_URL_BLUEHOST } = process.env;

interface IEurekaTableroPendientes {
  pendientes: { sucursal: string; fecha_impresion: string; idtipoconector: number; idsucursal: number; fecha_entrega?: string; fecha_despacho?: string; nro_venta: string; identrega: number; codinterno: number; fecha: string; fecha_cancelacion: string; esturbo: number; tipoconector: string; productos: string; direccion: string; recibe: string; telefono: string; comentario: string; observaciones: string }[];
  pendientesTotal: number[];
  underline: React.MouseEventHandler<HTMLLabelElement>;
  noUnderline: React.MouseEventHandler<HTMLLabelElement>;
  SetRefresh: Function;
  direccion: boolean;
  vermas: { observados: boolean; pendientes: boolean; finalizados: boolean };
  setVermas: Function;
}

interface IValoresCotizacion {
  idpais: number;
  cuit?: string;
  idproveedor: number[];
  identrega: number;
  nro_venta: string;
  origen: {
    pais: string;
    codigoPostal: number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  destino: {
    pais: string;
    codigoPostal: number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  productos: { articulo: string; cantidad: number; largo: number; ancho: number; alto: number; peso: number }[];
  idtipoconector?: number;
  proveedores?: IProveedores;
  entregas?: { id: number; nroventa: string }[];
}

interface IProveedores {
  rapiboy: boolean;
  pedidosya: boolean;
  rappi: boolean;
  urbano: boolean;
}

interface IListaChecks {
  id: number;
  nroventa: string;
  idsucursal: number;
  idtipoconector: number;
}

const EurekaTableroPendientes: React.FC<IEurekaTableroPendientes> = ({ pendientes = [], pendientesTotal = [], underline, noUnderline, SetRefresh, direccion, vermas, setVermas }) => {
  const [chequeosPendientes, setChequeosPendientes] = useState<IListaChecks[]>([]);
  const [valorCotizacion, setValorCotizacion] = useState({
    codigoSeguimiento: '',
    descripcion: '',
    idproveedor: 0,
    resultado: '',
  });
  const [idProveedor, setIdProveedor] = useState({});
  const [errorCoti, seterrorCoti] = useState<boolean>(false);
  const [checkCotizacion, setcheckCotizacion] = useState({
    id: 0,
    nroventa: 0,
    via: '',
  });
  const [proveedores, setProveedores] = useState<IProveedores>({ rapiboy: false, pedidosya: false, rappi: false, urbano: false });
  const [formDisabled, setformDisabled] = useState<boolean>(false);
  const [mensajeStep4, setMensajeStep4] = useState<string>('');
  const [mensajeStep3, setMensajeStep3] = useState<string>('');
  const [valoresCotizacion, setValoresCotizacion] = useState<IValoresCotizacion[]>([
    {
      idpais: 0,
      idproveedor: [],
      identrega: 0,
      nro_venta: '',
      origen: {
        pais: '',
        codigoPostal: 0,
        provincia: '',
        ciudad: '',
        direccion: '',
        latitud: 0,
        longitud: 0,
        telefono: '',
        nombreEnvia: '',
        observaciones: '',
      },
      destino: {
        pais: '',
        codigoPostal: 0,
        provincia: '',
        ciudad: '',
        direccion: '',
        latitud: 0,
        longitud: 0,
        telefono: '',
        nombreEnvia: '',
        observaciones: '',
      },
      productos: [],
    },
  ]);
  const [datosCotizacionFinal, setdatosCotizacionFinal] = useState([]);
  const [entregaActivo, setEntregaActivo] = useState({ id: 0, nroventa: '' });
  const [loading, setLoading] = useState(false);
  const [entregas, setEntregas] = useState([]);
  const [modalPendiente, setmmodalPendiente] = useState({
    show: false,
    tipo: 0,
    accion: '',
  });
  const ModalClose = (tipo: number) => {
    if (tipo === 6) {
      setMensajeStep4('');
      setMensajeStep3('');
      setValoresCotizacion([
        {
          identrega: 0,
          idpais: 0,
          nro_venta: '',
          idproveedor: [],
          origen: {
            pais: '',
            codigoPostal: 0,
            provincia: '',
            ciudad: '',
            direccion: '',
            latitud: 0,
            longitud: 0,
            telefono: '',
            nombreEnvia: '',
            observaciones: '',
          },
          destino: {
            pais: '',
            codigoPostal: 0,
            provincia: '',
            ciudad: '',
            direccion: '',
            latitud: 0,
            longitud: 0,
            telefono: '',
            nombreEnvia: '',
            observaciones: '',
          },
          productos: [],
        },
      ]);
      setIdProveedor({});
      setmmodalPendiente({ show: false, tipo: 0, accion: '' });
    } else setmmodalPendiente({ show: false, tipo: 0, accion: '' });
  };
  const ModalShow = (tipo: number, accion: string) => setmmodalPendiente({ show: true, tipo: tipo, accion: accion });
  const FnSetEntregaActivo = (id: number, nroventa: string) => setEntregaActivo({ id: id, nroventa: nroventa });
  let lenguaje = {
    next: 'Siguiente',
    previous: 'Previo',
  };
  function checkallpendientes() {
    if ($('.all_chk_pendientes').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_ent"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_ent"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  function devolverName(name: string | null) {
    if (name) return '.' + name;
    else return '[class^="chk_p_ent"]:checked';
  }
  function getallcheckspendientes(name: string | null) {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>(devolverName(name)).forEach((input) => {
      const id = Number(input.value);
      const nroventa = input.dataset.nroventa;
      const idsucursal = Number(input.dataset.idsucursal);
      const idtipoconector = Number(input.dataset.idtipoconector);
      const bolsa_ = {
        id: id,
        nroventa: nroventa,
        idsucursal: idsucursal,
        idtipoconector: idtipoconector,
      };
      bolsa.push(bolsa_);
    });
    setChequeosPendientes(bolsa);
  }
  const btn_cancelar_entrega = () => {
    getallcheckspendientes(null);
    ModalShow(1, 'cl');
  };
  function btn_cotizar_entrega() {
    setformDisabled(true);
    getallcheckspendientes(null);
    setValoresCotizacion(valoresCotizacion);
    ModalShow(6, 'ct');
  }
  function btn_imprimir_entregas() {
    getallcheckspendientes(null);
    ModalShow(4, 'imp');
  }
  function btn_confirmar_entrega() {
    getallcheckspendientes(null);
    ModalShow(5, 'cfm');
  }
  function btn_tracking_entrega(identrega: number) {
    FnSetEntregaActivo(identrega, '0');
    ModalShow(2, 'tk');
  }
  function btn_qr_entrega(identrega: number) {
    FnSetEntregaActivo(identrega, '0');
    ModalShow(3, 'qr');
  }
  function devolverValores(values: any) {
    setValoresCotizacion(values);
  }
  function funcionesBotonSiguiente(currentStepIndex: number, nextStepIndex: number, stepDirection: string, e: any) {
    let condicion = false;
    if (currentStepIndex === 0 && nextStepIndex === 1) {
      if (entregas.length) {
        if (valoresCotizacion[0].idproveedor && valoresCotizacion[0].idproveedor.length) {
          condicion = true;
        } else {
          EnviarMensaje('danger', 'Debe seleccionar pais y proveedor');
          condicion = false;
        }
      } else {
        EnviarMensaje('danger', 'Debe tener al menos una entrega para seguir con el proceso.');
        condicion = false;
      }
      return condicion;
    }
    if (currentStepIndex === 1 && nextStepIndex === 2) {
      let condicion = true;
      let regex = /^\w{2}-\w{8}-\w$/;
      let array: any = [];
      valoresCotizacion.forEach((value, key) => {
        value.identrega = chequeosPendientes.reduce((acc: any, current: any, k: number) => {
          if (k === key) acc = current.id;
          return acc;
        }, 0);
        value.idtipoconector = chequeosPendientes.reduce((acc: any, current: any, k: number) => {
          if (k === key) acc = current.idtipoconector;
          return acc;
        }, 0);
        value.proveedores = {
          rapiboy: false,
          pedidosya: true,
          rappi: false,
          urbano: false,
        };
        value.entregas = chequeosPendientes
          .filter((valor, k) => key === k)
          .map((value: any) => ({
            ...value,
            identrega: value.id,
            nro_venta: value.nroventa,
          }));
      });
      valoresCotizacion.forEach((values) => {
        values.productos.forEach((value, k) => {
          if (k === values.productos.length - 1 && value.hasOwnProperty('alto') && value.hasOwnProperty('ancho') && value.hasOwnProperty('largo') && value.hasOwnProperty('peso') && value.alto > 0 && value.ancho > 0 && value.largo > 0 && value.peso > 0) {
            array.push(values.productos);
          }
        });
      });
      let arrayCampos = [];
      for (let i = 0; i < valoresCotizacion.length; i++) {
        if (array[i].length === valoresCotizacion[i].productos.length && valoresCotizacion[i].productos.length > 0) {
          if (valoresCotizacion[i].origen.codigoPostal && valoresCotizacion[i].destino.codigoPostal && valoresCotizacion[i].origen.provincia && valoresCotizacion[i].origen.ciudad && valoresCotizacion[i].origen.direccion && valoresCotizacion[i].destino.provincia && valoresCotizacion[i].destino.ciudad && valoresCotizacion[i].destino.direccion) {
            valoresCotizacion[i].origen.codigoPostal = Number(valoresCotizacion[i].origen.codigoPostal);
            valoresCotizacion[i].destino.codigoPostal = Number(valoresCotizacion[i].destino.codigoPostal);
            valoresCotizacion[i].productos = valoresCotizacion[i].productos.map((value) => ({ ...value, count: value.cantidad }));
            arrayCampos.push(i);
          } else {
            EnviarMensaje('danger', 'Faltan completar campos en la entrega' + valoresCotizacion[i].nro_venta);
            condicion = false;
            break;
          }
        } else {
          EnviarMensaje('danger', 'Esta entrega no puede ser cotizada por falta de medidas en productos.');
          condicion = false;
          break;
        }
      }
      if (arrayCampos.length === valoresCotizacion.length) {
        PostEureka('/Conector/cotizacion', valoresCotizacion).then((value) => {
          setLoading(true);
          setValorCotizacion({
            ...value.content,
          });
          setLoading(false);
          return false;
        });
      }
      return condicion;
    }
    if (currentStepIndex === 2 && nextStepIndex === 3) {
      let condicion = false;
      let proveedores = Object.values(idProveedor || {});
      let newArray: any = [];
      Object.values(idProveedor).forEach((val: any) => {
        newArray.push({
          idpais: valoresCotizacion[val.key].idpais,
          idproveedor: [val.id],
          identrega: valoresCotizacion[val.key].identrega,
          cuit: valoresCotizacion[val.key].cuit,
          idtipoconector: valoresCotizacion[val.key].idtipoconector,
          origen: {
            ...valoresCotizacion[val.key].origen,
            numeroIdentificacion: '123456',
            nombreTienda: 'FLEXIT',
            numeroTienda: 'C6J695',
          },
          destino: {
            ...valoresCotizacion[val.key].destino,
            nombreTienda: 'FLOXET',
          },
          nro_venta: valoresCotizacion[val.key].nro_venta,
          productos: valoresCotizacion[val.key].productos,
          proveedores: {
            rapiboy: false,
            pedidosya: true,
            rappi: false,
            urbano: false,
          },
          entregas: chequeosPendientes
            .filter((value: any, k) => valoresCotizacion[val.key].identrega === value.id)
            .map((value: any) => ({
              via: 'pedidosya',
              identrega: value.id,
              nro_venta: value.nroventa,
            })),
        });
      });
      if (proveedores?.length && proveedores.filter((value: any) => value.id !== 0).length) {
        condicion = true;
        PostEureka('/conector/envios/crearenvio', newArray).then((response) => {
          setdatosCotizacionFinal(response.content);
        });
      } else {
        EnviarMensaje('danger', 'No selecciono ninguna cotización');
        condicion = false;
      }
      return condicion;
    }
  }
  function funcionBotonSiguiente(wizard: any) {
    wizard.off('leaveStep');
    wizard.on('leaveStep', function (e: any, anchorObject: any, currentStepIndex: number, nextStepIndex: number, stepDirection: string) {
      return funcionesBotonSiguiente(currentStepIndex, nextStepIndex, stepDirection, e);
    });
  }
  function btn_reprogramar_entregas() {
    getallcheckspendientes(null);
    ModalShow(8, 'rpg');
  }
  let toolbar = {
    showNextButton: true,
    showPreviousButton: false,
  };
  let anchor = {
    enableNavigation: false,
  };
  let keyboard = {
    keyNavigation: false,
  };
  return (
    <>
      <EurekaModals manejador={modalPendiente} modalTitulo={tituloModalTableroEntregas(modalPendiente.tipo)} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosPendientes, modalPendiente.tipo)} handleClose={() => ModalClose(modalPendiente.tipo)}>
        {modalPendiente.tipo === 1 ? (
          <FormCanceladosEntregas ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : modalPendiente.tipo === 2 ? (
          <FormTrackingEntregas identrega={entregaActivo.id} />
        ) : modalPendiente.tipo === 3 ? (
          <FormQrEntregas identrega={entregaActivo.id} />
        ) : modalPendiente.tipo === 4 ? (
          <FormImprimirEntregas ListaChecks={chequeosPendientes} />
        ) : modalPendiente.tipo === 5 ? (
          <FormConfirmarEntregas ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : modalPendiente.tipo === 6 ? (
          <EurekaSmartNew lenguaje={lenguaje} botonesToolbar={toolbar} anchor={anchor} keyboard={keyboard} funcionesSiguiente={funcionBotonSiguiente} navItems={['Cotizador', 'Origen y destino', 'Cotización Final', 'Envio entrega']} id='smart-cotizar' titulo='Cotizar Entrega'>
            <FormCotizarEntrega mensajeStep3={mensajeStep3} mensajeStep4={mensajeStep4} setIdProveedor={setIdProveedor} idProveedor={idProveedor} loading={loading} valorCotizacion={valorCotizacion} setValorCotizacion={setValorCotizacion} datos={modalPendiente} entregas={entregas} setEntregas={setEntregas} datosCotizacionFinal={datosCotizacionFinal} formCotizacion={valoresCotizacion} setFormCotizacion={setValoresCotizacion} setcheckCotizacion={setcheckCotizacion} proveedores={proveedores} formDisabled={formDisabled} identrega={chequeosPendientes} devolverValores={devolverValores} />
          </EurekaSmartNew>
        ) : modalPendiente.tipo === 8 ? (
          <FormReprogramarEntregas ListaChecks={chequeosPendientes} ModalClose={ModalClose} SetRefresh={SetRefresh} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay entregas seleccionadas
          </div>
        )}
      </EurekaModals>
      <div className={direccion ? 'col-12 col-lg-4' : 'col-md-12'}>
        <div className='card col_entregas_pendientes'>
          <div className='card-header eureka-bg-primary'>
            <div className='row'>
              <div className='col-10 text-light'>
                <input className='all_chk_pendientes' onClick={checkallpendientes} type='checkbox' /> Pendientes <Badge bg='primary'>{pendientesTotal.length}</Badge>
              </div>
              <div className='col-2 text-end'>
                <div className='dropdow'>
                  <button className='btn btn-primary btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    +
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_imprimir_entregas}>
                        Imprimir entregas
                      </button>
                    </li>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_cancelar_entrega}>
                        Cancelar entregas
                      </button>
                    </li>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_cotizar_entrega}>
                        Cotizar entregas
                      </button>
                    </li>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_confirmar_entrega}>
                        Confirmar entregas
                      </button>
                    </li>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_reprogramar_entregas}>
                        Reprogramar entregas
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direccion ? '' : 'row'}`} id='accordionPendiente'>
              {pendientes.map((pendiente, key) => (
                <div className={`accordion-item ${direccion ? 'col-md-12' : 'col-md-4'}`} key={key}>
                  <div className='accordion-header eureka-bg-primary  accordion-pendiente primary'>
                    <nav className='navbar bg-body-tertiary'>
                      <div className='container-fluid'>
                        <div className='form-check'>
                          <input className={'chk_p_ent' + key + ' form-check-input me-1'} data-idsucursal={pendiente.idsucursal} data-nroventa={pendiente.nro_venta} type='checkbox' value={pendiente.identrega} data-idtipoconector={pendiente.idtipoconector} id={'chk_pen_' + key} />
                          <label className='text-ligth form-check-label' data-bs-toggle='collapse' data-bs-target={'#collapse_pen' + key} aria-expanded='false' aria-controls={'collapse_pen' + key} onMouseEnter={underline} onMouseLeave={noUnderline}>
                            #{pendiente.codinterno} - {pendiente.fecha}{' '}
                            {pendiente.fecha_impresion?.length ? (
                              <EurekaOverlayTrigger isButton={false} title={<FontAwesomeIcon icon={faTag} />}>
                                {`Impresión: ${moment(pendiente.fecha_impresion).format('DD/MM')}`}
                              </EurekaOverlayTrigger>
                            ) : null}{' '}
                            {pendiente.fecha_cancelacion?.length ? (
                              <EurekaOverlayTrigger isButton={false} title={<FontAwesomeIcon icon={faCircleMinus} />}>
                                {`Cancelada: ${moment(pendiente.fecha_cancelacion).format('DD/MM')}`}
                              </EurekaOverlayTrigger>
                            ) : null}{' '}
                            {pendiente.esturbo === 1 ? (
                              <EurekaOverlayTrigger isButton={false} title={<FontAwesomeIcon icon={faFire} />}>
                                {'Es Turbo'}
                              </EurekaOverlayTrigger>
                            ) : (
                              ''
                            )}
                          </label>
                        </div>
                        <div className='dropdow'>
                          <button className='btn btn-primary btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                            {pendiente.tipoconector}
                          </button>
                          <ul className='dropdown-menu dropdown-menu-end'>
                            <li>
                              <a onClick={(e) => btn_qr_entrega(pendiente.identrega)} className='dropdown-item pointer'>
                                <FontAwesomeIcon icon={faQrcode} /> QR
                              </a>
                            </li>
                            <li>
                              <a onClick={(e) => btn_tracking_entrega(pendiente.identrega)} className='dropdown-item pointer'>
                                <FontAwesomeIcon icon={faTimeline} /> Tracking
                              </a>
                            </li>
                            <li>
                              <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + pendiente.nro_venta} className='dropdown-item' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={faLocationArrow} /> Seguimiento
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div id={'collapse_pen' + key} className='accordion-collapse collapse' data-bs-parent='#accordionPendiente'>
                    <div className='text-dark accordion-body'>
                      <small>{pendiente.productos}</small>
                      <hr className='border border-dark margin-hr'></hr>
                      <small>
                        Nro Venta:{' '}
                        <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + pendiente.nro_venta} className='btn btn-link' target='_blank' rel='noreferrer'>
                          <small>{pendiente.nro_venta}</small>
                        </a>
                      </small>
                      <br />
                      <small>Sucursal: {pendiente.sucursal}</small>
                      <br />
                      <small>Dirección: {pendiente.direccion}</small>
                      <br />
                      <small>
                        Recibe: {pendiente.recibe} | Telefono: {pendiente.telefono}
                      </small>
                      <br />
                      <div className='row p-0 m-0'>
                        <small className='col-md-6 m-0 p-0'>Fecha despacho: {pendiente.fecha_despacho ? <span className='badge bg-secondary'>{moment.utc(pendiente.fecha_despacho).format('DD/MM/YYYY')}</span> : null}</small>
                        <small className='col-md-6 m-0 p-0'>Fecha entrega: {pendiente.fecha_entrega ? <span className='badge bg-secondary'>{moment.utc(pendiente.fecha_entrega).format('DD/MM/YYYY')}</span> : null}</small>
                      </div>
                      <br />
                      <small>Comentario: {pendiente.comentario}</small>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
              <div className='d-flex justify-content-center align-items-center pt-2'>
                <button hidden={pendientes.length < 12} className='btn btn-sm btn-primary' onClick={() => setVermas({ ...vermas, pendientes: !vermas.pendientes })}>
                  {pendientes.length <= 12 ? 'Ver mas' : 'Ver menos'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EurekaTableroPendientes;
