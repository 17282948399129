import React, { useContext, useEffect, useState } from 'react';
import { FlexitContext } from '../../context/Flexit.tsx';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment';
import Select from 'react-select';
import useGetComplexObject from '../../hooks/useGetComplexObject.ts';

interface IFiltrosArticulos {
  SetRefresh: Function;
  offCanvasFiltrosClose: Function;
}

interface ISel {
  value: number;
  label: string;
}

interface IContent {
  marcas: IArray[];
  categorias: IArray[];
  proveedores: IArray[];
  modelos: IArray[];
}

interface IArray {
  orden: number;
  descripcion: string;
}

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

const FiltrosArticulos: React.FC<IFiltrosArticulos> = ({ SetRefresh, offCanvasFiltrosClose }) => {
  const { datos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [sel_filtrosproductos, setsel_filtrosproductos] = useState<ISel[]>([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState<ISel[]>([])
  const [sel_proveedores, setsel_proveedores] = useState<ISel[]>([]);
  const [proveedorSeleccionado, setProveedorSeleccionado] = useState<ISel[]>([]);
  const [sel_modelos, setsel_modelos] = useState<ISel[]>([]);
  const [modeloSeleccionado, setModeloSeleccionado] = useState<ISel[]>([])
  const [sel_marcas, setsel_marcas] = useState<ISel[]>([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState<ISel[]>([])
  const [sel_tipos, setsel_tipos] = useState<ISel[]>([]);
  const [tipoSeleccionado, setTipoSeleccionado] = useState<ISel[]>([])
  const [defaultProv, setdefaultProv] = useState<ISel[]>([]);
  const [defaultModelos, setdefaultModelos] = useState<ISel[]>([]);
  const [defaultMarcas, setdefaultMarcas] = useState<ISel[]>([]);
  const [defaultTipos, setdefaultTipos] = useState<ISel[]>([]);
  const [defaultProductos, setdefaultProductos] = useState<ISel[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { register, getValues, setValue, reset } = useForm({
    defaultValues: {
      idarticulo: 0,
      articulo: '',
      idtipo: 0,
      idmarca: 0,
      idmodelo: 0,
      idproveedor: 0,
      idfiltroproducto: 0,
    },
  });
  const { idmarca, idmodelo, idproveedor, idtipo, idfiltroproducto } = getValues();
  const handleMarcaChange = (selectedOption: any) => {
    setValue(
      'idmarca',
      selectedOption.map((value: any) => value?.value)
    );
    setMarcaSeleccionada(selectedOption)
  }
  const handleProductoChange = (selectedOption: any) => {
    setValue(
      'idfiltroproducto',
      selectedOption.map((value: any) => value?.value)
    );
    setProductoSeleccionado(selectedOption)
  }
  const handleTipoChange = (selectedOption: any) => {
    setValue(
      'idtipo',
      selectedOption.map((value: any) => value?.value)
    );
    setTipoSeleccionado(selectedOption)
  }
  const handleProveedorChange = (selectedOption: any) => {
    setValue(
      'idproveedor',
      selectedOption.map((value: any) => value?.value)
    );
    setProveedorSeleccionado(selectedOption)
  }
  const handleModeloChange = (selectedOption: any) => {
    setValue(
      'idmodelo',
      selectedOption.map((value: any) => value?.value)
    );
    setModeloSeleccionado(selectedOption)
  }
  async function submitFiltros() {
    try {
      localStorage.setItem('filtrosarticulos', JSON.stringify(getValues()));
      SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
      offCanvasFiltrosClose();
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    let filtros = localStorage.getItem('filtrosarticulos')?.length ? JSON.parse(localStorage.getItem('filtrosarticulos') || '{}') : null;
    const expectedStructure = {
      idarticulo: { value: 0, label: 'Select...', name: 'idarticulo' },
      articulo: '',
      idmarca: { value: 1, label: 'Marca Generica', name: 'idmarca' },
      idmodelo: { value: 0, label: 'Select...', name: 'idmodelo' },
      idproveedor: { value: 0, label: 'Select...', name: 'idproveedor' },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
    };

    const isValidStructure = (obj: any, structure: any): boolean => {
      return Object.keys(structure).every((key) => {
        if (typeof structure[`${key}`] === 'object' && structure[`${key}`] !== null) {
          return isValidStructure(obj[`${key}`], structure[`${key}`]);
        }
        return obj.hasOwnProperty(key) && typeof obj[`${key}`] === typeof structure[`${key}`];
      });
    };

    if (filtros && isValidStructure(filtros, expectedStructure)) {
      for (let prop in filtros) {
        if (['idarticulo', 'articulo', 'idtipo', 'idmarca', 'idmodelo', 'idproveedor', 'idfiltroproducto'].includes(prop)) {
          setValue(prop as 'idarticulo' | 'articulo' | 'idtipo' | 'idmarca' | 'idmodelo' | 'idproveedor' | 'idfiltroproducto', filtros[prop]);
        }
      }
    }
    GetEureka('/Articulos/Combos').then((result) => {
      let content: IContent = result.content;
      let { marcas, categorias, proveedores, modelos } = content;
      let filtrosProductos = [
        { orden: 1, descripcion: 'Flexit' },
        { orden: datos.usuario.idusuario, descripcion: 'Cliente' },
      ];
      setsel_filtrosproductos(
        filtrosProductos.map((value) => ({
          value: value.orden,
          label: value.descripcion,
        }))
      );
      setsel_modelos(
        modelos.map((value) => ({
          value: value.orden,
          label: value.descripcion,
        }))
      );
      setsel_marcas(
        marcas.map((value) => ({
          value: value.orden,
          label: value.descripcion,
        }))
      );
      setsel_tipos(
        categorias.map((value) => ({
          value: value.orden,
          label: value.descripcion,
        }))
      );
      setsel_proveedores(
        proveedores.map((value) => ({
          value: value.orden,
          label: value.descripcion,
        }))
      );
      if (filtros) {
        setValue('articulo', filtros.articulo);
        setValue('idarticulo', filtros.idarticulo);
        setValue('idtipo', filtros.idtipo);
        setValue('idmarca', filtros.idmarca);
        setValue('idmodelo', filtros.idmodelo);
        setValue('idproveedor', filtros.idproveedor);
        setValue('idfiltroproducto', filtros.idfiltroproducto);
        for (const key in filtros) {
          if (filtros[`${key}`] === 0) filtros[`${key}`] = [];
        }
        console.log(filtros);
        let proveedoresArray = proveedores.filter((value) => filtros?.idproveedor?.includes(value.orden)).map((value) => ({ value: value.orden, label: value.descripcion }));
        let marcasArray = marcas.filter((value) => filtros?.idmarca?.includes(value.orden)).map((value) => ({ value: value.orden, label: value.descripcion }));
        let tiposArray = categorias.filter((value) => filtros?.idtipo?.includes(value.orden)).map((value) => ({ value: value.orden, label: value.descripcion }));
        let modelosArray = modelos.filter((value) => filtros?.idmodelo?.includes(value.orden)).map((value) => ({ value: value.orden, label: value.descripcion }));
        let productosArray = filtrosProductos.filter((value) => filtros?.idfiltroproducto?.includes(value.orden)).map((value) => ({ value: value.orden, label: value.descripcion }));
        setModeloSeleccionado(modelosArray)
        setMarcaSeleccionada(marcasArray)
        setProductoSeleccionado(productosArray)
        setTipoSeleccionado(tiposArray)
        setProveedorSeleccionado(proveedoresArray)
        setdefaultProv(proveedoresArray);
        setdefaultMarcas(marcasArray);
        setdefaultModelos(modelosArray);
        setdefaultTipos(tiposArray);
        setdefaultProductos(productosArray);
      }
      setDataLoaded(true);
    });
  }, []);
  const resetearFiltros = (e: React.MouseEvent<HTMLButtonElement>, origen: string) => {
    e.preventDefault();
    setModeloSeleccionado([])
    setMarcaSeleccionada([])
    setProductoSeleccionado([])
    setTipoSeleccionado([])
    setProveedorSeleccionado([])
    let reseteo: any = {
      idarticulo: 0,
      articulo: '',
      idtipo: 0,
      idmarca: 0,
      idmodelo: 0,
      idproveedor: 0,
      idfiltroproducto: 0,
    };
    localStorage.removeItem('filtros' + origen);    
    for (let i in reset) {
      if (
        i === 'idarticulo' ||
        i === 'articulo' ||
        i === 'idtipo' ||
        i === 'idmarca' ||
        i === 'idmodelo' ||
        i === 'idproveedor' ||
        i === 'idfiltroproducto'
      ) {
        setValue(i, reseteo[i as keyof typeof reseteo]);
      }
    }
    reset({
      idarticulo: 0,
      articulo: '',
      idtipo: 0,
      idmarca: 0,
      idmodelo: 0,
      idproveedor: 0,
      idfiltroproducto: 0,
    });
    submitFiltros()
  };
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <Form.Label htmlFor='articulo'>Articulo</Form.Label>
            <Form.Control type='text' id='articulo' placeholder='Igual a ...' {...register('articulo')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idproveedor'>Proveedores</Form.Label>
            {dataLoaded && <Select isMulti id='idproveedor' defaultValue={defaultProv} value={proveedorSeleccionado} {...register('idproveedor')} onChange={handleProveedorChange} options={sel_proveedores} />}
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idmodelo'>Modelos</Form.Label>
            {dataLoaded && <Select isMulti id='idmodelo' defaultValue={defaultModelos} value={modeloSeleccionado} {...register('idmodelo')} onChange={handleModeloChange} options={sel_modelos} />}
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idtipo'>Tipos</Form.Label>
            {dataLoaded && <Select isMulti id='idtipo' defaultValue={defaultTipos} value={tipoSeleccionado} {...register('idtipo')} onChange={handleTipoChange} options={sel_tipos} />}
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idmodelo'>Marcas</Form.Label>
            {dataLoaded && <Select isMulti id='idmodelo' defaultValue={defaultMarcas} value={marcaSeleccionada} {...register('idmarca')} onChange={handleMarcaChange} options={sel_marcas} />}
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idfiltroproducto'>Pertenencia</Form.Label>
            {dataLoaded && <Select isMulti id='idfiltroproducto' defaultValue={defaultProductos} value={productoSeleccionado} {...register('idfiltroproducto')} onChange={handleProductoChange} options={sel_filtrosproductos} />}
          </div>
          <div className='form-group mt-4'>
            <button onClick={(e) => {
              resetearFiltros(e, 'articulos')
            }} className='btn btn-warning w-100 btn-sm'>
              Resetear
            </button>
          </div>
          <div className='form-group mt-2'>
            <button onClick={submitFiltros} className='btn btn-primary w-100 btn-sm'>
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default FiltrosArticulos;
