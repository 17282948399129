import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faGear } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Card, Form, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import FormFormularioOrdenes from '../../../EurekaDashboard/TabOrdenes/Forms/FormFormularioOrdenes';
import EurekaModals from '../../EurekaModals/EurekaModals';
import EurekaGeneral from '../../EurekaGeneral/EurekaGeneral';
import { tituloModalTableroOrdenes } from './Genericos/FormGenericos';
import EurekaOverlayTrigger from '../../EurekaOverlayTrigger/EurekaOverlayTrigger';
import moment from 'moment-timezone';
import $ from 'jquery';

interface IEurekaOrdenesRechazadas {
  rechazados: {
    idcliente: number;
    nroremito: number;
    idremito: number;
    fecha_alta: string;
    mod_manual: boolean;
    tipoconector: string;
    productos: string;
    observaciones: string;
    fecha: string;
  }[];
  direccion: boolean;
  SetRefresh: Function;
}

const EurekaOrdenesRechazados: React.FC<IEurekaOrdenesRechazadas> = ({ rechazados = [], direccion = true, SetRefresh }) => {
  const [chequeosRechazadas, setChequeosRechazadas] = useState([]);
  const [modalRechazado, setmodalRechazado] = useState({ show: false, tipo: 0, accion: 'c', id: 0 });
  const [formDisabled, setformDisabled] = useState(false);
  const ModalClose = () => setmodalRechazado({ show: false, tipo: 0, accion: 'c', id: 0 });
  const ModalShow = (tipo: number, accion: string) => setmodalRechazado({ show: true, tipo: tipo, accion: accion, id: 0 });
  function checkallrechazadas() {
    if ($('.all_chk_rechazadas').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_r_ord"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_r_ord"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  function devolverName(name: string | null) {
    if (name) return '.' + name;
    else return '[class^="chk_r_ord"]:checked';
  }
  function getallchecksrechazadas(name: string) {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>(devolverName(name)).forEach((input) => {
      const idremito = Number(input.value);
      const nroremito = input.dataset.nroremito;
      const idcliente = Number(input.dataset.idcliente);
      const bolsa_ = { idremito: idremito, nroremito: nroremito, idcliente: idcliente };
      bolsa.push(bolsa_);
    });
    setChequeosRechazadas(bolsa);
  }
  function btn_consultar_orden(idremito: number, name: string) {
    getallchecksrechazadas(name);
    setformDisabled(true);
    ModalShow(2, 'c');
  }
  return (
    <>
      <EurekaModals manejador={modalRechazado} modalTitulo={tituloModalTableroOrdenes(modalRechazado.accion)} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosRechazadas, modalRechazado.tipo)} handleClose={ModalClose}>
        {modalRechazado.tipo === 2 ? (
          <FormFormularioOrdenes ListaChecks={chequeosRechazadas} estadoOrdenes={modalRechazado} formDisabled={formDisabled} handleClose={ModalClose} SetRefresh={SetRefresh} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay entregas seleccionadas
          </div>
        )}
      </EurekaModals>
      <div className={direccion ? 'col-12 col-lg-4' : 'col-md-12'}>
        <div className='card col_entregas_rechazadas'>
          <div className='card-header eureka-bg-danger'>
            <div className='row'>
              <div className='col-10 text-danger'>
                {/* <input className='all_chk_rechazadas' onClick={checkallrechazadas} type='checkbox' /> */} Remitos Rechazados <Badge bg='danger'>{rechazados.length}</Badge>
              </div>
              <div className='col-2 text-end'>
                {/*                 <div className="dropdow">
                  <button className="btn btn-danger btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    +
                  </button>
                  <ul className="dropdown-menu">
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direccion ? '' : 'row'}`} id='accordionrechazadas'>
              {rechazados.map((rechazada, key) => (
                <div className={`accordion-item ${direccion ? 'col-md-12' : 'col-md-4'}`} key={key}>
                  <div className='accordion-header eureka-bg-danger accordion-rechazada'>
                    <nav className='navbar bg-body-tertiary'>
                      <div className='container-fluid'>
                        <div className='form-check'>
                          <input className={'chk_r_ord' + key + ' form-check-input me-1'} data-idcliente={rechazada.idcliente} data-nroremito={rechazada.nroremito} type='checkbox' value={rechazada.idremito} id={'chk_r_ord' + key} />
                          <label className='text-danger form-check-label' data-bs-toggle='collapse' data-bs-target={'#collapse_rec_' + key} aria-expanded='false' aria-controls={'collapse_rec_' + key} /* onMouseEnter={underline} onMouseLeave={noUnderline} */>
                            #{rechazada.nroremito} - {moment(rechazada.fecha).format('DD/MM HH:mm')}
                          </label>
                          {rechazada.mod_manual && (
                            <EurekaOverlayTrigger
                              title={
                                <Badge bg='secondary' className='ms-2' style={{ textDecoration: 'none' }}>
                                  <FontAwesomeIcon icon={faPen} />
                                </Badge>
                              }
                              isButton={false}
                              variant=''>
                              <p>Modificado por {rechazada.mod_manual}</p>
                            </EurekaOverlayTrigger>
                          )}
                        </div>
                        <div className='dropdow'>
                          <button className='btn btn-danger btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                            {rechazada.tipoconector || <FontAwesomeIcon icon={faGear} />}
                          </button>
                          <ul className='dropdown-menu dropdown-menu-end'>
                            <li>
                              <a onClick={(e) => btn_consultar_orden(rechazada.idremito, 'chk_r_ord' + key)} className='dropdown-item'>
                                {' '}
                                Consultar
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div id={'collapse_rec_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionrechazadas'>
                    <div className='text-danger accordion-body'>
                      <small>{JSON.parse(rechazada?.productos || '[]')?.map((value: { cantidad: string; producto: string }) => `(${value.cantidad}) ${value.producto}, `)}</small>
                      <hr className='border border-dark'></hr>
                      <small>
                        Nro orden:{' '}
                        <a>
                          <small>{rechazada.nroremito}</small>
                        </a>
                      </small>
                      <br />
                      <small>Observaciones: {rechazada.observaciones}</small>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
              {/*                             <div className="d-flex justify-content-center align-items-center pt-2">
                                <button hidden={rechazadas.length < 12} className="btn btn-sm btn-danger" onClick={() => setVermas({ ...vermas, rechazadas: !vermas.rechazadas })}>{rechazadas.length <= 12 ? "Ver mas" : "Ver menos"}</button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EurekaOrdenesRechazados;
