import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
import { useEffect, useState } from 'react';
import { JSONTree } from 'react-json-tree';

interface IFormConector {
  props: {
    modalConector: IModalConector;
  };
}

interface IModalConector {
  show: boolean;
  id: number;
  accion: string;
}

interface IDatosLog {
  estado: number;
  fecha_alta: string;
  idcliente: number;
  identrega: string;
  idlog: number;
  idproveedor: string;
  nro_venta: string;
  request: string;
  response: string;
}

const FormConector: React.FC<IFormConector> = ({ props }) => {
  const [datosLog, setdatosLog] = useState<IDatosLog>();
  useEffect(() => {
    if (props.modalConector.show) GetEureka(`/conector-log/get_log_by_id`, `&idlog=${props.modalConector.id}`).then((log) => setdatosLog(log.content[0]));
  }, []);
  const theme = {
    scheme: 'monokai',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  };
  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <div className='form-group'>
            <label className='fs-4'>Cuerpo</label>
            {datosLog?.request && <JSONTree data={JSON.parse(datosLog?.request || '{}')} theme={theme} />}
          </div>
        </div>
        <div className='col-6'>
          <div className='from-group'>
            <label className='fs-4'>Respuesta</label>
            {datosLog?.response && <JSONTree data={JSON.parse(datosLog?.response || '{}')} theme={theme} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormConector;
